import React, { useState, useContext, createContext, useEffect } from "react";
import { fetchValidDomains, firebase, fridaTesterFirebase } from ".";
import { AccessControl } from "../interfaces/Home";
import { getEmailName } from "../utilities";

interface AuthContextObject {
  user: firebase.User | null;
  loading: boolean;
  userName:string;
  updateAuthObjUserName: (val:string,user:firebase.User | null) => void,
}

// updateUserNameAuthObj?:()=>void
const AuthContext = createContext<AuthContextObject>({
  user: null,
  loading: true,
  userName:'',
  updateAuthObjUserName:(val:string)=>{},
});

export const doSignInWithMicrosoft = () => {
  // console.log(`previo login:`, firebase.auth());
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      const provider = new firebase.auth.OAuthProvider('microsoft.com');
      firebase.auth().signInWithPopup(provider)
        .then(async (result) => {
          // IdP data available in result.additionalUserInfo.profile.
          // ...
          // console.log(`bien login:`, result);
          
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
        })
        .catch((error) => {
          // Handle error.
          console.log(`error en login:`, error);
        });
    })
    .catch((err) => { console.log(`error segundo catch:`, err); });
};

export function AuthProvider({ children }: any) {

  const updateAuthObjUserName=(newName:string,user: firebase.User | null)=>{
    setAuthObj({...authObj,user:user,loading:false,userName:newName})
    // setAuthObj({...authObj,loading:false})
  }

  const [authObj, setAuthObj] = useState<AuthContextObject>({
    user: null,
    loading: true,
    userName:'',
    updateAuthObjUserName
  });

  useEffect (() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setAuthObj({ user, loading: true, userName:'',updateAuthObjUserName: updateAuthObjUserName});
      if (user) {
        let Name=''
        let ref = fridaTesterFirebase.database().ref();
        let res:AccessControl=(await ref.child(`AccessControl/${user.uid}/`).once('value')).val()
        if(res)
          if(!res.hasOwnProperty('Name')) //no existe Name
          {
            if(user.displayName===null)
            {
              let userNm=user.email&&getEmailName(user.email?.split('@')[0])
              await ref.child(`AccessControl/${user.uid}/Name`).set(userNm);
              userNm&&user&&user.displayName&&setAuthObj({user, loading: false,userName:userNm,updateAuthObjUserName: updateAuthObjUserName});
              Name=userNm?userNm:''
            }
            else
            {
              await ref.child(`AccessControl/${user.uid}/Name`).set(user.displayName);
              user&&user.displayName&&setAuthObj({user, loading: false,userName:user.displayName,updateAuthObjUserName: updateAuthObjUserName});
              Name=user.displayName
            }
          }
          else //existe Name
          {
            res.Name&&setAuthObj({ user, loading: false,userName:res.Name,updateAuthObjUserName: updateAuthObjUserName})
            Name=res.Name
          }

        setAuthObj({ user, loading: false, userName:Name,updateAuthObjUserName: updateAuthObjUserName});
        if (user) {
          let userDomain = user.email?.split('@')[1];
          const validDomains = await fetchValidDomains();
          if (userDomain !== undefined && validDomains.includes(userDomain!)) {
            await fridaTesterFirebase.database().ref('AccessControl').child(user.uid).update({ Mail: user.email! });
          } else {
            await firebase.auth().signOut();
            setAuthObj({ user: null, loading: false,
              userName:'' ,updateAuthObjUserName: updateAuthObjUserName
            });
          }
        } else {
          console.log('user undefined');
        }
      } else {
        setAuthObj({ user: null, loading: false,
          userName:'',updateAuthObjUserName: updateAuthObjUserName 
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={authObj}>{children}</AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
