import React from "react";
import { Typography, Select, Row, Col } from "antd";
import { TestTemplate } from "../../../interfaces/Home";
import "./blockStyles.css";

const { Title } = Typography;
const { Option } = Select;

interface Props {
  isDarkMode: boolean;
  selectedType: string;
  selectedKey: string;
  testTemplates: TestTemplate;
  setSelectedKey: React.Dispatch<React.SetStateAction<string>>;
}

export const TestKey: React.FC<Props> = ({
  isDarkMode,
  selectedType,
  selectedKey,
  testTemplates,
  setSelectedKey,
}) => {
  // console.log(`TestKey selectedType:`,selectedType);
  
  return (
    <>
      <div className="justifyCenter">
        <Row justify="center">
          <Col xs={24}>
            <h5 style={{textAlign:'center',marginBottom:'15px'}}>Test Type:  {selectedType}</h5>
            <Title
              style={{ color: isDarkMode ? "#f0f0f0" : "#434343", textAlign:'center' }}
              level={4}
            >
              Select Test
            </Title>
          </Col>
          <Col xs={24}>
            <Select
              showSearch
              style={{ width: "100%", minWidth:'150px' }}
              value={selectedKey}
              onChange={(ev) => setSelectedKey(ev as string)}
              filterOption={(input, option) =>
                option!==undefined&&option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {Object.keys(testTemplates[selectedType]).map((testType, indx) => (
                
                  testType!=='Description'&&
                  <Option value={testType} key={`${testType}-${indx}`}>
                    {testType}
                  </Option>
                
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      {
        selectedKey!==''
        &&
        Object.keys(testTemplates[selectedType][selectedKey]).find((val)=>val==='Description')&&
          <div className="justifyCenter" style={{marginTop:'0px'}}>
          
            <strong>Description:</strong>
          {
            ` ${Object.values(testTemplates[selectedType][selectedKey])[Object.keys(testTemplates[selectedType][selectedKey]).findIndex((val)=>val==='Description')]}`
          }
        </div>
      }
      </>
  );
};
