import { emitKeypressEvents } from "readline";
import { fridaTesterFirebase } from "../../firebase/";
import { AccessControl, Groups } from "../../interfaces/Home";

const fetchGroupsFb = async (uid: string): Promise<Groups> => {
  let ref = fridaTesterFirebase.database().ref();
  //Sacar a qué grupos tiene acceso el usuario
  const access: AccessControl = (
    await ref.child(`AccessControl/${uid}`).once("value")
  ).val();
  if (access.Groups) {
    const gruposDelUsuario = Object.keys(access.Groups);
    const greedy = false;
    const filteredGroups: Groups = {} as Groups;

    if (!greedy) {
      //Query de los grupos del usuario exclusivamente (toma toma más tiempo)
      for (let g of gruposDelUsuario) {
        const grp: { Enabled: boolean, Name: string, Archived:boolean,LastUpdateTimeout:string } = (await ref.child("Groups").child(g).once("value")).val();
        filteredGroups[g] = grp;
      }
    } else {
      //Leer todos los grupos de una, y filtrar en memoria (consume más datos)
      const groups: Groups = (await ref.child("Groups").once("value")).val();
      for (let grp of Object.keys(groups)) {
        if (gruposDelUsuario.includes(grp)) {
          filteredGroups[grp] = groups[grp];
        }
      }
    }
    return filteredGroups;
  } else {
    return {};
  }

};

const createGroup = async (
  uid: string,
  Name: string,
  Enabled: boolean,
  Type: string,
) => {
  let Archived:boolean=false
  const ref = fridaTesterFirebase.database().ref();
  const groupID = fridaTesterFirebase.database().ref().child(`Groups`).push().key;
  await ref.child(`Groups/${groupID}`).set({Archived, Enabled, Name,Type, LastUpdateTimeout:"48" });
  await ref.child(`AccessControl/${uid}/Groups/${groupID}`).set(true);
  const userTeamMates=(await ref.child(`AccessControl/${uid}/TeamMates`).once('value')).val()
  if(userTeamMates)
  {
    // console.log(`contiene datos:`,userTeamMates);
    // console.log(`nuevo id para grupo:`,groupID);
    Object.keys(userTeamMates).forEach(async(tm)=>{
      // console.log(`tm:`,tm);
      await ref.child(`AccessControl/${tm}/Groups/${groupID}`).set(true);
    })
  }

};

export { fetchGroupsFb, createGroup };
