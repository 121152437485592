import { access } from "fs";
import { fridaTesterFirebase } from "../../firebase/index";
import { AccessControl, GroupInformation, Groups, Log, Machine, Result, TeamMates, Test, TestTemplate, User } from "../../interfaces/Home";
import { getEmailName } from "../../utilities";
import { ListUsers, MessagesError } from "./interface";

const checkAccess = async (uid: string, group: string): Promise<boolean> => {
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl = (
    await ref.child(`AccessControl/${uid}`).once("value")
  ).val();
  if (access !== null) {
    const { Groups } = access;
    if (Groups[group]) {
      return true;
    } else {
      return false;
    }
  }
  else
    return false
};

const getUserGroups=async(userEmail:string)=>{
   let totalGroupsName:string[]=[]
   let listUsers:ListUsers[]=[]

   let ref = fridaTesterFirebase.database().ref();
  // Inicio obtener toda la lista de correos/usuarios registrados
   // //    const allAccess = (
   // //     await ref.child(`AccessControl`).once("value")
   // //   ).val();
      
   // //   let allUsers=Object.keys(allAccess).map((usr,indx)=>{
   // //      return Object.values(allAccess as AccessControl)[indx].Mail
   // //   })
   // //   console.log(`allUsers:`,allUsers);
  // Fin obtener toda la lista de correos/usuarios registrados

  const access: AccessControl = (
    await ref.child(`AccessControl`).orderByChild('Mail').equalTo(userEmail).once("value")
  ).val();
  try{
    let groupsName=Object.keys(Object.values(access)[0].Groups).map(async(gp)=>{
      let res = (await ref.child(`Groups/${gp}/Name`).once('value')).val()
      // let res:GroupInformation = (await ref.child(`Groups/${gp}`).once('value')).val()
      
      // let res:Groups = (await ref.child(`Groups`).once('value')).val()
      // console.log(`res:`,res);
      
      if(res.Enabled)
      {
         totalGroupsName.push(res.Name)
         // console.log(`Object.values(Object.keys(res[gp])):`,Object.values(Object.keys(res[gp])));
         // listUsers.push({
         //    mail:'',
         //    uid:'',
         //    groupName:res[gp].Name,
         //    gid:gp,
         // })
      }
   })
   await Promise.all(groupsName)
  }
  catch(e){
   console.log(`error:`,e);
  }
  return totalGroupsName as string[]
}

const getAccessControl=async()=>{
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl = (
    await ref.child(`AccessControl`).once("value")
  ).val();
  return access
}

const getTeamMates=async(userEmail:string)=>{
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl[] = (
    await ref.child(`AccessControl`).orderByChild('Mail').equalTo(userEmail).once("value")
  ).val() as AccessControl[];
  let uid:any=Object.keys(access)[0]
  return access[uid].TeamMates?access[uid].TeamMates:null as TeamMates|null
}

const addTeamMate=async(mail:string,uid:string)=>{
  let messageError:MessagesError={message:'',type:'',
  display:false}

  let userTm: AccessControl|null=null
  let tmid=''
  let teamMateName=''
  console.log(`mail:`,mail);
  console.log(`uid:`,uid);
  let ref = fridaTesterFirebase.database().ref();
  userTm = (
    await ref.child(`AccessControl`).orderByChild('Mail').equalTo(mail).once("value")
  ).val();
  if(userTm)
  {
    console.log(`Existe userTm:`,userTm);
    tmid=Object.keys(userTm)[0]
    console.log(`Existe tmid:`,tmid);
    //validar si contiene la propiedad Name, si no, se le agrega
    if(Object.values(userTm)[0].hasOwnProperty('Name'))
    {
      console.log(`Existe y contien Name:`,Object.values(userTm)[0].Name);
      teamMateName=Object.values(userTm)[0].Name
      try
      {
        await ref.child(`AccessControl/${uid}/TeamMates/${tmid}/`).set({Mail:Object.values(userTm)[0].Mail,Name:teamMateName});
        messageError.display=true
        messageError.type='success'
        messageError.message=`The user was added successfully.`
      }
      catch(e){
        messageError.display=true
        messageError.type='error'
        messageError.message=`The user could not be added correctly.`
      }
    }
    else
    {
      console.log(`Existe y pero NO contien Name, nuevo name:`,getEmailName(Object.values(userTm)[0].Mail));
      teamMateName=getEmailName(Object.values(userTm)[0].Mail)
      try{
        await ref.child(`AccessControl/${tmid}/Name`).set(teamMateName);
        await ref.child(`AccessControl/${uid}/TeamMates/${tmid}/`).set({Mail:Object.values(userTm)[0].Mail,Name:teamMateName});
      }
      catch(e)
      {
      }
    }
  }
  else
  {
    messageError.display=true
    messageError.type='error'
    messageError.message=`The user doesn't exist, is not registered.`
  }
  console.log(`return messageError:`,messageError);
  return messageError
}

const removeTeamMate=async(uid:string,tmid:string)=>{
  let messageError:MessagesError={message:'',type:'',
  display:false}
  let ref = fridaTesterFirebase.database().ref();
  console.log(`uid:`,uid);
  console.log(`tmid:`,tmid);
  try
  {
    await ref.child(`AccessControl/${uid}/TeamMates/${tmid}/`).remove();
    // await ref.child(`AccessControl/${tmid}/Groups/${tmid}/`).remove();
    messageError.display=true
    messageError.type='success'
    messageError.message=`The user was removed successfully.`
  }
  catch(e)
  {
    console.log(`error:`,e);
    messageError.display=true
    messageError.type='error'
    messageError.message=`Error to remove the user.`
  }
  return messageError
}

const updateUserName=async(uid:string,newName:string)=>{
  let ref = fridaTesterFirebase.database().ref();
  let messageError:MessagesError={message:'',type:'',
  display:false}
  try
  {
    await ref.child(`AccessControl/${uid}/Name`).set(newName);
    let tmpAllAccessUsers:AccessControl=(await ref.child(`AccessControl/`).once('value')).val()
    Object.values(tmpAllAccessUsers).forEach(async(val,indx)=>{
      if(val.TeamMates)
        await ref.child(`AccessControl/${Object.keys(tmpAllAccessUsers)[indx]}/TeamMates/${uid}/Name`).set(newName);
    })
    messageError.display=true
    messageError.type='success'
    messageError.message=`User Name was updated successfully.`
  }
  catch(e){
    console.log(`error:`,e);
  }
  return messageError
}

export {
  checkAccess,
  getAccessControl,
  getUserGroups,
  getTeamMates,
  addTeamMate,
  removeTeamMate,
  updateUserName,
};
