import React, { useEffect, useState } from "react";
import LoginView from "./view";
import { useAuth } from "../../firebase/auth";
import { SignInForm, SignStatus, SignUpForm } from "../../interfaces/auth";
import { loginOption } from "../../constants/login";
import { doSignIn, doSignUp, fetchValidDomains } from "../../firebase";
import { Loader } from "../../components/Loader";
import { notification } from "antd";

interface Props {
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
}

const Login: React.FC<Props> = ({ isDarkMode, toggleTheme }) => {
  const authObj = useAuth();
  const [logOption, setLogOption] = useState(loginOption.signIn);
  const [domains, setDomains] = useState<string[]>([]);
  const [fetchingDom, setFetchingDom] = useState(false);
  const [loginin, setLoginin] = useState(false);
  const [status, setStatus] = useState<SignStatus>({
    status: undefined,
    message: undefined,
  });

  const Formlayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const FormLayoutUp = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const onFinishSignIn = async (values: SignInForm) => {
    setLoginin(true);
    const res = await doSignIn(values.email, values.password);
    if (res.message?.includes("Please verify your email")) {
      notification.info({
        message: "Verify your email",
        description: "Please verify your email",
      });
    }
    setStatus(res);
    setLoginin(false);
  };

  const onFinishSignUp = async (values: SignUpForm) => {
    setLoginin(true);
    const res = await doSignUp(values.email, values.password,values.userName);
    if (res.message?.includes("verification email")) {
      notification.success({
        message: "Account created",
        description:
          "A verification email has been sent to your email, please check your email and verify your account",
      });
      setLogOption(loginOption.signIn);
    }
    setStatus(res);
    setLoginin(false);
  };

  const fetchDomains = async () => {
    setFetchingDom(true);
    const newDoms = await fetchValidDomains();
    setDomains(newDoms);
    setFetchingDom(false);
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  useEffect(() => {
    console.log(`Login`);
    console.log(`authObj:`,authObj);
    
    if (!authObj.loading) {
      if (authObj.user) {
        if (!status.message?.includes("Please verify your email"))
          window.location.replace("/home");
      }
    }
  }, [authObj, status, status.message]);

  useEffect(() => {
    setStatus({ status: undefined, message: undefined });
  }, [logOption]);

  return (
    <>
      {!authObj.loading ? (
        !authObj.user ? (
          <LoginView
            status={status}
            loginin={loginin}
            domains={domains}
            logOption={logOption}
            Formlayout={Formlayout}
            isDarkMode={isDarkMode}
            fetchingDom={fetchingDom}
            FormLayoutUp={FormLayoutUp}
            toggleTheme={toggleTheme}
            setLogOption={setLogOption}
            onFinishSignIn={onFinishSignIn}
            onFinishSignUp={onFinishSignUp}
          />
        ) : (
          <Loader />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Login;
