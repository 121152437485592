import { access } from "fs";
import { useLocation } from "react-router-dom";
import { fridaTesterFirebase } from "../../firebase/index";
import { AccessControl, GroupInformation, Log, Machine, Result, Test, TestTemplate, User } from "../../interfaces/Home";
import { createExcel } from "./utilities";


const checkAccess = async (uid: string, group: string): Promise<boolean> => {
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl = (
    await ref.child(`AccessControl/${uid}`).once("value")
  ).val();
  if (access !== null) {
    const { Groups } = access;
    if (Groups[group]) {
      return true;
    } else {
      return false;
    }
  }
  else
    return false
};

const getAccessControl=async()=>{
  
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl = (
    await ref.child(`AccessControl`).once("value")
  ).val();
  return access
}

const fetchGroupTests = async (group: string) => {
  const ref = fridaTesterFirebase.database().ref();
  const tests: any = (
    await ref.child(`Groups/${group}/Tests`).once("value")
  ).val();
  if (tests) {
    const testArr: Test[] = Object.values(tests);
    const testsId: string[] = Object.keys(tests);
    return { tests: testArr, testsId };
  } else {
    return { tests: [], testsId: [] };
  }
};

const fetchTestTemplates = async (): Promise<TestTemplate> => {
  
  
  const ref = fridaTesterFirebase.database().ref();
  const tests: TestTemplate = (
    window.location.hostname.includes('-qa')||window.location.hostname.includes('localhost')?
      await ref.child(`TestTemplatesQA`).once("value")
    :
      await ref.child(`TestTemplates`).once("value")

  ).val();
  return tests;
};

const addGroupTest = async (group: string, newTest: Test) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${group}/Tests`).push(newTest);
};

const deleteGroupTestFb = async (group: string, test: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${group}/Tests/${test}`).remove();
};

const updateGroupTestFb = async (
  group: string,
  testID: string,
  newTest: Test
) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${group}/Tests/${testID}`).update(newTest);
};

const AddUserFb = async (user: User) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref
    .child(`Machines`)
    .push(user)
    .then(async (res) => {
      await res.update({ ID: res.key });
    });
};

const getGroupData = async (id: string) => {
  const ref = fridaTesterFirebase.database().ref();
  const groupData: GroupInformation = (await ref.child(`Groups/${id}/`).once("value")).val();
  return groupData;
};

const duplicateGroup=async(userID:string,userEmail:string,currentGroupID:string,newGroupName:string)=>{
  const ref = fridaTesterFirebase.database().ref();
  const groupID = fridaTesterFirebase.database().ref().child(`Groups`).push().key;
  const groupData: GroupInformation = (await ref.child(`Groups/${currentGroupID}/`).once("value")).val();
  groupData.Name=newGroupName
  await ref.child(`Groups/${groupID}`).set(groupData);
  await ref.child(`AccessControl/${userID}/Mail`).set(userEmail);
  await ref.child(`AccessControl/${userID}/Groups/${groupID}`).set(true);

}
// const getGroupName = async (id: string) => {
//   const ref = fridaTesterFirebase.database().ref();
//   const name = (await ref.child(`Groups/${id}/Name`).once("value")).val();
//   return name;
// };

// const isGroupEnable = async (id: string) => {
//   const ref = fridaTesterFirebase.database().ref();
//   const isEnable = (await ref.child(`Groups/${id}/Enabled`).once("value")).val();
//   return isEnable;
// }

const setEmailToShare = async (emailUser: string, id: string, setMessageAddUserToGroup: (message: string) => void, setDisplayErrorEmail: (display: boolean) => void) => {
  const ref = fridaTesterFirebase.database().ref();
  try {
    const query = ref.child('AccessControl').orderByChild('Mail').equalTo(emailUser);
    let queriedUser: any = (await query.once('value')).val()
    let res = {
      message: 'Email does not exist',
      updated: false
    }
    if (queriedUser) {
      let key = Object.keys(queriedUser)[0];
      await ref.child(`AccessControl/${key}/Groups/${id}`).set(true);
      res.message = `Group shared with user: ${emailUser}`;
      res.updated = true;
      setMessageAddUserToGroup(res.message)
      setDisplayErrorEmail(res.updated)
    } else {
      setMessageAddUserToGroup(res.message)
      setDisplayErrorEmail(res.updated)
    }
  }
  catch (e) {
    console.log(`error to share group:`, e);
  }
}

const updateStatusArchivedGroup = async (status: boolean, id: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${id}/Archived`).set(status);
}

const updateIsGroupEnabled = async (status: boolean, id: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${id}/Enabled`).set(status);
}

const updateTimeout = async (id: string, newTime: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${id}/LastUpdateTimeout`).set(newTime);
};

const updateGroupName = async (id: string, name: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Groups/${id}/Name`).set(name);
};

const getLogsData = async (groupID: string) => {
  const ref = fridaTesterFirebase.database().ref();
  let machines: Machine = (await ref.child('Machines').orderByChild('Group').equalTo(groupID).once('value')).val()
  let allLogs: Log[] = []
  let arrayResults: Result[] = []
  let machineID: string = ''
  try {
    const promiseDataLogs = Object.values(machines).map(async (m: Machine) => {
      machineID = m.Name
      let res = (await ref.child(`Logs/${groupID}/${m.ID}`).orderByChild('date').limitToLast(1).once('value')).val()
      return res
    })

    const allLogsData: Log[] = await Promise.all(promiseDataLogs)
    allLogsData && allLogsData.forEach((log) => allLogs.push(log))
    Object.values(allLogs[0]).forEach((log: Log) => {
      arrayResults = log.results
    })
  }
  catch (e) {
    console.log(`error:`, e);
  }

  return { machineID, allLogs, arrayResults }
}
const getMachinesData = async (groupID: string) => {
  const ref = fridaTesterFirebase.database().ref();
  let machine: Machine[] = (await ref.child('Machines').orderByChild('Group').equalTo(groupID).once('value')).val()
  let totalTest = 0
  let res = (await ref.child(`Groups/${groupID}/Tests`).once('value')).val()
  if (res)
    totalTest = Object.keys(res).length
  return { machine, totalTest }
}

const createResultCvsFile = async (
  groupID: string, groupName: string
) => {
  const { machineID, allLogs, arrayResults } = await getLogsData(groupID)
  const { machine, totalTest } = await getMachinesData(groupID)
  let totalTestsAllMachines=0
  machine&&Object.keys(machine).forEach((m,ind)=>{
    totalTestsAllMachines+=Object.values(machine)[ind].total
  })
  let tmpTotalTest = totalTest
  // if(totalTest===0&&arrayResults.length>0)
  tmpTotalTest = arrayResults.length
  createExcel(allLogs, machineID, machine, tmpTotalTest, groupID, arrayResults, groupName,totalTestsAllMachines)
};


export {
  AddUserFb,
  checkAccess,
  addGroupTest,
  getGroupData,
  fetchGroupTests,
  updateGroupName,
  deleteGroupTestFb,
  updateGroupTestFb,
  fetchTestTemplates,
  createResultCvsFile,
  updateIsGroupEnabled,
  setEmailToShare,
  updateStatusArchivedGroup,
  duplicateGroup,
  getAccessControl,
  updateTimeout,
};
