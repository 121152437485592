const fridaTesterJSON = {
  apiKey: "AIzaSyAQFDEOAuratbU7ebOb7dxlcCWffHUL4lk",
  authDomain: "tester.fridaplatform.online",
  databaseURL: "https://frida-tester-default-rtdb.firebaseio.com",
  projectId: "frida-tester",
  storageBucket: "frida-tester.appspot.com",
  messagingSenderId: "995188397252",
  appId: "1:995188397252:web:ca79c9ba7507fa0a58a591",
  measurementId: "G-12EDCWDLKV",
};

export { fridaTesterJSON };
