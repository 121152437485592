import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Steps,
  Table,
  Typography,
} from "antd";
import { Log, Result, Test, TestTemplate, User } from "../../interfaces/Home";
import { UserBreadCrumbs } from "../../components/User/BreadCrumbs";
import { ColumnsType } from "antd/es/table";
import {
  CloseOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { EditForm } from "../Group/TestBlock";

const { Title } = Typography;
const { Step } = Steps;

interface Props {
  user: User;
  logs: Log[];
  selectedTest: Test;
  groupTests: Test[];
  isDarkMode: boolean;
  selectedKey: string;
  currentStep: number;
  fetchingUser: boolean;
  groupResults: Result[];
  cols: ColumnsType<Log>;
  visibleAddBlock: boolean;
  openManageDrawer: boolean;
  visibleEditBlock: boolean;
  selectedResults: Result[];
  personalResults: Result[];
  testForm: FormInstance<any>;
  testTemplates: TestTemplate;
  editForm: FormInstance<any>;
  notifyForm: FormInstance<any>;
  steps: {
    title: string;
    content: JSX.Element;
  }[];
  openDrawerResults: boolean;
  testCols: ColumnsType<Test>;
  testUserCols: ColumnsType<Test>;
  resultCols: ColumnsType<Result>;
  next: () => void;
  prev: () => void;
  handleCloseSelectResults: () => void;
  handleEditNotifyValue: (vals: any) => void;
  onFinishEditForm: (vals: any) => Promise<void>;
  toggleVisibleAddBlock: (visible: boolean) => void;
  toggleVisibleEditBlock: (visible: boolean) => void;
  toggleOpenManageDrawer: (drawer: boolean) => void;
}

const UserView: React.FC<Props> = ({
  user,
  logs,
  cols,
  steps,
  testCols,
  testForm,
  editForm,
  resultCols,
  isDarkMode,
  groupTests,
  notifyForm,
  currentStep,
  selectedKey,
  groupResults,
  testUserCols,
  fetchingUser,
  selectedTest,
  testTemplates,
  personalResults,
  visibleAddBlock,
  selectedResults,
  visibleEditBlock,
  openManageDrawer,
  openDrawerResults,
  next,
  prev,
  onFinishEditForm,
  handleEditNotifyValue,
  toggleVisibleAddBlock,
  toggleOpenManageDrawer,
  toggleVisibleEditBlock,
  handleCloseSelectResults,
}) => {
  const [drawerWidth, setDrawerWidth] = useState(0);

  const computeDrawerWidth = () => {
    let width = window.innerWidth;
    if (width >= 1700) {
      setDrawerWidth((width / 5) * 3);
    } else if (width >= 1000) {
      setDrawerWidth((width / 4) * 3);
    }
  };

  useEffect(() => {
    computeDrawerWidth();
    window.addEventListener("resize", computeDrawerWidth);
    return () => {
      window.removeEventListener("resize", () => { });
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="mainContainerUser">
      <Title style={{ color: isDarkMode ? "#f0f0f0" : "#595959" }} level={3}>
        {user.Name}
      </Title>
      <UserBreadCrumbs user={user} isDarkMode={isDarkMode} />
      <Row justify="end">
        <Col className="col-end">
          <Button
            type="primary"
            className="ManageBtn"
            style={{ borderRadius: "7px" }}
            icon={<SettingOutlined />}
            onClick={() => toggleOpenManageDrawer(true)}
          >
            Manage
          </Button>
        </Col>
      </Row>
      <Table
        loading={fetchingUser}
        columns={cols}
        dataSource={logs}
        pagination={logs.length > 7 ? {
          showSizeChanger: true,
          size: "small",
          defaultPageSize: 8,
          pageSizeOptions: ["8", "20", "30", "50"],
        } : false}
        rowKey="ID"
      />
      <Drawer
        visible={openManageDrawer}
        onClose={() => toggleOpenManageDrawer(false)}
        width={drawerWidth}
      >
        <Title style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }} level={3}>
          NotifyFailure
        </Title>
        <Row style={{ marginBottom: 20 }}>
          <Col xs={12}>
            <Form
              form={notifyForm}
              initialValues={{
                NotifyFailure: user.NotifyFailure,
              }}
              onFinish={handleEditNotifyValue}
            >
              <Form.Item
                name="NotifyFailure"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4};?)+$/g
                    ),
                    message: "Please provide mails separated by ;",
                  },
                ]}
                required={false}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Title style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }} level={3}>
          Group Tests
        </Title>
        <Table
          columns={testCols}
          dataSource={groupTests}
          pagination={false}
        />
        <Title
          style={{ color: isDarkMode ? "#f0f0f0" : "#434343", marginTop: 20 }}
          level={3}
        >
          Personalized Tests
        </Title>
        {!visibleAddBlock && (
          <Button
            style={{ marginBottom: 20 }}
            type="ghost"
            icon={<PlusCircleOutlined />}
            onClick={() => toggleVisibleAddBlock(true)}
          >
            Add Test
          </Button>
        )}
        {visibleAddBlock && (
          <Row justify="center">
            <Col span={24}>
              <div className="cardBlockAdd">
                <Row justify="space-between">
                  <Col span={22} className="colCenter">
                    <Title
                      style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
                      level={4}
                    >
                      Add new Test to {user.Name}
                    </Title>
                  </Col>
                  <Col span={2}>
                    <Button
                      type="text"
                      shape="circle"
                      icon={<CloseOutlined style={{ fontSize: 20 }} />}
                      onClick={() => toggleVisibleAddBlock(false)}
                    />
                  </Col>
                  <Col span={24}>
                    <Steps current={currentStep}>
                      {steps.map((step, indx) => (
                        <Step title={step.title} key={indx} />
                      ))}
                    </Steps>
                  </Col>
                  <Col span={24}>{steps[currentStep].content}</Col>
                  <Col span={24} className="stepsBtns">
                    {currentStep > 0 && (
                      <Button
                        style={{ margin: "0 8px" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                      <Button
                        disabled={currentStep === 0 && selectedKey === ""}
                        type="primary"
                        onClick={() => next()}
                      >
                        Next
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      <Button onClick={() => testForm.submit()} type="primary">
                        Done
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        {visibleEditBlock && (
          <Row justify="center" style={{ marginTop: 20 }}>
            <Col span={24}>
              <div className="cardBlockAdd">
                <Row justify="space-between">
                  <Col span={22} className="colCenter">
                    <Title
                      style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
                      level={4}
                    >
                      Edit test
                    </Title>
                  </Col>
                  <Col span={2}>
                    <Button
                      type="text"
                      shape="circle"
                      icon={<CloseOutlined style={{ fontSize: 20 }} />}
                      onClick={() => toggleVisibleEditBlock(false)}
                    />
                  </Col>
                  <Col span={24}>
                    <EditForm
                      testForm={editForm}
                      isDarkMode={isDarkMode}
                      selectedTest={selectedTest}
                      testTemplates={testTemplates}
                      onFinishTestForm={onFinishEditForm}
                      selectedKey={selectedTest.TestKey}
                      selectedType={selectedTest.TestType}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <Table
          columns={testUserCols}
          dataSource={user.Tests}
          pagination={false}
        />
      </Drawer>
      <Drawer
        visible={openDrawerResults}
        onClose={handleCloseSelectResults}
        width={drawerWidth}
      >
        <Title style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }} level={3}>
          Results
        </Title>
        <Table
          columns={resultCols}
          dataSource={selectedResults}
          pagination={false}
        />
        {/* <Title style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }} level={3}>
          Group Results
        </Title>
        <Table
          columns={resultCols}
          dataSource={groupResults}
          pagination={false}
        />
        <Title
          style={{ color: isDarkMode ? "#f0f0f0" : "#434343", marginTop: 20 }}
          level={3}
        >
          Personal Results
        </Title>
        <Table
          columns={resultCols}
          dataSource={personalResults}
          pagination={false}
        /> */}
      </Drawer>
    </div>
  );
};

export default UserView;
