import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  List,
  Modal,
  Row,
  Tag,
  Typography,
  Radio,
  Tabs,
  Badge,
} from "antd";
import {
  AppleOutlined,
  ClockCircleOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Groups } from "../../interfaces/Home";
import { Link } from "react-router-dom";
import { showGroups } from "./interface";

const { Title, Text } = Typography;

interface Props {
  cols: number;
  groups: Groups;
  pagination: number;
  modalCreate: boolean;
  searchGroups: string;
  fetchingGroups: boolean;
  isDarkMode: boolean;
  groupForm: FormInstance<any>;
  onFinishCreateForm: (vals: any) => void;
  handleSearchGroups: (value: string) => void;
  setModalCreate: React.Dispatch<React.SetStateAction<boolean>>;
  viewGroups: showGroups;
  setViewGroups: (val: showGroups) => void;
  groupsViewtype: string;
  handleGroupsViewType: (val: string) => void;
  active: Groups | null;
  archived: Groups | null;
}

const GroupsView: React.FC<Props> = ({
  cols,
  groups,
  groupForm,
  isDarkMode,
  pagination,
  modalCreate,
  searchGroups,
  fetchingGroups,
  setModalCreate,
  handleSearchGroups,
  onFinishCreateForm,
  viewGroups,
  setViewGroups,
  groupsViewtype,
  handleGroupsViewType,
  active,
  archived,
}) => {
  const { TabPane } = Tabs;
  const operations = (
    <div
      style={{
        width: "450px",
        //backgroundColor:'red',
        // width:window.innerWidth > 1067 ? '800px' : '80px'
      }}
    >
      <Row justify="start" className="search-container">
        <Col
        xxl={16}
        xl={16}
        lg={16}
        md={16}
        sm={12}
        xs={12}
        >
          <Input.Search
            // style={{ width:'275px' }}
            style={{ marginBottom: 0, marginRight: "55px" }}
            value={searchGroups}
            onChange={(ev) => handleSearchGroups(ev.target.value)}
            placeholder="Search group"
          />
        </Col>
        <Col
          xxl={2}
          xl={4}
          lg={1}
          md={1}
          sm={1}
          xs={12}
          // style={{ marginBottom: 0,marginRight:'55px' }}
          style={{ width: "75px", marginLeft: "10px" }}
        >
          <Button type="primary" onClick={() => setModalCreate(true)}>
            Create Group
          </Button>
        </Col>
      </Row>
    </div>
  );

  const displayGroups = () => (
    <Row justify="end" className="showGroups-container">
      <Col
        //  xxl={5}
        //   xl={5}
        //   lg={2.8}
        //   md={6}
        //   sm={6}
        //   xs={5}
        style={{ marginBottom: 0 }}
      >
        <Button
          shape="round"
          icon={<TableOutlined />}
          size={"small"}
          onClick={() => setViewGroups({ mosaic: true, list: false })}
          style={{
            marginRight: "5px",
            color: viewGroups.mosaic ? "#B37FEB" : "gray",
            borderColor: viewGroups.mosaic ? "#B37FEB" : "gray",
          }}
        >
          Grid
        </Button>

        <Button
          shape="round"
          icon={<UnorderedListOutlined />}
          size={"small"}
          onClick={() => setViewGroups({ mosaic: false, list: true })}
          style={{
            color: viewGroups.list ? "#B37FEB" : "gray",
            borderColor: viewGroups.list ? "#B37FEB" : "gray",
          }}
        >
          List
        </Button>
      </Col>
    </Row>
  );

  return (
    <div className="mainContainerGroups">
      {/* <Title style={{ color: isDarkMode ? "#d9d9d9" : "#595959" }} level={3}>
        Groups
      </Title> */}

      {/* <Row justify="end" className="search-container">
        <Col
          xxl={3}
          xl={4}
          lg={4}
          md={4}
          sm={24}
          xs={24}
          style={{ marginBottom: 0 }}
        >
          <Button type="primary" onClick={() => setModalCreate(true)}>
            Create Group
          </Button>
        </Col>
        <Col xxl={6} xl={8} lg={15} md={15} sm={24} xs={24}>
          <Input.Search
            value={searchGroups}
            onChange={(ev) => handleSearchGroups(ev.target.value)}
            placeholder="Search group"
          />
        </Col>
      </Row> */}
      <Tabs
        defaultActiveKey="1"
        onChange={(e) => handleGroupsViewType(String(e))}
        tabBarExtraContent={operations}
        

      >
        {/* Active groups */}
        <TabPane
        
          tab={
            <span style={{width:'100px'}}>
              Active Groups
              {searchGroups !== "" && active && Object.keys(active).length > 0 && (
                <Badge
                  count={
                    Object.keys(active).length > 0
                      ? Object.keys(active).length
                      : 0
                  }
                  style={{
                    margin: "0px 5px 0px 5px",
                    backgroundColor: "#33AB2B",
                  }}
                />
              )}
            </span>
          }
          key="1"
        >
          <div>
            {displayGroups()}
            <div style={{
              // backgroundColor:'green',
              display:'flex', 
              justifyContent:'center',
              // width:'2100px', 
              }}
            >
              {viewGroups.list && active ? ( //list
                <List
                  itemLayout={"horizontal"}
                  // grid={{ gutter: 1, column: 1 }}
                  dataSource={Object.keys(active)}
                  loading={fetchingGroups}
                  size="small"
                  key="lista"
                  pagination={
                    Object.keys(active).length > 10
                      ? {
                          defaultPageSize: 10,
                          // defaultPageSize: pagination,
                          pageSizeOptions: ["10", "20", "50"],
                          showSizeChanger: true,
                          // pageSize: 7,
                        }
                      : false
                  }
                  style={{
                    marginTop: "53px",
                    width:'2100px'
                  }}
                  renderItem={(item, indx) => (
                    <>
                      {groupsViewtype === "1" && !active[item].Archived && (
                        <List.Item
                          key={indx}
                          style={
                            {
                              // marginBottom:'33px'
                            }
                          }
                        >
                          <Link to={`/home/group/${item}`}>
                            <List.Item.Meta
                              avatar={
                                <TeamOutlined
                                  style={{
                                    fontSize: "40px",
                                    color: isDarkMode ? "#d9d9d9" : "#722ed1",
                                    marginTop: "6px",
                                  }}
                                />
                              }
                              title={<a>{active[item].Name}</a>}
                              // description={`GroupID: ${item}`}
                            />
                          </Link>
                          <Link
                            to={`/home/group/${item}`}
                            style={{
                              textDecoration: "none",
                              color: isDarkMode ? "#d9d9d9" : "black",
                            }}
                          >
                            <div>GroupID: ${item}</div>
                          </Link>
                          {active[item].Type ? (
                            <Link
                              to={`/home/group/${item}`}
                              style={{
                                textDecoration: "none",
                                color: isDarkMode ? "#d9d9d9" : "black",
                              }}
                            >
                              <div> Type: {active[item].Type}</div>
                            </Link>
                          ) : (
                            <div></div>
                          )}
                          <Tag color={active[item].Enabled ? "green" : "error"}>
                            {active[item].Enabled ? "enabled" : "disabled"}
                          </Tag>
                        </List.Item>
                      )}
                    </>
                  )}
                />
              ) : (
                active && ( //Cards
                <div style={{
                  display:'flex', 
                  justifyContent:'center',
                  width:'auto',
                    maxWidth:'2100px', 
                  }}
                >
                  <List
                    itemLayout={"horizontal"}
                    grid={{ gutter: 8, column: cols }}
                    dataSource={Object.keys(active)}
                    loading={fetchingGroups}
                    size="small"
                    key="Mosaico"
                    pagination={
                      Object.keys(active).length > 12
                        ? {
                            defaultPageSize: 12,
                            pageSizeOptions: ["12", "24", "48"],
                            showSizeChanger: true,
                            // pageSize: 12,
                          }
                        : false
                    }
                    style={{
                      marginTop: "53px",
                      display:'flex', 
                  justifyContent:'center',
                    }}
                    renderItem={(item, indx) => (
                      <Row
                        // gutter={16}
                        gutter={[8, 8]}
                        justify="space-around"
                      >
                        {groupsViewtype === "1" && !active[item].Archived && (
                          <List.Item key={`${item}-${indx}`}>
                            <Col
                              style={{
                                // backgroundColor:'red',
                                // width:'800px',
                                width:'1000px',
                                // minWidth:'215px'
                              }}
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              // xs={{span:12,
                              // }}
                              // sm={{
                              //   span:3,
                              // }}
                              // md={{span:3,
                              // }}
                              // lg={{span:3,
                              // }}
                              // xl={{span:3,
                              // }}
                              // className="gutter-row"
                            >
                              <Link to={`/home/group/${item}`}>
                                <Card
                                  className="groupCard"
                                  style={{ borderRadius: 5, textAlign: "center" }}
                                >
                                  <TeamOutlined
                                    style={{
                                      fontSize: "20px",
                                      color: isDarkMode ? "#d9d9d9" : "#722ed1",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      color: isDarkMode ? "#d9d9d9" : "#595959",
                                      display: "block",
                                      fontSize: "18px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <strong>{active[item].Name}</strong>
                                  </Text>
                                  {active[item].Type && (
                                    <Text
                                      style={{
                                        color: isDarkMode ? "#eeeeee" : "#757575",
                                        display: "block",
                                        fontSize: "11px",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Type: {active[item].Type}
                                    </Text>
                                  )}
                                  <Text
                                    style={{
                                      color: isDarkMode ? "#eeeeee" : "#757575",
                                      display: "block",
                                      fontSize: "11px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    GroupID: {item}
                                  </Text>
                                  <Tag
                                    color={
                                      active[item].Enabled ? "green" : "error"
                                    }
                                  >
                                    {active[item].Enabled
                                      ? "enabled"
                                      : "disabled"}
                                  </Tag>
                                </Card>
                              </Link>
                            </Col>
                          </List.Item>
                        )}
                      </Row>
                    )}
                  />
                </div>
                )
              )}
            </div>
          </div>
        </TabPane>
        {/* Archived groups */}
        <TabPane
          tab={
            <span>
              Archived Groups
              {searchGroups !== "" &&
                archived &&
                Object.keys(archived).length > 0 && (
                  <Badge
                    count={
                      Object.keys(archived).length > 0
                        ? Object.keys(archived).length
                        : 0
                    }
                    style={{
                      margin: "0px 5px 0px 5px",
                      backgroundColor: "#33AB2B",
                    }}
                  />
                )}
            </span>
          }
          key="2"
        >
          <div>
            {displayGroups()}
            <div style={{
              display:'flex', 
              justifyContent:'center',
              }}
            >
              {viewGroups.list && archived ? (
                <List
                  itemLayout={"horizontal"}
                  // grid={{ gutter: 1, column: 1 }}
                  dataSource={Object.keys(archived)}
                  loading={fetchingGroups}
                  size="small"
                  key="lista"
                  pagination={
                    Object.keys(archived).length > 10
                      ? {
                          defaultPageSize: 10,
                          // defaultPageSize: pagination,
                          pageSizeOptions: ["10", "20", "50"],
                          showSizeChanger: true,
                          // pageSize: 7,
                        }
                      : false
                  }
                  style={{
                    marginTop: "53px",
                    width:'2100px'
                  }}
                  renderItem={(item, indx) => (
                    <>
                      {groupsViewtype === "2" && archived[item].Archived && (
                        <List.Item
                          key={`${item}-${indx}`}
                          style={
                            {
                              // marginBottom:'33px'
                            }
                          }
                        >
                          <Link to={`/home/group/${item}`}>
                            <List.Item.Meta
                              avatar={
                                <TeamOutlined
                                  style={{
                                    fontSize: "40px",
                                    color: isDarkMode ? "#d9d9d9" : "#722ed1",
                                    marginTop: "6px",
                                  }}
                                />
                              }
                              title={<a>{archived[item].Name}</a>}
                              // description={`GroupID: ${item}`}
                            />
                          </Link>
                          <Link
                            to={`/home/group/${item}`}
                            style={{
                              textDecoration: "none",
                              color: isDarkMode ? "#d9d9d9" : "black",
                            }}
                          >
                            <div>GroupID: ${item}</div>
                          </Link>
                          {archived[item].Type ? (
                            <Link
                              to={`/home/group/${item}`}
                              style={{
                                textDecoration: "none",
                                color: isDarkMode ? "#d9d9d9" : "black",
                              }}
                            >
                              <div> Type: {archived[item].Type}</div>
                            </Link>
                          ) : (
                            <div></div>
                          )}
                          <Tag color={archived[item].Enabled ? "green" : "error"}>
                            {archived[item].Enabled ? "enabled" : "disabled"}
                          </Tag>
                        </List.Item>
                      )}
                    </>
                  )}
                />
              ) : (
                archived && ( //Cards
                <div style={{
                  // width:'100%',
                  display:'flex', 
                  justifyContent:'center',
                  // width:'auto',
                     
                  }}
                >
                  <div style={{
                    // backgroundColor:'green',
                    display:'flex', 
                    justifyContent:'center',
                    width:'auto',
                      maxWidth:'2100px', 
                    }}
                  >
                    <List
                      itemLayout={"horizontal"}
                      grid={{ gutter: 8, column: cols }}
                      dataSource={Object.keys(archived)}
                      loading={fetchingGroups}
                      size="small"
                      key="Mosaico"
                      pagination={
                        Object.keys(archived).length > 12
                          ? {
                              defaultPageSize: 12,
                              pageSizeOptions: ["12", "24", "48"],
                              showSizeChanger: true,
                              // pageSize: 12,
                            }
                          : false
                      }
                      style={{
                        marginTop: "53px",
                        display:'flex', 
                        justifyContent:'center',
                      }}
                      renderItem={(item, indx) => (
                        <Row
                        // gutter={16}
                        
                        gutter={[8, 8]} //chido
                        
                        >
                          {groupsViewtype === "2" && archived[item].Archived && (
                            <List.Item key={`${item}-${indx}`}>
                            <Col
                              style={{
                                // backgroundColor:'red',
                                width:'1000px'
                              }}
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              // xs={{span:12,
                              // }}
                              // sm={{
                              //   span:3,
                              // }}
                              // md={{span:3,
                              // }}
                              // lg={{span:3,
                              // }}
                              // xl={{span:24
                              // }}
                              // className="gutter-row"
                            >
                              <Link to={`/home/group/${item}`}>
                                <Card
                                  className="groupCard"
                                  style={{ borderRadius: 5, textAlign: "center" }}
                                >
                                  <TeamOutlined
                                    style={{
                                      fontSize: "20px",
                                      color: isDarkMode ? "#d9d9d9" : "#722ed1",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      color: isDarkMode ? "#d9d9d9" : "#595959",
                                      display: "block",
                                      fontSize: "18px",
                                      paddingBottom: "5px",
                                    }}
                                  >
                                    <strong>{archived[item].Name}</strong>
                                  </Text>
                                  {archived[item].Type && (
                                    <Text
                                      style={{
                                        color: isDarkMode ? "#eeeeee" : "#757575",
                                        display: "block",
                                        fontSize: "11px",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Type: {archived[item].Type}
                                    </Text>
                                  )}
                                  <Text
                                    style={{
                                      color: isDarkMode ? "#eeeeee" : "#757575",
                                      display: "block",
                                      fontSize: "11px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    GroupID: {item}
                                  </Text>
                                  <Tag
                                    color={
                                      archived[item].Enabled ? "green" : "error"
                                    }
                                  >
                                    {archived[item].Enabled
                                      ? "enabled"
                                      : "disabled"}
                                  </Tag>
                                </Card>
                              </Link>
                            </Col>
                          </List.Item>
                          )}
                        </Row>
                      )}
                    />
                  </div>
                </div>
              )
              )}
            </div>
          </div>
        </TabPane>
      </Tabs>

      <Modal
        closable
        footer={null}
        onCancel={() => {
          setModalCreate(false);
          groupForm.resetFields();
        }}
        visible={modalCreate}
        title={`Create Group`}
      >
        <Form
          form={groupForm}
          onFinish={onFinishCreateForm}
          initialValues={{ Name: "", Type: "" }}
        >
          <Form.Item
            name="Name"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={
              <Title
                level={5}
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#595959",
                  fontSize: "14px",
                }}
              >
                Group Name
              </Title>
            }
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Type"
            rules={[
              {
                required: true,
              },
            ]}
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={
              <Title
                level={5}
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#595959",
                  fontSize: "14px",
                }}
              >
                Type
              </Title>
            }
          >
            <Radio.Group>
              <Radio value={"Monitor"}>Monitor</Radio>
              <Radio value={"Audit"}>Audit</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GroupsView;
