import React from "react";
import { SignInForm, SignStatus, SignUpForm } from "../../interfaces/auth";
import { Card, Col, Row, Spin, Switch, Typography } from "antd";
import { SignInView, SignUpView } from "../../components/Sign";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../../assets/f-letter.svg";
import QueueAnim from "rc-queue-anim";
import { loginOption } from "../../constants/login";
import "./login.css";

const { Title, Text } = Typography;

interface Props {
  Formlayout: any;
  loginin: boolean;
  FormLayoutUp: any;
  domains: string[];
  logOption: number;
  status: SignStatus;
  isDarkMode: boolean;
  fetchingDom: boolean;
  toggleTheme: (isChecked: boolean) => void;
  onFinishSignIn: (values: SignInForm) => void;
  onFinishSignUp: (values: SignUpForm) => void;
  setLogOption: React.Dispatch<React.SetStateAction<number>>;
}

const LoginView: React.FC<Props> = ({
  status,
  domains,
  loginin,
  logOption,
  Formlayout,
  isDarkMode,
  fetchingDom,
  FormLayoutUp,
  toggleTheme,
  onFinishSignIn,
  onFinishSignUp,
  setLogOption,
}) => {
  return (
    <Row className="main-container">
      <Col xl={8} lg={8} md={24} sm={24} xs={24}>
        <div className="box welcome">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className="box">
                    <Title style={{ color: "white" }} level={1}>
                      <Logo className="fridaLogo" />
                      rida Tester Dashboard
                    </Title>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="box">
                    <Text style={{ color: "white" }}>
                      Frida Tester Dashboard
                    </Text>
                  </div>
                  <div className="box">
                    <Switch
                      checkedChildren={<FontAwesomeIcon icon={faMoon} />}
                      unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
                      onChange={toggleTheme}
                      checked={isDarkMode}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xl={16} lg={16} md={24} sm={24} xs={24}>
        <div className="box">
          <Card
            style={{ borderRadius: 10 }}
            className="loginCard shadowCardLogin"
          >
            {logOption === loginOption.signIn && (
              <Spin spinning={loginin}>
                <QueueAnim appear type={["right", "left"]} leaveReverse>
                  <SignInView
                    status={status}
                    onFinishSignIn={onFinishSignIn}
                    key="signin"
                    Formlayout={Formlayout}
                    setLogOption={setLogOption}
                  />
                </QueueAnim>
              </Spin>
            )}
            {logOption === loginOption.signUp && (
              <Spin spinning={loginin}>
                <QueueAnim appear type={["right", "left"]} leaveReverse>
                  <SignUpView
                    key="signup"
                    status={status}
                    domains={domains}
                    fetchingDom={fetchingDom}
                    Formlayout={FormLayoutUp}
                    onFinishSignUp={onFinishSignUp}
                    setLogOption={setLogOption}
                  />
                </QueueAnim>
              </Spin>
            )}
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default LoginView;
