/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Typography,
  Drawer,
  Row,
  Col,
  Button,
  Steps,
  FormInstance,
  Form,
  Input,
  Switch,
  Tooltip,
  notification,
  Modal,
  Alert,
  Popconfirm,
  Badge,
  Radio,
  Upload,
  message,
  TreeSelect,
  Menu,
  Space,
  Dropdown,
} from "antd";
import {
  InboxOutlined,
  KeyOutlined,
  LogoutOutlined,
  ToolOutlined,
  UploadOutlined,
  FieldTimeOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GroupBreadCrumbs } from "../../components/Group/BreadCrumbs";
import { ColumnsType } from "antd/es/table";
import { Test, TestTemplate, User } from "../../interfaces/Home";
import "./group.css";
import {
  CloseOutlined,
  CopyOutlined,
  FontColorsOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  CloudDownloadOutlined,
  BranchesOutlined,
  DiffOutlined,
  DeliveredProcedureOutlined,
  MergeCellsOutlined,
  SplitCellsOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { EditForm } from "./TestBlock";
import { createResultCvsFile } from "./service";
import { informationEmails, userList } from "./interface";
import EditTestForm from "./TestBlock/EditTestForm";
import { RcFile } from "antd/lib/upload";
import Avatar from "antd/lib/avatar/avatar";

const { Title } = Typography;
const { Step } = Steps;

const { SHOW_PARENT } = TreeSelect;

interface Props {
  group: string;
  users: User[];
  groupName: string;
  isGroupEnabled: boolean;
  Type: string | undefined;
  lastUpdateTimeout: string ;
  selectedTest: Test;
  groupTests: Test[];
  nameModal: boolean;
  isDarkMode: boolean;
  selectedKey: string;
  selectedType: string;
  currentStep: number;
  fetchingUsers: boolean;
  cols: ColumnsType<User>;
  visibleAddBlock: boolean;
  openManageGroup: boolean;
  visibleUserBlock: boolean;
  visibleEditBlock: boolean;
  nameForm: FormInstance<any>;
  testCols: ColumnsType<Test>;
  testForm: FormInstance<any>;
  editForm: FormInstance<any>;
  fetchingGroupTests: boolean;
  testTemplates: TestTemplate;
  newUserForm: FormInstance<any>;
  steps: {
    title: string;
    content: JSX.Element;
  }[];
  isArchived: boolean;
  visibleActionsMenu:boolean;
  showAlertChangeHour:boolean;
  newFinishUpdTmout:string;
  errorTemplatesGroup:string[];
  modalErrorTemplatesGroup:boolean;
  next: () => void;
  prev: () => void;
  onFinishAddUser: (vals: any) => void;
  onFinishEditForm: (vals: any) => void;
  onFinishNameGroup: (newGroupName: string) => void;
  toggleManageDrawer: (toggle: boolean) => void;
  toggleVisibleAddBlock: (toggle: boolean) => void;
  toggleVisibleEditBlock: (visible: boolean) => void;
  toggleVisibleUserBlock: (visible: boolean) => void;
  setNameModal: React.Dispatch<React.SetStateAction<boolean>>;
  searchMachine: string;
  setSearchMachine: (val: string) => void;
  handleChangeEnabled: (val: boolean) => void;
  modalShareGroup: boolean;
  handleModalShareGroup: (val: boolean) => void;
  usersList: userList[];
  stateLista: { value: string[] };
  onChangeShareGoupList: (val: { value: string[] }) => void;
  handleAddEmailToShareGroup: (val: string) => void;
  shareGroup: () => void;
  emailShareGroup: string;
  EMAIL_REGEX: RegExp;
  displayErrorEmail: boolean;
  messageAddUserToGroup: string;
  setSelectedTest: (val: Test) => void;
  handleArchiveGroup: (stats: boolean, val: string) => void;
  handleShowModalDuplicateGroup: () => void;
  showModalDuplicateGroup: boolean;
  newGroupNameDuplicate: string;
  handleNewGroupNameDuplicated: (val: string) => void;
  duplicateNewGroup: () => void;
  onFinishTestForm: (values: any) => void;
  handleEditTestForm: (values: Test) => void;
  isAbleCreateEditNewTest: boolean;
  newTestData: Test;
  setNewTestData: (val: Test) => void;
  createNewTest: () => void;
  verifySyntax: {};
  setVirifySintax: (val: {}) => void;
  uploadEmailsFile: string[];
  setUploadEmailsFile: (val: string[]) => void;
  typeShareEmail: string;
  setTypeShareEmail: (val: string) => void;
  onChangeTypeShareEmail: (val: string) => void;
  uploadedEmailsFile: boolean;
  isCorrectFormatFile: boolean;
  setDataEmails: (val: informationEmails) => void;
  setTitleNameGroup: (val: string) => void;
  handleMenuClick: (val: any) => void;
  handleVisibleChange: (val: boolean) => void;
  setShowAlertChangeHour: (val: boolean) => void;
  onFinishUpdateTimeout: () => void;
  setNewFinishUpTmout: (val:string) => void;
  handleModalErrorTemplatesGroup:(val:boolean)=>void
}

const GroupsView: React.FC<Props> = ({
  cols,
  steps,
  users,
  group,
  testForm,
  editForm,
  testCols,
  nameForm,
  groupName,
  isGroupEnabled,
  Type,
  lastUpdateTimeout,
  nameModal,
  isDarkMode,
  groupTests,
  currentStep,
  selectedKey,
  selectedType,
  newUserForm,
  selectedTest,
  testTemplates,
  fetchingUsers,
  visibleAddBlock,
  openManageGroup,
  visibleEditBlock,
  visibleUserBlock,
  fetchingGroupTests,
  isArchived,
  visibleActionsMenu,
  showAlertChangeHour,
  newFinishUpdTmout,
  errorTemplatesGroup,
  modalErrorTemplatesGroup,
  next,
  prev,
  setNameModal,
  onFinishAddUser,
  onFinishEditForm,
  onFinishNameGroup,
  toggleManageDrawer,
  toggleVisibleAddBlock,
  toggleVisibleUserBlock,
  toggleVisibleEditBlock,
  searchMachine,
  setSearchMachine,
  handleChangeEnabled,
  modalShareGroup,
  handleModalShareGroup,
  usersList,
  stateLista,
  onChangeShareGoupList,
  handleAddEmailToShareGroup,
  shareGroup,
  emailShareGroup,
  EMAIL_REGEX,
  displayErrorEmail,
  messageAddUserToGroup,
  setSelectedTest,
  handleArchiveGroup,
  handleShowModalDuplicateGroup,
  showModalDuplicateGroup,
  newGroupNameDuplicate,
  handleNewGroupNameDuplicated,
  duplicateNewGroup,
  onFinishTestForm,
  handleEditTestForm,
  isAbleCreateEditNewTest,
  newTestData,
  createNewTest,
  setNewTestData,
  verifySyntax,
  setVirifySintax,
  uploadEmailsFile,
  setUploadEmailsFile,
  typeShareEmail,
  setTypeShareEmail,
  onChangeTypeShareEmail,
  uploadedEmailsFile,
  isCorrectFormatFile,
  setDataEmails,
  setTitleNameGroup,
  handleMenuClick,
  handleVisibleChange,
  setShowAlertChangeHour,
  onFinishUpdateTimeout,
  setNewFinishUpTmout,
  handleModalErrorTemplatesGroup,
}) => {
  const [drawerWidth, setDrawerWidth] = useState(0);

  const [isEnableNext, setIsEnableNext] = useState<boolean>(true);

  const { Dragger } = Upload;
  // const [typeShareEmail, setTypeShareEmail] = useState<string>('SingleEmail');
  // const onChangeTypeShareEmail = (e:string) => {
  //   setTypeShareEmail(e);
  // };
  const emails: string[] = [];

  const propsUp = {
    name: "file",
    accept: ".txt",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
  };

  useEffect(() => {
    if (currentStep === 0 && selectedType !== "") {
      setIsEnableNext(false);
    } else if (currentStep === 0 && selectedType === "") {
      setIsEnableNext(true);
    }

    if (currentStep === 1 && selectedKey !== "") {
      setIsEnableNext(false);
    } else if (currentStep === 1 && selectedKey === "") {
      setIsEnableNext(true);
    }
  }, [currentStep, selectedType, selectedKey]);

  const computeDrawerWidth = () => {
    let width = window.innerWidth;
    if (width >= 1700) {
      setDrawerWidth((width / 5) * 3);
    } else if (width >= 1000) {
      setDrawerWidth((width / 4) * 3);
    }
  };

  const openNotificationWithIcon = (
    type: "success" | "error" | "info" | "warning" | "open",
    message: string,
    description: string
  ) => {
    notification[type]({
      message,
      description,
    });
  };

  useEffect(() => {
    computeDrawerWidth();
    window.addEventListener("resize", computeDrawerWidth);
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const ref = useRef(null);

  const [selectedName, setSelectedName] = useState<boolean>(false);
  const handleClickOutside = (event: any) => {
    if (ref.current) {
      setSelectedName(false);
    }
  };
  const handleClickInside = () => setSelectedName(true);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const [nameGroup, setNameGroup] = useState<string>(groupName);

  useEffect(() => {
    if (nameGroup === "" && !selectedName) {
      setNameGroup(groupName);
    } else if (nameGroup !== "") setTitleNameGroup(nameGroup);
  }, [nameGroup, selectedName]);

  // useEffect(() => {
  //   console.log(`uploadEmailsFile:`,uploadEmailsFile);
  // }, [uploadEmailsFile])

  const actionsMenu = (
    <Menu 
      onClick={(e)=>{
        handleMenuClick(e)
      
      }}
      >
      <Menu.Item key={'1'}>
        <CopyToClipboard
          text={group}
          onCopy={() =>
            openNotificationWithIcon(
              "success",
              "GroupID copied",
              ""
            )
          }
        >
            <Button
              type="ghost"
              className="ManageBtn"
              style={{ 
                border:'none',
                borderRadius: "7px", marginRight: 0 }}
              icon={<CopyOutlined />}
            >
              Copy Group ID
            </Button>
        </CopyToClipboard>
      </Menu.Item>
      <Menu.Item key={'2'}>
        
        <Button
          type="ghost"
          className="ManageBtn"
          style={{ border:'none',
          borderRadius: "7px", marginRight: 0 }}
          onClick={handleShowModalDuplicateGroup}
          icon={<SplitCellsOutlined />}
          // icon={<MergeCellsOutlined />}
          // icon={<DeliveredProcedureOutlined />}
          // icon={<DiffOutlined />}
          // icon={<BranchesOutlined />}
        >
          Duplicate Group
        </Button>
      </Menu.Item>
      <Menu.Item key={'3'}>
        <Button
          type="ghost"
          className="ManageBtn"
          style={{ border:'none',borderRadius: "7px", marginRight: 0 }}
          onClick={() => {
            handleModalShareGroup(true);
          }}
          icon={<ShareAltOutlined />}
        >
          Share Group
        </Button>
      </Menu.Item>
      <Menu.Item key={'4'}>
        <Space direction="vertical">
          <div style={{margin:'0px 0px 0px 14px'}}>
            <Tooltip placement="top" title="Time (in hours) after which the Test should be considered invalid, old or expired">
              <FieldTimeOutlined style={{ fontSize: '18px', margin:'0px 5px 0px 0px'}} />
            </Tooltip>
            {/* <InfoCircleOutlined style={{ fontSize: '13px',color: "#2197DA", }} />  */}
            Hours:
            <Input 
              value={newFinishUpdTmout}
              // defaultValue={newFinishUpdTmout}
              onChange={(e)=> {
                if(e.target.value===''||RegExp(/^[0-9]+$/).test(e.target.value))
                  setNewFinishUpTmout(e.target.value)}
              }
              style={{margin:'0px 0px 0px 3px', width:'60px',}}
              onKeyPress={(e)=>{
                if(e.key=='Enter'){onFinishUpdateTimeout()}
              }}
            />
            {showAlertChangeHour&&<Alert style={{marginTop:'5px'}} message="Press Enter to confirm" type="warning" />}
          </div>
          {/* <Tooltip placement="top" title="Actions">
            <Space wrap>
                <Button
                  type="ghost"
                  className="ManageBtn"
                  style={{ borderRadius: "10px", marginRight: 0 }}
                  icon={<SettingOutlined />}
                  // icon={<ToolOutlined />}
                />
            </Space>
          </Tooltip> */}
        </Space>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="mainContainerGroups">
      <Modal
        closable
        footer={null}
        onCancel={() => {
          handleModalErrorTemplatesGroup(false);
        }}
        visible={modalErrorTemplatesGroup}
        title={`Parameter errors at group`}
      >
        <>
          <Row
            justify="start"
            gutter={[8, 8]}
            style={{ margin: "30px 0px 0px 0px" }}
          >
            <Col className="col-start">
              <ul>
              {
                errorTemplatesGroup.map((err)=>(
                  <li>
                    {err}
                  </li>
                ))
              }
                </ul>
            </Col>
          </Row>
          
          <Row justify="end" gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col>
              <Button
                onClick={() => {
                  handleModalErrorTemplatesGroup(false)
                }}
              >
                Ok
              </Button>
            </Col>
            
          </Row>
        </>
      </Modal>
      <Modal
        closable
        footer={null}
        onCancel={() => {
          handleModalShareGroup(false);
        }}
        visible={modalShareGroup}
        title={`Share group to...`}
      >
        <>
          <Row
            justify="start"
            gutter={[8, 8]}
            style={{ margin: "30px 0px 0px 0px" }}
          >
            <Col className="col-start">
              <Title
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#434343",
                  marginBottom: "30px",
                }}
                level={4}
              >
                Email:
              </Title>
            </Col>
          </Row>
          <Row justify="start" gutter={[8, 8]}>
            <Col>
              <Input
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#595959",
                  fontSize: "20px",
                  marginRight: "15px",
                  marginBottom: "10px",
                }}
                ref={ref}
                value={emailShareGroup}
                onChange={(e) => handleAddEmailToShareGroup(e.target.value)}
              />
              {!displayErrorEmail ? (
                <div>
                  <h4 style={{ color: "#E73830" }}>{messageAddUserToGroup}</h4>
                </div>
              ) : (
                <div>
                  <h4 style={{ color: "green" }}>{messageAddUserToGroup}</h4>
                </div>
              )}
            </Col>
          </Row>
          <Row justify="start" gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col>
              <Button
                onClick={() => {
                  handleModalShareGroup(false);
                  handleAddEmailToShareGroup("");
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  shareGroup();
                }}
                disabled={
                  uploadedEmailsFile
                    ? false
                    : displayErrorEmail
                    ? true
                    : EMAIL_REGEX.test(emailShareGroup)
                    ? false
                    : true
                }
              >
                Add
              </Button>
            </Col>
          </Row>
        </>
      </Modal>
      <Modal
        closable
        footer={null}
        onCancel={handleShowModalDuplicateGroup}
        visible={showModalDuplicateGroup}
        title={`Duplicate Group`}
      >
        <>
          <Row justify="start" gutter={[8, 8]}>
            <Col className="col-start">
              <Title
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#434343",
                  marginBottom: "30px",
                }}
                level={4}
              >
                Name:
              </Title>
            </Col>
          </Row>
          <Row justify="start" gutter={[8, 8]}>
            <Col>
              <Input
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#595959",
                  fontSize: "20px",
                  marginRight: "15px",
                  marginBottom: "10px",
                }}
                ref={ref}
                value={newGroupNameDuplicate}
                onChange={(e) => handleNewGroupNameDuplicated(e.target.value)}
              />
              {!displayErrorEmail ? (
                <div>
                  <h4 style={{ color: "#E73830" }}>{messageAddUserToGroup}</h4>
                </div>
              ) : (
                <div>
                  <h4 style={{ color: "green" }}>{messageAddUserToGroup}</h4>
                </div>
              )}
            </Col>
          </Row>
          <Row justify="start" gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col>
              <Button
                onClick={() => {
                  handleShowModalDuplicateGroup();
                  handleNewGroupNameDuplicated("");
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  duplicateNewGroup();
                }}
                disabled={newGroupNameDuplicate !== "" ? false : true}
              >
                Create
              </Button>
            </Col>
          </Row>
        </>
      </Modal>
      <Row 
        // style={{backgroundColor:'red'}}
      >
        <Col 
          // style={{backgroundColor:'pink'}} 
          span={12}
        >
          {/* Accines y input group name */}
          <Row justify="start" gutter={[8, 8]}>
          {/* Botón acciones engrane */}
            <Col className="col-end">
              <Space direction="vertical">
                <Tooltip placement="top" title="Actions">
                  <Space wrap>
                    <Dropdown 
                      overlay={actionsMenu} 
                      placement="bottomCenter"
                      onVisibleChange={handleVisibleChange}
                      visible={visibleActionsMenu}
                    >
                      <Button
                        type="ghost"
                        className="ManageBtn"
                        style={{ borderRadius: "10px", marginRight: 0 }}
                        icon={<SettingOutlined />}
                        // icon={<ToolOutlined />}
                      />
                    </Dropdown>
                  </Space>
                </Tooltip>
              </Space>
            </Col>
            {/* Group name Input */}
            <Col className="col-end">
              <div style={{ marginBottom: "0px", marginTop: "0px" }}>
                  {/* <GroupBreadCrumbs isDarkMode={isDarkMode} groupName={groupName} > */}
                  {/* <Button>
                      A
                    </Button> */}
                  
                  <Input
                    style={{
                      color: isDarkMode ? "#f0f0f0" : "#595959",
                      fontSize: "18px",
                      marginRight: "15px",
                      marginLeft: "-7px",
                    }}
                    ref={ref}
                    value={nameGroup}
                    bordered={selectedName}
                    onChange={(e) => setNameGroup(e.target.value)}
                    onClick={() => {
                      handleClickInside();
                    }}
                    onKeyPress={(e: React.KeyboardEvent) => {
                      if (
                        e.key == "Enter" &&
                        nameGroup !== "" &&
                        nameGroup !== groupName
                      ) {
                        onFinishNameGroup(nameGroup);
                      }
                    }}
                  />
                  
                  {/* {
                        nameGroup !== groupName
                        &&
                        <Row >
                          <Col className="col-end" >
                            <Row justify="start" >
                              <Col >
                                <Alert message="Please Press enter into text input to apply the change in the group name" type="warning" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      } */}
                  {/* </GroupBreadCrumbs> */}
                </div>
            </Col>
            {
              errorTemplatesGroup.length>0&&
              <Col className="col-end">
                <div style={{ marginBottom: "0px", marginTop: "0px" }}>
                  <Tooltip placement="top" title="Params Error">
                    <Button
                      type="ghost"
                      className="ModalErrorParamsBtn"
                      style={{ borderRadius: "0px", marginRight: 0, border:'none' }}
                      icon={<WarningOutlined style={{color:'#EDCB3F', fontSize:'25px'}}/>}
                      onClick={()=>handleModalErrorTemplatesGroup(true)}
                    />
                  </Tooltip>
                </div>
              </Col>
            }
          </Row>
          {/* Alerta de presionar enter para cambiar nombre de grupo */}
          <Col>
            <div
              style={{
                marginTop: "0px",
                marginBottom: "-10px",
                marginLeft: "5px",
              }}
            >
              {nameGroup !== groupName && (
                <Row>
                  <Col className="col-end">
                    <Row justify="start">
                      <Col>
                        <Alert
                          message="Please Press enter into text input to apply the change in the group name"
                          type="warning"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {/* {Type} */}
            </div>
          </Col>
        </Col>
        {/* Botones switch, manage tests, export results */}
        <Col 
          style={{
            // backgroundColor:'brown',
          }} 
          span={12}
        >
          <Row 
            // style={{backgroundColor:'brown'}} 
            justify="end" gutter={[8, 8]}>
            <Col className="col-end">
              <div style={{ margin: "5px 15px 0px 15px" }}>
                <Switch
                  size="small"
                  checked={isGroupEnabled}
                  onChange={() => handleChangeEnabled(!isGroupEnabled)}
                  checkedChildren={"Enabled"}
                  unCheckedChildren={"Disabled"}
                />
              </div>
            </Col>
            <Col className="col-end">
              <Button
                type="primary"
                className="ManageBtn"
                style={{ borderRadius: "7px" }}
                icon={<SettingOutlined />}
                onClick={() => toggleManageDrawer(true)}
              >
                Manage Tests
              </Button>
            </Col>
            <Col>
              <Button
                // type="primary"

                style={{
                  borderRadius: "7px",
                  marginLeft: "15px",
                  // backgroundColor:'#7A7A7A'
                  //7A7A7A
                  //8C8C8C
                }}
                disabled={users.length > 0 ? false : true}
                icon={<DownloadOutlined />}
                onClick={() => createResultCvsFile(group, groupName)}
              >
                Export Results
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      
      {/* archive y search */}
      <Row 
      style={{
        // backgroundColor:'brown',
        marginTop:'30px'
      }} 
        // style={{backgroundColor:'orange'}} 
        gutter={[16, 16]}
      >
        <Col  span={12} >
          <Row justify="start" >
            <Col>
              <Popconfirm
                title={`Are you sure to ${
                  isArchived ? "Unarchive" : "Archive"
                } this group?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() =>
                  handleArchiveGroup(isArchived ? false : true, groupName)
                }
              >
                <Button>{isArchived ? "Unarchive" : "Archive"} group</Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end" >
            <Col xxl={16} xl={16} lg={16} md={20} sm={24} xs={24}>
              <Input.Search
                value={searchMachine}
                onChange={(ev) => setSearchMachine(ev.target.value)}
                placeholder="Search machine"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row justify="end">
        <Col xxl={6} xl={8} lg={15} md={15} sm={24} xs={24}>
          <Input.Search
            value={searchMachine}
            onChange={(ev) => setSearchMachine(ev.target.value)}
            placeholder="Search machine"
          />
        </Col>
      </Row> */}
      <Table
        className="usersTable"
        loading={fetchingUsers}
        columns={cols}
        dataSource={users}
        pagination={
          users.length > 7
            ? {
                showSizeChanger: true,
                size: "small",
                defaultPageSize: 8,
                pageSizeOptions: ["8", "20", "30", "50"],
              }
            : false
        }
        rowKey="ID"
      />
      {Type === "Monitor" && (
        <Button
          type="ghost"
          onClick={() => toggleVisibleUserBlock(true)}
          icon={<PlusCircleOutlined />}
          style={{ marginTop: users.length > 7 ? "0px" : "25px" }}
        >
          Add VM
        </Button>
      )}
      {visibleUserBlock && (
        <Row justify="center">
          <Col xxl={12} xl={12} lg={18} md={18} sm={20} xs={20}>
            <div className="cardBlockAdd">
              <Row justify="space-between">
                <Col span={22} className="colCenter">
                  <Title
                    style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
                    level={4}
                  >
                    Add new user to {group}
                  </Title>
                </Col>
                <Col span={2}>
                  <Button
                    type="text"
                    shape="circle"
                    icon={<CloseOutlined style={{ fontSize: 20 }} />}
                    onClick={() => toggleVisibleUserBlock(false)}
                  />
                </Col>
                <Col span={24}>
                  <Form form={newUserForm} onFinish={onFinishAddUser}>
                    <Form.Item
                      name="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      required={false}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={
                        <Title
                          level={5}
                          style={{
                            color: isDarkMode ? "#f0f0f0" : "#595959",
                            fontSize: "14px",
                          }}
                        >
                          Name
                        </Title>
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="Username"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      required={false}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={
                        <Title
                          level={5}
                          style={{
                            color: isDarkMode ? "#f0f0f0" : "#595959",
                            fontSize: "14px",
                          }}
                        >
                          Username
                        </Title>
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="Enabled"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      required={false}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={
                        <Title
                          level={5}
                          style={{
                            color: isDarkMode ? "#f0f0f0" : "#595959",
                            fontSize: "14px",
                          }}
                        >
                          Enabled
                        </Title>
                      }
                    >
                      <Switch />
                    </Form.Item>
                    <Row justify="center">
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Confirm
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      <Drawer
        placement="right"
        closable={false}
        width={drawerWidth}
        title={
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={3}
          >
            Managing <i>{groupName}</i> group
          </Title>
        }
        onClose={() => toggleManageDrawer(false)}
        visible={openManageGroup}
        key="right"
      >
        {!visibleAddBlock && (
          <Button
            style={{ marginBottom: 20 }}
            type="ghost"
            icon={<PlusCircleOutlined />}
            onClick={() => toggleVisibleAddBlock(true)}
          >
            Add Test
          </Button>
        )}
        {visibleAddBlock && (
          <Row justify="center">
            <Col span={24}>
              <div className="cardBlockAdd">
                <Row justify="space-between">
                  <Col span={22} className="colCenter">
                    <Title
                      style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
                      level={4}
                    >
                      Add new Test to {group}
                    </Title>
                  </Col>
                  <Col span={2}>
                    <Button
                      type="text"
                      shape="circle"
                      icon={<CloseOutlined style={{ fontSize: 20 }} />}
                      onClick={() => toggleVisibleAddBlock(false)}
                    />
                  </Col>
                  <Col span={24}>
                    <Steps current={currentStep}>
                      {steps.map((step, indx) => (
                        <Step title={step.title} key={`${step}-${indx}`} />
                      ))}
                    </Steps>
                  </Col>
                  <Col span={24}>{steps[currentStep].content}</Col>
                  <Col span={24} className="stepsBtns">
                    {currentStep > 0 && (
                      <Button
                        style={{ margin: "0 8px" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                      <Button
                        disabled={isEnableNext}
                        type="primary"
                        onClick={() => next()}
                      >
                        Next
                      </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                      // <Button
                      //   onClick={() => testForm.submit()}

                      //   type="primary"
                      //   >
                      //   Done
                      // </Button>
                      <Button
                        type="primary"
                        // disabled={!isAbleCreateEditNewTest}
                        disabled={isAbleCreateEditNewTest}
                        // disabled={true}
                        style={{ margin: "25px 0px 0px 0px" }}
                        onClick={() => {
                          // handleCreateNewTest(copySelectedTest)
                          createNewTest();
                        }}
                      >
                        Done
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        {visibleEditBlock && (
          <EditTestForm
            visibleEditBlock={visibleEditBlock}
            testForm={editForm}
            isDarkMode={isDarkMode}
            selectedTest={selectedTest}
            testTemplates={testTemplates}
            toggleVisibleEditBlock={toggleVisibleEditBlock}
            handleEditTestForm={handleEditTestForm}
            selectedKey={selectedTest.TestKey}
            selectedType={selectedTest.TestType}
            newTestData={newTestData}
            setNewTestData={setNewTestData}
            verifySyntax={verifySyntax}
            setVirifySintax={setVirifySintax}
            isAbleCreateEditNewTest={isAbleCreateEditNewTest}
          />
        )}
        <>
          <Table
            pagination={false}
            columns={testCols}
            dataSource={groupTests}
            loading={fetchingGroupTests}
          />
        </>
      </Drawer>
    </div>
  );
};

export default GroupsView;
