import React from "react";
import { Typography } from "antd";
import "./notfound.css";

const { Text } = Typography;

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <div className="main-containernf">
      <Text
        strong
        style={{
          fontSize: "6rem",
          display: "flex",
          justifyContent: "center",
          marginTop: "10%",
        }}
      >
        404
      </Text>
      <Text
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        The page you are trying to access doesn't exist
      </Text>
    </div>
  );
};

export default NotFound;
