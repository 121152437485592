import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../firebase/auth";
import { BrowserRouter, Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "../pages/Login";
import Home from "../pages/Home";
import AccountPage from "../pages/Account";
import NotFound from "../pages/NotFound";
import { Loader } from "../components/Loader";
import { useCookies } from "react-cookie";
import { useThemeSwitcher } from "react-css-theme-switcher";
import "./routing.css";

const browserHistory = createBrowserHistory();

interface Props {}

interface CompProps {
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
}

const MainLayout: React.FC<Props> = () => {
  const authObj = useAuth();
  const [cookies, setCookie] = useCookies(["theme"]);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  //eslint-disable-next-line
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked: boolean) => {
    setIsDarkMode(isChecked);
    if (isChecked)
      setCookie("theme", "dark", {
        path: "/",
        sameSite: "lax",
        maxAge: 12 * 30 * 24 * 60 * 60,
      });
    else
      setCookie("theme", "light", {
        path: "/",
        sameSite: "lax",
        maxAge: 12 * 30 * 24 * 60 * 60,
      });
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  const HomeWithProps = useMemo(() => {
    let HomeP = Home;
    HomeP.defaultProps = { isDarkMode, toggleTheme };
    return HomeP;
    //eslint-disable-next-line
  }, [isDarkMode]);

  const LoginWithProps = useMemo(() => {
    let LoginP = Login;
    LoginP.defaultProps = { isDarkMode, toggleTheme };
    return LoginP;
    //eslint-disable-next-line
  }, [isDarkMode]);

  const AccountWithProps = useMemo(() => {
    let AccountP = AccountPage;
    AccountP.defaultProps = { isDarkMode};
    return AccountP;
    //eslint-disable-next-line
  }, [isDarkMode]);

  useEffect(() => {
    if (cookies.theme) {
      setIsDarkMode(cookies.theme === "dark" ? true : false);
    }
  }, [cookies.theme]);

  const isLogged = (component: React.FC<CompProps>) => {
    if (!authObj.loading) {
      if (authObj.user) return component;
      else return Login;
    } else {
      return Loader;
    }
  };

  // Avoid theme change flicker
  if (status === "loading") {
    return null;
  }

  return (
    <BrowserRouter>
      <Router history={browserHistory}>
        <Switch>
          <Route  path="/account" component={isLogged(AccountWithProps)} />
          <Route path="/home" component={isLogged(HomeWithProps)} />
          <Route exact path="/" component={isLogged(LoginWithProps)} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default MainLayout;
