import React, { useEffect, useMemo, useState } from "react";
import {  Groups } from "../../interfaces/Home";
import { createGroup, fetchGroupsFb } from "./service";
import GroupsView from "./view";
import { useAuth } from "../../firebase/auth";
import "./groups.css";
import { Form, notification } from "antd";
import { showGroups } from "./interface";

interface Props {
  isDarkMode: boolean;
}

const GroupsComponent: React.FC<Props> = ({ isDarkMode }) => {
  const { loading, user } = useAuth();
  const [groupForm] = Form.useForm();
  const [groups, setGroups] = useState<Groups>({} as Groups);
  const [modalCreate, setModalCreate] = useState(false);
  const [groupsSearch, setGroupsSearch] = useState<Groups>({} as Groups);
  const [searchGroups, setSearchGroups] = useState("");
  const [fetchingGroups, setFetchingGroups] = useState(false);
  const [cols, setCols] = useState(6);
  const [pagination, setPagination] = useState(10);
  const [viewGroups,setViewGroups]=useState<showGroups>({
    mosaic:true,
    list:false
  })
  const [groupsViewtype,setGroupsViewType]=useState<string>('1')

  const handleGroupsViewType=(type:string)=>{
    setGroupsViewType(type)
  }
  const computePagination = () => {
    const width = window.innerWidth;
    if (width <= 1000 && width > 800) {
      setPagination(16);
      // setCols(6);
      setCols(3);
    }
    if (width <= 800 && width > 600) {
      setPagination(10);
      setCols(2);
    }
    if (width <= 600) {
      setPagination(10);
      setCols(1);
    }
    if (width > 1000) {
      setPagination(18);
      setCols(4);
    }
  };

  useEffect(() => {
    computePagination();
    window.addEventListener("resize", computePagination);
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const fetchGroups = async () => {
    if (user && !loading) {
      setFetchingGroups(true);
      const newGroups = await fetchGroupsFb(user.uid);
      setGroups(newGroups);
      setGroupsSearch(newGroups);
      setFetchingGroups(false);
    }
  };
  const handleSearchGroups = (value: string) => {
    setSearchGroups(value);
    if (value && value !== "") {
      const filtered = Object.keys(groups)
        .filter((key,indx) => Object.values(groups)[indx].Name.toLowerCase().includes(value.toLowerCase())||key.toLowerCase().includes(value.toLowerCase())
        ).reduce((obj: Groups, key: string) => {
          obj[key] = groups[key];
          return obj;
        }, {});
      setGroupsSearch(filtered);
    }
    if (value === "") {
      setGroupsSearch(groups);
    }
  };

  const openNotificationWithIcon = (
    type: "success" | "error" | "info" | "warning" | "open",
    message: string,
    description: string
  ) => {
    notification[type]({
      message,
      description,
    });
  };

  const onFinishCreateForm = (vals: any) => {
    const { Name,Type } = vals;
    
    handleCreateGroup(Name,Type);
  };

  const handleCreateGroup = async (
    Name: string,
    Type: string,
  ) => {
    groupForm.resetFields();
    setModalCreate(false);
    const Enabled=true
    try {
      await createGroup(user?.uid as string, Name, Enabled,Type);
      openNotificationWithIcon("success", `Group ${Name} created`, "");
      fetchGroups();
    } catch (err) {
      openNotificationWithIcon("error", `Something went wrong`, "");
    }
  };

  useEffect(() => {
    fetchGroups();
    //eslint-disable-next-line
  }, []);

  const active:Groups|null=useMemo(() => {
    let vl:{}|null=Object.values(groupsSearch).reduce((acc,el,indx)=>{
      if(!el.Archived)
        acc={...acc,[Object.keys(groupsSearch)[indx]]:el}
      return acc
    },{})
    if(Object.keys(vl).length === 0)
      vl = null
    return vl as Groups|null
  }, [groupsSearch])

  const archived:Groups|null=useMemo(() => {
    let vl:{}|null=Object.values(groupsSearch).reduce((acc,el,indx)=>{
      if(el.Archived)
        acc={...acc,[Object.keys(groupsSearch)[indx]]:el}
      return acc
    },{})
    if(Object.keys(vl).length === 0)
      vl = null
    return vl as Groups|null
  }, [groupsSearch])

  return (
    <GroupsView
      cols={cols}
      groupForm={groupForm}
      groups={groupsSearch}
      isDarkMode={isDarkMode}
      pagination={pagination}
      modalCreate={modalCreate}
      searchGroups={searchGroups}
      fetchingGroups={fetchingGroups}
      setModalCreate={setModalCreate}
      onFinishCreateForm={onFinishCreateForm}
      handleSearchGroups={handleSearchGroups}
      viewGroups={viewGroups}
      setViewGroups={setViewGroups}
      groupsViewtype={groupsViewtype}
      handleGroupsViewType={handleGroupsViewType}
      active={active}
      archived={archived}
    />
  );
};

export default GroupsComponent;
