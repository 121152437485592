import React, { useContext } from "react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { UserOutlined, LockOutlined, WindowsOutlined } from "@ant-design/icons";
import { loginOption } from "../../constants/login";
import { SignInForm, SignStatus } from "../../interfaces/auth";
import "../../pages/Login/login.css";
import { Divider } from 'antd';
import { doSignInWithMicrosoft, useAuth } from "../../firebase/auth";
import { doSignOut } from "../../firebase";

const { Title, Text } = Typography;

interface Props {
  Formlayout: any;
  onFinishSignIn: (values: SignInForm) => void;
  status: SignStatus;
  setLogOption: React.Dispatch<React.SetStateAction<number>>;
}

export const SignInView: React.FC<Props> = ({
  Formlayout,
  status,
  setLogOption,
  onFinishSignIn,
}) => {
  const authObj = useAuth();
  
  return (
    <div className="login-container">
      <Title level={2}>Sign in</Title>
      <Form {...Formlayout} onFinish={onFinishSignIn}>
        <Form.Item
          label="Email"
          name="email"
          required={false}
          rules={[
            { required: true, message: "Please input your email" },
            { type: "email", message: "please enter a valid email" },
          ]}
        >
          <Input
            autoComplete="off"
            allowClear
            prefix={<UserOutlined />}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          validateStatus={status.status}
          help={status.message}
          required={false}
          validateFirst={false}
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password prefix={<LockOutlined />} size="large" />
        </Form.Item>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              width: "50%",
            }}
            shape="round"
            size="large"
            type="primary"
            htmlType="submit"
          >
            Sign in
          </Button>
        </Form.Item>
        <div
          className="to-sign"
          onClick={() => setLogOption(loginOption.signUp)}
        >
          <Text className="sign-up">Sign up</Text>
        </div>
      </Form>
      <Divider style={{color:'white'}}  />
      <div>
        <Button 
          onClick={() => { doSignInWithMicrosoft() }}
          danger style={{backgroundColor:'#D2330F', color:'white',fontSize: "15px",
          width: "50%", padding:'10px 0px 35px 0px'}} shape="round" icon={<WindowsOutlined  style={{ fontSize: '19px', color: '#FFF', marginRight:'5px', }} />} size={'small'}>
          <strong>Login with Microsoft</strong>
        </Button>
      </div>
    </div>
  );
};
