import React from "react";
import HomeView from "./view";
import { useRouteMatch } from "react-router-dom";
import "./home.css";
import { useAuth } from "../../firebase/auth";

interface Props {
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
}

const Home: React.FC<Props> = ({ isDarkMode, toggleTheme }) => {
  const { path } = useRouteMatch();
  const { user } = useAuth();
  return (
    <HomeView path={path} isDarkMode={isDarkMode} toggleTheme={toggleTheme} user={user}/>
  );
};

export default Home;
