/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Menu,
  Typography,
  Switch,
  Space,
  Dropdown,
  Button,
  Badge,
  Input,
  Divider,
} from "antd";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../firebase/auth";
import { doSignOut } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../../../assets/f-letterpurple.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./navbar.css";
import { getAvatarLeters } from "../../../utilities";

const { Text } = Typography;

interface Props {
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
  titleNameGroup: string
  groupType: string
}

export const NavBar: React.FC<Props> = ({ isDarkMode, toggleTheme, titleNameGroup, groupType }) => {
  const history = useHistory();
  const authObj = useAuth();
  const [avCont, setAvCont] = useState("");
  const [selected, setSelected] = useState("");
  const [userName, setUserName] = useState("");

  const url = useLocation();

  useEffect(() => {
    const { pathname } = url;
    if (pathname === "/home") {
      setSelected("1");
    } else if (pathname.includes("option")) {
      setSelected("2");
    } else if (pathname.includes("jsoncreator")) {
      setSelected("3");
    } else if (pathname.includes("wiki")) {
      setSelected("4");
    } else if (pathname.includes("about")) {
      setSelected("5");
    }
  }, [url]);

  let disabledAccountBtn = useMemo(() => {
    const { pathname } = url;
    let res: boolean = false
    if (pathname.includes("home/account")) {
      res = true
    }
    return res
  }, [url]);

  useEffect(() => {
    const { loading, user, userName } = authObj;

    if (!loading) {
      if (user) {
        const mail = user.email;
        // const name = mail?.split("@")[0] as string;
        setUserName(userName);
        try {
          const initials = userName.split(" ");
          const newCont = initials[0][0] + initials[1][0];
          setAvCont(newCont);
        } catch (err) {
          const initials = userName.split(" ");
          const newCont = initials[0][0] + initials[0][1];
          setAvCont(newCont);
        }
        // try {
        //   const initials = userName.split(".");
        //   const newCont = initials[0][0] + initials[1][0];
        //   setAvCont(newCont);
        // } catch (err) {
        //   const initials = name.split(".");
        //   const newCont = initials[0][0] + initials[0][1];
        //   setAvCont(newCont);
        // }
      }
    }
  }, [authObj]);

  const handleSignOut = () => {
    doSignOut();
  };

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <a
          href={`https://firebasestorage.googleapis.com/v0/b/frida-tester.appspot.com/o/FridaTesterMonitor.zip?alt=media`}
          target="_blank"
        >
          <Button
            style={{ borderRadius: "10px", border: "none" }}
            icon={<DownloadOutlined />}
          >
            Frida Tester Monitor
          </Button>
        </a>
      </Menu.Item>
      <Menu.Item key={2}>
        <a
          href={`https://firebasestorage.googleapis.com/v0/b/frida-tester.appspot.com/o/FridaTesterAudit.zip?alt=media`}
          target="_blank"
        >
          <Button
            style={{ borderRadius: "10px", border: "none" }}
            icon={<DownloadOutlined />}
          >
            Frida Tester Audit
          </Button>
        </a>
      </Menu.Item>
      <Menu.Item key={3}>
        <a
          href={
            "https://firebasestorage.googleapis.com/v0/b/frida-tester.appspot.com/o/manual-FridaTester-configuracion.pdf?alt=media"
          }
          target="_blank"
        >
          <Button
            style={{ borderRadius: "10px", border: "none" }}
            icon={<CloudDownloadOutlined />}
          >
            Documentation
          </Button>
        </a>
      </Menu.Item>
      <Menu.Item
        style={{
          // float: "right",
          // marginRight: "50px",
          color: isDarkMode ? "#f0f0f0" : "#434343",
        }}
        key={4}
        // onClick={handleSignOut}
        disabled={disabledAccountBtn}
      >
        {/* <Link to="home/account"> */}
        <Button
          style={{ borderRadius: "10px", border: "none" }}
          icon={<UserOutlined />}
          onClick={() => history.push('/home/account')}
        >
          Account
        </Button>
        {/* </Link> */}
      </Menu.Item>
      {<Divider style={{ marginTop: '5px', marginBottom: '0px' }} />}
      <Menu.Item
        style={{
          // float: "right",
          // marginRight: "50px",
          color: isDarkMode ? "#f0f0f0" : "#434343",
        }}
        key={5}
        onClick={handleSignOut}
      >
        <Button
          style={{ borderRadius: "10px", border: "none" }}
          icon={<LogoutOutlined />}
        >
          Sign out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const ref = useRef(null);
  const [selectedName, setSelectedName] = useState<boolean>(false)
  const handleClickOutside = (event: any) => {
    if (ref.current) {
      setSelectedName(false);
    }
  };
  const handleClickInside = () => setSelectedName(true);

  // <Menu.Item key="dashboard" onClick={() => history.push('/')}>
  //     ΠΙΝΑΚΑΣ
  //   </Menu.Item>
  //   <Menu.Item key="settings" onClick={() => history.push('/settings/accounts')}>
  //     ΡΥΘΜΙΣΕΙΣ
  //   </Menu.Item>

  return (
    <Menu
      mode="horizontal"
      style={{
        color: "#434343",
        lineHeight: "57px",
      }}
      selectedKeys={[selected]}
    >
      {/* Title Frida Tester */}
      <Menu.Item key="1" style={{ marginLeft: "50px" }}>
        {" "}
        <Text
          style={{
            color: isDarkMode ? "#f0f0f0" : "#434343",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          <Link
            to="/home"
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
          >
            <Logo className="fridaLogo" />
            rida Tester
          </Link>
        </Text>
        {/* <Text
          style={{
            color: isDarkMode ? "#f0f0f0" : "#434343",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Groups
        </Text> */}
      </Menu.Item>

      {/* Title: groups text for dashboard or group name  */}
      <>
        {
          url.pathname === "/home" ?
            <Text
              style={{
                color: isDarkMode ? "#ABA8A8" : "#6E6E6E",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Groups
            </Text>
            : disabledAccountBtn ?
              <Text
                style={{
                  color: isDarkMode ? "#ABA8A8" : "#6E6E6E",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Account
              </Text>
              : titleNameGroup !== '' && groupType !== '' &&
              <>
                <Badge
                  size="small"
                  count={groupType}
                  // style={{ backgroundColor: '#52c41a',color:'white' }}
                  style={{
                    backgroundColor: groupType === 'Audit' ? '#4DB51A' : '#1A92B5',
                    color: 'white',
                    marginLeft: '0px',
                    // marginBottom:'25px',
                    marginBottom: '4px',
                    width: groupType === 'Audit' ? '37px' : '47px',
                    fontSize: '9px',
                    marginRight: '12px',
                    borderRadius: '3px'
                  }}
                />
                {/* Input of group name title to change name */}
                {/* <Input
              style={{ color: isDarkMode ? "#f0f0f0" : "#595959", 
                    fontSize: '18px', marginRight: '15px',
                    marginLeft:'-7px',
                    
                }}
              ref={ref}
              value={titleNameGroup}
              bordered={selectedName}
              // onChange={(e) => setNameGroup(e.target.value)}
              onClick={() => {
                handleClickInside()
              }}
              // onKeyPress={(e: React.KeyboardEvent) => {
              //   if (e.key == 'Enter' && nameGroup !== '' && nameGroup !== groupName) {
              //     onFinishNameGroup(nameGroup)
              //   }
              // }}
            /> */}

                <Text
                  style={{
                    color: isDarkMode ? "#ABA8A8" : "#6E6E6E",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {titleNameGroup}
                </Text>

                {/* {groupType} */}

              </>
        }
      </>

      {/* Switch to light or dark mode */}
      <Menu.Item key="3"
        style={{ float: "right", marginRight: "50px" }}
      >
        <Switch
          checkedChildren={<FontAwesomeIcon icon={faMoon} />}
          unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
          onChange={toggleTheme}
          checked={isDarkMode}
        />
      </Menu.Item>
      {/* <Menu.Item
        style={{
          float: "right",
          marginRight: "50px",
          color: isDarkMode ? "#f0f0f0" : "#434343",
        }}
        key="4"
        onClick={handleSignOut}
      >
        Sign out <LogoutOutlined />
      </Menu.Item> */}

      {/* Avatar Menu  */}
      <div
        key="4"
        style={{ float: "right", color: isDarkMode ? "#f0f0f0" : "#434343" }}
      >
        <Space direction="vertical">
          <Space wrap>
            <Dropdown overlay={menu} placement="bottomCenter">
              <Avatar
                size={45}
                style={{
                  color: "white",
                  marginRight: "10px",
                  backgroundColor: "#531dab",
                  textTransform: "uppercase",
                }}
              >
                {avCont}
              </Avatar>
            </Dropdown>
          </Space>
        </Space>
        {/* {userName} */}
      </div>
    </Menu>
  );
};
