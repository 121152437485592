import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { User } from "../../../interfaces/Home";
import "./userbreadcrumbs.css";

interface Props {
  user: User;
  isDarkMode: boolean;
}

export const UserBreadCrumbs: React.FC<Props> = ({ user, isDarkMode }) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/home">
            <div
              className={
                isDarkMode
                  ? "BreadcrumbLinkUserDark breadCrumbFirst"
                  : "BreadcrumbLinkUser breadCrumbFirst"
              }
            >
              <HomeOutlined className="homeIcon" />
              Home
            </div>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/home/group/${user.Group}`}>
            <div
              className={
                isDarkMode
                  ? "BreadcrumbLinkUserDark breadCrumbGroup"
                  : "BreadcrumbLinkUser breadCrumbGroup"
              }
            >
              <TeamOutlined className="homeIcon" />
              {user.Group}
            </div>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <div className={isDarkMode ? "BreadcrumbItemDark" : "BreadcrumbItem"}>
            <UserOutlined className="homeIcon" />
            {user.Name}
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
};
