import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { SignStatus } from "../interfaces/auth";
import { fridaTesterJSON } from "../constants/firebase";

//eslint-disable-next-line
interface AuthContextObject {
  user: firebase.User | null;
  loading: boolean;
}

const fridaTesterFirebase = firebase.initializeApp(fridaTesterJSON);


const doSignOut = () => {
  console.log(`firebase:`, firebase.auth());
  firebase.auth().signOut()
  console.log(`auth:`, firebase.auth());
};

const doSignIn = async (email: string, pwd: string): Promise<SignStatus> => {
  const res = fridaTesterFirebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(async () => {
      const res = await fridaTesterFirebase
        .auth()
        .signInWithEmailAndPassword(email, pwd)
        .then((user) => {
          let status: SignStatus = {} as SignStatus;
          if (user.user?.emailVerified) {
            status = { status: undefined, message: "true" };
          } else {
            status = { status: "error", message: "Please verify your email" };
            fridaTesterFirebase.auth().signOut();
          }
          return status;
        })
        .catch((error) => {
          const status: SignStatus = {
            status: "error",
            message: error.message,
          };
          return status;
        });
      return res;
    })
    .catch((error) => {
      const status: SignStatus = {
        status: "error",
        message: error.message,
      };
      return status;
    });
  return res;
};

const doSignUp = async (
  email: string,
  password: string,
  name?:string
): Promise<SignStatus> => {
  const res = await fridaTesterFirebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      // fridaTesterFirebase.database().ref().child(`AccessControl/${res.user?.uid}/Name`).set(name);
      fridaTesterFirebase.auth().signOut();
      res.user?.sendEmailVerification();
      const status: SignStatus = {
        status: undefined,
        message: "A verification email has been sent, please check your email",
      }; 
      return status;
    })
    .catch((error) => {
      const status: SignStatus = {
        status: "error",
        message: error.message,
      };
      return status;
    });
  return res;
};

const fetchValidDomains = async (): Promise<string[]> => {
  let ref = fridaTesterFirebase.database().ref();
  const domains: string[] = (await ref.child("domains").once("value")).val();
  return domains;
};



export {
  firebase,
  fridaTesterFirebase,
  doSignIn,
  doSignOut,
  doSignUp,
  fetchValidDomains,
};
