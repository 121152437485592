import React from "react";
import vsDark from "prism-react-renderer/themes/vsDark";
import Highlight, { defaultProps } from "prism-react-renderer";

interface Props {
  code: string;
  language: any;
  height?:string
}

export const CodeSnippet: React.FC<Props> = ({ code, language,height }) => {
  return (
    <Highlight {...defaultProps} theme={vsDark} code={code} language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{ ...style, borderRadius: 8, padding: 15,height:height?height:'auto' }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
