import React, { useEffect, useMemo, useState } from "react";
import UserView from "./view";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchUserInfo,
  checkAccess,
  fetchGroupTests,
  fetchTestTemplates,
  addUserTest,
  updateUserTest,
  deleteUserTestFb,
  updateNotifyFailure,
  updateResultStatus,
} from "./service";
import { GroupParams } from "../../interfaces/Group";
import { ColumnsType } from "antd/es/table";
import { Button, Form, message, Popconfirm, Tag,Input,Modal, Checkbox,Typography } from "antd";
import { Log, Result, Test, TestTemplate, User } from "../../interfaces/Home";
import { fridaTesterFirebase } from "../../firebase";
import { CodeSnippet } from "../../components/User/Snippet";
import { useAuth } from "../../firebase/auth";
import "./user.css";
import { TestForm, TestKey, TestType } from "../Group/TestBlock";
import { DeleteOutlined } from "@ant-design/icons";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import {  a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
interface Props {
  isDarkMode: boolean;
}

const UserPage: React.FC<Props> = ({ isDarkMode }) => {
  const history = useHistory();
  let { id } = useParams() as GroupParams;
  const { loading, user } = useAuth();
  const [notifyForm] = Form.useForm();
  const [testForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTest, setSelectedTest] = useState<Test>({} as Test);
  const [selectedTestIdx, setSelectedTestIdx] = useState(0);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [visibleAddBlock, setVisibleAddBlock] = useState(false);
  const [visibleEditBlock, setVisibleEditBlock] = useState(false);
  const [userTester, setUser] = useState<User>({} as User);
  const [logs, setLogs] = useState<Log[]>([]);
  const [openManageDrawer, setOpenManageDrawer] = useState(false);
  const [groupTests, setGroupTests] = useState<Test[]>([]);
  //eslint-disable-next-line
  const [testsId, setTestsId] = useState<string[]>([]);
  const [testTemplates, setTestTemplates] = useState<TestTemplate>({});
  const [selectedResults, setSelectedResults] = useState<Result[]>([]);
  //eslint-disable-next-line
  const [personalResults, setPersonalResults] = useState<Result[]>([]);
  //eslint-disable-next-line
  const [groupResults, setGroupResults] = useState<Result[]>([]);
  const [openDrawerResults, setOpenDrawerResults] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(false);
  const [managingAccess, setManagingAccess] = useState(true);
  //Modal Output information
  const [selectedResult,setSelectedResult]=useState<Result|null>(null)
  const [showModalOutput, setShowModalOutput] = useState(false);
  const { TextArea } = Input;
  // const testObj=JSON.parse(`{"carro":{"color": "azul"},"a":{"color": "azul"},"b":{"color": "azul"},"v":{"color": "azul"}}`)
  // const parsed=JSON.stringify(testObj,null,5)
  const HEIGHT_MODAL='400px'
  const { Text} = Typography;

  const [showSyntaxRegexError,setShowSyntaxRegexError]=useState<boolean>(false)
  const [verifySyntax,setVirifySintax]=useState<{}>({})

  const output=useMemo(() => {
    if(selectedResult)
   {
       if(selectedResult.Output.substr(0,1)==='[')
       {
         return selectedResult.Output.replaceAll('["','').replaceAll('","','\n\n').replaceAll('"]','')
       }
       if(selectedResult.Output.substr(0,2)==='"{')
       {
         let res=selectedResult.Output.replaceAll('"{','{').replaceAll('}"','}')
         const testObj=JSON.parse(res)
         const parsed=JSON.stringify(testObj,null,5)
         return parsed
       }   
       else
       {
        return selectedResult.Output
       }
   }
   else
   {
     return ''
   }
  }, [selectedResult])

  const checkHasAccess = async () => {
    if (!loading && user && userTester.Group) {
      const hasAccess = await checkAccess(user.uid, userTester.Group);
      if (!hasAccess) {
        history.replace("/home");
      }
      setManagingAccess(false);
    }
  };

  useEffect(() => {
    checkHasAccess();
    //eslint-disable-next-line
  }, [user, loading, userTester, id]);

  const [selectedIndexOpenResult,setSelectedIndexOpenResult]=useState<number>(0)

  const getColorErrorConfig = (val: boolean) => {
    if (!val) {
      return "green";
    } else {
      return "error";
    }
  };

  const cols: ColumnsType<Log> = [
    {
      key: "date",
      title: "Executed on",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (value, row, index) => {
        const date = new Date(value);
        const hrs = date.getHours();
        const mins = date.getMinutes();
        const seconds = date.getSeconds();
        let hrSt = `${hrs}`;
        let minSt = `${mins}`;
        let secSt = `${seconds}`;
        if (hrs < 10) {
          hrSt = `0${hrs}`;
        }
        if (mins < 10) {
          minSt = `0${mins}`;
        }
        if (seconds < 10) {
          secSt = `0${seconds}`;
        }
        return (
          <div>
            {date.toDateString()} {hrSt}:{minSt}:{secSt}
          </div>
        );
      },
    },
    {
      key: "elapsed_seconds",
      title: "Elapsed Seconds",
      dataIndex: "elapsed_seconds",
      align: "center",
    },
    {
      key: "ConfigError",
      title: "Config Error",
      dataIndex: "ConfigError",
      align: "center",
      render: (value, row, index) => (
        <Tag color={getColorErrorConfig(value)}>{value?'true':'false'}</Tag>
      ),
    },
    {
      key: "passed",
      title: "Passed",
      dataIndex: "passed",
      align: "center",
      render: (value, row, index) => (
        <Tag color={getPassedColor(row)}>{value}</Tag>
      ),
    },
    {
      key: "total",
      title: "Total",
      dataIndex: "total",
      align: "center",
    },
    {
      key: "results",
      title: "Results",
      dataIndex: "results",
      align: "center",
      render: (value, row, index) => (
        <Button
          // type="primary"
          // shape="round"
          onClick={() => {
            handleSelectResults(row.results)
            setSelectedIndexOpenResult(index)
            
          }}
        >
          open results
        </Button>
      ),
    },
  ];

  const testCols: ColumnsType<Test> = [
    {
      key: "TestType",
      dataIndex: "TestType",
      title: "TestType",
    },
    {
      key: "TestKey",
      dataIndex: "TestKey",
      title: "Test Key",
    },
    {
      key: "TestNickName",
      dataIndex: "TestNickName",
      title: "Test Nickname",
    },
    {
      key: "TestParams",
      title: "Params",
      dataIndex: "TestParams",
      render: (value, row, index) =>
        value && (
          <div className="paramsContainer">
            <CodeSnippet
              language="json"
              code={JSON.stringify(value, null, 2)}
            />
          </div>
        ),
    },
  ];
  const testUserCols: ColumnsType<Test> = [
    {
      key: "TestType",
      dataIndex: "TestType",
      title: "TestType",
    },
    {
      key: "TestKey",
      dataIndex: "TestKey",
      title: "Test Key",
    },
    {
      key: "TestNickName",
      dataIndex: "TestNickName",
      title: "Test Nickname",
    },
    {
      key: "TestParams",
      title: "Params",
      dataIndex: "TestParams",
      render: (value, row, index) =>
        value && (
          <div className="paramsContainer">
            <CodeSnippet
              language="json"
              code={JSON.stringify(value, null, 2)}
            />
          </div>
        ),
    },
    {
      key: "",
      title: "Edit",
      dataIndex: "",
      render: (value, row, index) => (
        <Button type="ghost" onClick={() => handleSelectEditTest(row, index)}>
          Edit
        </Button>
      ),
    },
    {
      key: "",
      title: "Delete",
      dataIndex: "",
      render: (value, row, index) => (
        <Popconfirm
          placement="topLeft"
          title={`Are you sure to delete this test?`}
          onConfirm={() => deleteUserTest(index)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="ghost" shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const [checkUpdateResult,setCheckUpdateResult]=useState<boolean>(false)
  const [indexSelect,setIndexSelect]=useState<number>(0)
  // const [displayCheckUpdateStatus,setDisplayCheckUpdateStatus]=useState<boolean>(false)

  // const displayCheck= useMemo (() => {
  //   let res:boolean=false
  //   const previouslyUpdated 
  //   return res
  // }, [showModalOutput])

  // useEffect(() => {
  //   setDisplayCheckUpdateStatus(displayCheck)
  // }, [displayCheck])
  // console.log(`selectedResult:`,selectedResult);
  
  const resultCols: ColumnsType<Result> = [
    {
      key: "Result",
      dataIndex: "Result",
      title: "Result",
      sorter: (a, b) => (a.Result === b.Result ? 0 : a.Result ? -1 : 1),
      render: (value, row, index) => (
        <Tag color={value ? "green" : "error"}>
          {value ? "passed" : "failed"}
        </Tag>
      ),
    },
    {
      key: "TestKey",
      dataIndex: "TestKey",
      title: "Test Key",
    },
    {
      key: "TestNickName",
      dataIndex: "TestNickName",
      title: "Test Nickname",
    },
    {
      key: "TestType",
      dataIndex: "TestType",
      title: "TestType",
    },
    {
      key: "TestParams",
      title: "Params",
      dataIndex: "TestParams",
      render: (value, row, index) =>
        value && (
          <div className="paramsContainer">
            <CodeSnippet
              language="json"
              code={JSON.stringify(value, null, 2)}
            />
          </div>
        ),
    },
    {
      key: "Actions",
      title: "Actions",
      dataIndex: "Actions",
      render: (value, row, index) =>
      row.Output!==''&&(

        <>
          <Button 
            // type="primary"
            // danger 
            // shape="round"
            disabled={selectedResults[index].Output?false:true}
            onClick={() => {
              getOutput(index);
              setSelectedResult(selectedResults[index]) 
              setIndexSelect(index)
            }}>
            Show Output
          </Button>
          <Modal
            title="Output"
            centered
            visible={showModalOutput}
            onCancel={() => setShowModalOutput(!showModalOutput)}
            onOk={() => setShowModalOutput(!showModalOutput)}
            footer={[]} 
          >
            <>
              {
                selectedResult&&!selectedResult.Result&&
                <Checkbox checked={checkUpdateResult} 
                  onChange={()=>setCheckUpdateResult(!checkUpdateResult)}
                  style={{marginBottom:'30px',marginRight:'10px'}}
                >
                  Update status to Passed
                </Checkbox>
              }
              {
                selectedResult?.authorized&&
                <div style={{marginBottom:'10px',marginTop:'-10px'}}>
                  <Text type="success" style={{fontSize:'11px'}}>     Authorized by: <Text strong type="success">{selectedResult.authorized.email}</Text></Text><div></div>
                  <Text type="success" style={{fontSize:'11px'}}>date: <Text strong type="success">{selectedResult.authorized.date}</Text></Text>
                </div>
              }
              {
                checkUpdateResult&&
                  <Button 
                    // type="primary"
                    // danger 
                    // shape="round"
                    // disabled={selectedResults[index].Output?false:true}
                    onClick={() => {
                      
                      setCheckUpdateResult(false)
                      selectedResults[indexSelect].Result=true
                      setSelectedResults(selectedResults)
                      updateResultStatus(selectedResults,indexSelect,selectedIndexOpenResult,userTester.Group,userTester.ID,user?.uid,user?.email)
                      // updateResultStatus(selectedResults,indexSelect,'AuditIsida','-MgLuPC5W-aU0VxIuqnf')
                      // setSelectedResults([...selectedResults,{selectedResults[indexSelect].Result:false}])
                    }}
                    style={{marginBottom:'10px'}}
                  >
                    Confirm
                  </Button>

              }
              {
                output.substr(0,1)!=='{'
                ?
                  <div
                    style={{height:HEIGHT_MODAL}}
                  >
                    <TextArea
                      style={{height:HEIGHT_MODAL,resize:'none'}}
                      bordered={false}
                      disabled={false}
                      value={output}
                      />
                  </div>
                :
                  <div
                    style={{height:'auto'}}
                  >
                    <CodeSnippet
                        language="json"
                        code={output}
                        height={HEIGHT_MODAL}
                      />
                  </div>
              }
            </>
         </Modal>
        </>
        ),
    },
  ];

  const getOutput=(index:number)=>{
    setSelectedResult(selectedResults[index])
    setShowModalOutput(!showModalOutput)
  }

  const handleSelectResults = (results: Result[]) => {
    setSelectedResults(results);
    setOpenDrawerResults(true);
  };

  const handleCloseSelectResult = () => {
    setOpenDrawerResults(false);
  };

  const getPassedColor = (log: Log) => {
    if (log.passed !== log.total) {
      return "error";
    } else {
      return "green";
    }
  };

  const fetchUser = async () => {
    setFetchingUser(true);
    const newUser = await fetchUserInfo(id);
    setUser(newUser);
  };

  const fetchTemplates = async () => {
    const newTemplates = await fetchTestTemplates();
    setTestTemplates(newTemplates);
  };

  const fetchGroupTest = async () => {
    if (userTester.Group) {
      const newTests = await fetchGroupTests(userTester.Group);
      // let newGroupRes: Result[] = [];
      // let newSelRes = [...selectedResults];
      setGroupTests(newTests.tests);
      setTestsId(newTests.testsId);
      // newTests.forEach((test, indx) => {
      //   const newRes = selectedResults.findIndex((res) => {
      //     return res.TestKey === test.TestKey && res.TestType === test.TestType;
      //   });
      //   if (newRes !== -1) {
      //     newGroupRes.push(selectedResults[newRes]);
      //     newSelRes.splice(newRes, 1);
      //   }
      // });
      // setGroupResults(newGroupRes);
      // setPersonalResults(newSelRes);
    }
  };

  useEffect(() => {
    fetchGroupTest();
    fetchTemplates();
    //eslint-disable-next-line
  }, [userTester]);

  useEffect(() => {
    if (userTester.Username || userTester.Name) {
      setFetchingUser(true);
      let ref = fridaTesterFirebase.database().ref();
      const listener = ref
        .child(`Logs/${userTester.Group}/${userTester.ID}`).orderByChild('date')
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const logsArray: Log[] = Object.values(snapshot.val());
            logsArray.sort(
              (log1, log2) =>
                new Date(log1.date).getTime() - new Date(log2.date).getTime()
            );
            logsArray.reverse()
            setLogs(logsArray);
          }
        });
      setFetchingUser(false);
      return () => ref.off("value", listener);
    }
  }, [userTester]);

  useEffect(() => {
    fetchUser();
    //eslint-disable-next-line
  }, []);

  const updateTest = async (newTests: Test[]) => {
    await addUserTest(userTester.ID, newTests);
    message.success({ content: "Test added" });
    testForm.resetFields();
    fetchUser();
    setVisibleAddBlock(false);
    setSelectedKey("");
    setSelectedType("");
    setCurrentStep(0);
    fetchUser();
  };

  const handleAddTest = (values: any) => {
    const { TestNickName, ...params } = values;

    const newTest: Test = {
      TestKey: selectedType,
      TestNickName,
      TestParams: params,
      TestType: selectedKey,
    };
    let userTests: Test[] = [];
    if (userTester.Tests && userTester.Tests.length > 0) {
      userTests = [...userTester.Tests];
    }
    userTests.push(newTest);
    updateTest(userTests);
  };

  const steps = [
    {
      title: "TestType",
      content: (
        <TestType
          isDarkMode={isDarkMode}
          selectedType={selectedType}
          testTemplates={testTemplates}
          setSelectedType={setSelectedType}
        />
      ),
    },
    {
      title: "TestKey",
      content: (
        <TestKey
          isDarkMode={isDarkMode}
          selectedKey={selectedKey}
          selectedType={selectedType}
          testTemplates={testTemplates}
          setSelectedKey={setSelectedKey}
        />
      ),
    },
    {
      title: "Test Form",
      content: (
        <TestForm
          testForm={testForm}
          isDarkMode={isDarkMode}
          selectedKey={selectedKey}
          selectedType={selectedType}
          testTemplates={testTemplates}
          onFinishTestForm={handleAddTest}
          verifySyntax={verifySyntax}
          setVirifySintax={setVirifySintax}
        />
      ),
    },
  ];

  const toggleOpenManageDrawer = (drawer: boolean) => {
    setOpenManageDrawer(drawer);
  };

  const toggleVisibleAddBlock = (visible: boolean) => {
    setVisibleAddBlock(visible);
  };

  const toggleVisibleEditBlock = (visible: boolean) => {
    setVisibleEditBlock(visible);
  };

  const handleSelectEditTest = (test: Test, index: number) => {
    toggleVisibleEditBlock(true);
    setSelectedTest(test);
    setSelectedTestIdx(index);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishEditForm = async (vals: any) => {
    const { TestNickName, ...params } = vals;
    let newTest = selectedTest;

    newTest.TestNickName = TestNickName;
    newTest.TestParams = params;
    await updateUserTest(userTester.ID, newTest, selectedTestIdx);
    message.success({ content: "Test changed" });
    setVisibleEditBlock(false);
    editForm.resetFields();
    setSelectedTest({} as Test);
    fetchUser();
  };

  const deleteUserTest = async (testIdx: number) => {
    let newTests = [...userTester.Tests];
    newTests.splice(testIdx, 1);
    await deleteUserTestFb(userTester.ID, newTests);
    message.success({ content: "Test deleted" });
    fetchUser();
  };

  const handleEditNotifyValue = (vals: any) => {
    const { NotifyFailure } = vals;
    handleUpdateNotifyFb(NotifyFailure);
  };

  const handleUpdateNotifyFb = async (NotifyFailure: string) => {
    await updateNotifyFailure(userTester.ID, NotifyFailure);
    message.success({ content: "NotifyFailure Changed" });
    await fetchUser();
  };

  return !managingAccess ? (
    <UserView
      cols={cols}
      logs={logs}
      steps={steps}
      user={userTester}
      editForm={editForm}
      testCols={testCols}
      testForm={testForm}
      notifyForm={notifyForm}
      isDarkMode={isDarkMode}
      groupTests={groupTests}
      resultCols={resultCols}
      currentStep={currentStep}
      selectedKey={selectedKey}
      selectedTest={selectedTest}
      groupResults={groupResults}
      testUserCols={testUserCols}
      fetchingUser={fetchingUser}
      testTemplates={testTemplates}
      visibleAddBlock={visibleAddBlock}
      personalResults={personalResults}
      selectedResults={selectedResults}
      visibleEditBlock={visibleEditBlock}
      openManageDrawer={openManageDrawer}
      openDrawerResults={openDrawerResults}
      next={next}
      prev={prev}
      onFinishEditForm={onFinishEditForm}
      handleEditNotifyValue={handleEditNotifyValue}
      toggleVisibleAddBlock={toggleVisibleAddBlock}
      toggleOpenManageDrawer={toggleOpenManageDrawer}
      toggleVisibleEditBlock={toggleVisibleEditBlock}
      handleCloseSelectResults={handleCloseSelectResult}
    />
  ) : (
    <div></div>
  );
};

export default UserPage;
