import { Modal } from 'antd'
import React from 'react'

interface Props{
   title:string;
   visible:boolean;
   setShowModalOutput:(val:boolean)=>void
   
}
 const ModalView:React.FC<Props> = ({
   children,
   title,
   visible,
   setShowModalOutput,
 }) => {

   return (
      <Modal
            title={title}
            centered
            visible={visible}
            onCancel={() => setShowModalOutput(!visible)}
            onOk={() => setShowModalOutput(!visible)}
            footer={[]} 
          >
            {children}
         </Modal>
   )
}
export default ModalView