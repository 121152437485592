import ExcelJS from 'exceljs'
// import DataGrid, {
//    Column,
//    Export,
//    GroupPanel
//  } from "devextreme-react/data-grid";
 import fileSaver  from "file-saver";
import { Log, Machine, Result } from '../../interfaces/Home';
//  import {saveAs} from "@types/file-saver";

const createExcel=(logs:Log[],machineID:string,machine:Machine[],totalTest:number,groupID:string,arrayResults:Result[],groupName:string,totalTestsAllMachines:number)=>{
  const workbook = new ExcelJS.Workbook();
  const date= new Date();
  // const date= new Date();
  // const day=date.getDate()
  // const month=date.getMonth()+1
  // const monthsArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const monthMMM=monthsArray[month-1]
  // const year=date.getFullYear()
  // const hr= date.getHours()
  // const min=date.getMinutes()
  // const seg=date.getSeconds()

  workbook.creator = 'Frida Tester';
   workbook.lastModifiedBy = 'Frida Tester';
   workbook.created = date;
   workbook.modified = date;
   workbook.lastPrinted = date;

   workbook.views = [
      {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 0, visibility: 'visible'
      }
    ]

   //  const sheet = workbook.addWorksheet('My Sheet');
    // create a sheet with red tab colour
      // const sheet = workbook.addWorksheet('My Sheet', {properties:{tabColor:{argb:'FFC0000'}}});

      // // create a sheet where the grid lines are hidden
      // const sheet = workbook.addWorksheet('My Sheet', {views: [{showGridLines: false}]});

      // // create a sheet with the first row and column frozen
      // const sheet = workbook.addWorksheet('My Sheet', {views:[{state: 'frozen', xSplit: 1, ySplit:1}]});

      // // Create worksheets with headers and footers
      // const sheet = workbook.addWorksheet('My Sheet', {
      // headerFooter:{firstHeader: "Hello Exceljs", firstFooter: "Hello World"}
      // });

      // sheet.pageSetup.margins = {
      //   left: 0.7, right: 0.7,
      //   top: 0.75, bottom: 0.75,
      //   header: 0.3, footer: 0.3
      // };

      // sheet.pageSetup.printArea = 'A1:G20';

      
      

//       machine
// totalTest

      //Tabla con detalles generales  del grupo (parte superior)
        const worksheetGeneral = workbook.addWorksheet("General");
       worksheetGeneral.columns = [
          // {header: 'Id', key: 'id', width: 10},
          {header: '', key: 'name', width: 32}, 
          {header: '', key: 'valor', width: 15,}
        ];
        //agregando filas
        worksheetGeneral.addRow({name: 'Grupo', valor: groupID});
        worksheetGeneral.addRow({name: 'Nombre', valor: groupName});
        worksheetGeneral.addRow({name: 'Fecha de Reporte', valor: `${date.toISOString()}`});
        // worksheetGeneral.addRow({name: 'Fecha de Reporte', valor: `${day}/${monthMMM}/${year}`});
        worksheetGeneral.addRow({name: 'Total Tests', valor: totalTest});
        worksheetGeneral.addRow({});
        //agregando estilo a la celdas
        // worksheetGeneral.getCell('A2').fill={
        //   type: 'pattern',
        //   pattern:'solid',
        //   fgColor:{argb:'A0E39E'}
        // };
        // worksheetGeneral.getCell('A3').fill={
        //   type: 'pattern',
        //   pattern:'solid',
        //   fgColor:{argb:'A0E39E'}
        // };
        // worksheetGeneral.getCell('A4').fill={
        //   type: 'pattern',
        //   pattern:'solid',
        //   fgColor:{argb:'A0E39E'}
        // };
        // worksheetGeneral.getCell('A5').fill={
        //   type: 'pattern',
        //   pattern:'solid',
        //   fgColor:{argb:'A0E39E'}
        // };

      //Tabla con detalles de las maquinas del grupo (parte inferior)
        //salto de fila
        worksheetGeneral.addRow({});
        worksheetGeneral.addRow(['ID Maquina','HostName','Passed','Total','Compliant','Audited User']) 
        worksheetGeneral.autoFilter = 'A8:F8';
        Object.values(machine).forEach((mach)=>{
          worksheetGeneral.addRow([mach.ID,mach.Name,mach.passed,mach.total,mach.passed<mach.total?'NO':'YES',mach.Username.split('\\')[1]]);
        })

        //Tab Detalle
      const worksheetDetalle = workbook.addWorksheet("Detalle");
      // worksheetDetalle.autoFilter = 'A1:F1';
      worksheetDetalle.columns=[
        {header: 'ID Maquina', key: 'idMaquina', width: 32},
        {header: 'Fecha', key: 'dateString', width: 32},
        {header: 'Tipo de prueba', key: 'TestType', width: 32},
        {header: 'Clave de prueba', key: 'TestKey', width: 32},
        {header: 'Sobre nombre de prueba', key: 'TestNickName', width: 32},
        {header: 'Resultado', key: 'Result', width: 32},
        {header: 'Salida', key: 'Output', width: 32},
        {header: 'Fecha de autorización Manual', key: 'DateAuth', width: 32},
        {header: 'Usuario que Autorizo', key: 'UserAuth', width: 32},
      ]

      // Object.values(machine).forEach((mach)=>{
        Object.values(logs).forEach((log,indx)=>{
          Object.values(log).forEach((l:Log)=>{
            if(l.results.length>0)
            {
              l.results.forEach((result)=>{
                let output=result.Output!==''?result.Output:'NA'
                worksheetDetalle.addRow({
                  idMaquina:Object.values(machine)[indx].Name,
                  dateString:l.dateString, 
                  TestType:result.TestType, 
                  TestKey:result.TestKey, 
                  TestNickName:result.TestNickName, 
                  Result:result.Result, 
                  Output:output,
                  DateAuth:result.authorized ?result.authorized?.date :'',
                  UserAuth:result.authorized?result.authorized?.email:''
                });
              })
            }
            else{
              worksheetDetalle.addRow({idMaquina:machineID,dateString:l.dateString, TestType:'NA', TestKey:'NA', TestNickName:'NA', Result:'NA', Output:'NA'});
            }
          })
        })
      // })

      //Tabla con Results 
      const worksheetTests = workbook.addWorksheet("Test Config");
      

      worksheetTests.columns=[
        {header: 'Test type', key: 'TestType', width: 32},
        {header: 'Test Key', key: 'TestKey', width: 32},
        {header: 'Test NickName', key: 'TestNickName', width: 32},
        {header: 'Params', key: 'Params', width: 32},
      ]

      console.log(`verificando si hay valores en params:`,arrayResults);
      
      Object.values(arrayResults).forEach((arrR,indx)=>{
        let params=''
        if(Object(arrayResults[indx]).hasOwnProperty('TestParams')&&Object.keys(arrR.TestParams).length>0)
        {
          params=''
          Object.keys(arrR.TestParams).forEach((val,indx)=>{
            params+=`${val}: ${Object.values(arrR.TestParams).toString()}  `})
        }
        else{
          params='NA'
        }
        // console.log(`params:`,params);
        
        
        // worksheetTestsDesc.addRow({Description:'La información mostrada son los resultados de las pruebas que contiene el grupo actualmente'});
        
        worksheetTests.addRow({TestType:arrR.TestType, TestKey:arrR.TestKey, TestNickName:arrR.TestNickName, Params:params});
      })
      // worksheetTests.autoFilter = 'A1:D1';
        
      workbook.xlsx.writeBuffer().then(function(buffer) {

        // var link = document.createElement("a");
        // link.setAttribute("href", encoding + buffer);
        // link.setAttribute("download", `PruebaExcel.xls`);
        // link.click();
        
        fileSaver.saveAs(
           new Blob([buffer], { type: "application/octet-stream" }),
           `${groupID} Results - ${date.toISOString()}.xlsx`
          //  `${groupID} Results - ${month}/${day}/${year} - ${hr}:${min}:${seg}.xlsx`
         );
       });


}

export {createExcel}