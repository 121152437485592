import React from "react";
import { Typography, Select, Row, Col } from "antd";
import { TestTemplate } from "../../../interfaces/Home";
import "./blockStyles.css";

const { Title } = Typography;
const { Option } = Select;

interface Props {
  selectedType: string;
  isDarkMode: boolean;
  testTemplates: TestTemplate;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
}

export const TestType: React.FC<Props> = ({
  selectedType,
  isDarkMode,
  testTemplates,
  setSelectedType,
}) => {
  return (
    <>
      <div className="justifyCenter">
        <Row justify="center">
          <Col xs={24}>
            <Title
              style={{ color: isDarkMode ? "#f0f0f0" : "#434343", textAlign:'center' }}
              level={4}
              >
              Select a Test Type
            </Title>
          </Col>
          <Col xs={24}>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={selectedType}
              onChange={(ev) => setSelectedType(ev as string)}
              filterOption={(input, option) =>
                option!==undefined&&option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              >
              {Object.keys(testTemplates).map((testType, indx) => (
                <Option value={testType} key={`${testType}-${indx}`}>
                  {testType}
                </Option>
              ))}
            </Select>
          </Col>
          {/* <Col xs={48}>
            
          </Col> */}
        </Row>
      </div>
      {
        selectedType!==''
        &&Object.keys(testTemplates[selectedType]).find((val)=>val==='Description')&&
            <div className="justifyCenter" style={{marginTop:'0px'}}><strong>Description: </strong>{Object.values(testTemplates[selectedType])[Object.keys(testTemplates[selectedType]).findIndex((val)=>val==='Description')]}</div>
      }
    </>
  );
};
