const getAvatarLeters=(val:string)=>{
   let resAvatar=''
   const mail = val;
   const name = mail?.split("@")[0] as string;
   resAvatar=name;
   try {
     const initials = name.split(".");
     const newCont = initials[0][0] + initials[1][0];
     resAvatar=newCont;
   } catch (err) {
     const initials = name.split(".");
     const newCont = initials[0][0] + initials[0][1];
     resAvatar=newCont;
   }
   return resAvatar
 }

const getEmailName=(val:string):string=>{
   let resAvatar=''
   const mail = val;
   const name = mail?.split("@")[0] as string;
   resAvatar=name;
   const initials = name.split(".");
   for (var i = 0; i < initials.length; i++) {
      initials[i] = initials[i].charAt(0).toUpperCase() + initials[i].slice(1);
  }
   return initials.join().replaceAll(',',' ')
 }

 const generarLetra=()=>{
	var letras = ["a","b","c","d","e","f","0","1","2","3","4","5","6","7","8","9"];
	var numero = Number((Math.random()*15).toFixed(0));
	return letras[numero];
}

const colorHEX=():string=>{
	var coolor = "";
	for(var i=0;i<6;i++){
		coolor = coolor + generarLetra() ;
	}
	return "#" + coolor;
}

 export {
   getAvatarLeters,
   getEmailName,
   colorHEX,
 }