import { fridaTesterFirebase } from "../../firebase";
import { AccessControl, Log, LogArray, Machine, Result, Test, TestTemplate, User } from "../../interfaces/Home";

const fetchUserInfo = async (userID: string): Promise<User> => {
  let ref = fridaTesterFirebase.database().ref();
  const user: User = (
    await ref.child(`Machines/${userID}`).once("value")
  ).val();
  return user;
};

const checkAccess = async (uid: string, group: string): Promise<boolean> => {
  let ref = fridaTesterFirebase.database().ref();
  const access: AccessControl = (
    await ref.child(`AccessControl/${uid}`).once("value")
  ).val();
  const { Groups } = access;
  if (Groups[group]) {
    return true;
  } else {
    return false;
  }
};

const updateResultStatus = async (results:Result[],index:number,selectedIndexOpenResult:number,groupID:string,machineID:string,uid:string| undefined,email:string| undefined|null) => {
  const ref = fridaTesterFirebase.database().ref();
  let totalPassed=0
  Object.values(results).forEach((val)=>{ 
    if(val.Result===true)
      totalPassed++
  })

  try{
    // const res =(await ref.child(`Logs/${groupID}/${machineID}`).once('value')).val();
    // const res =(await ref.child(`Logs/AuditIsida/-MgLuPC5W-aU0VxIuqnf/-MlGMT07FHDI3caAZu_W/results/0`).once('value')).val();

    const resID:LogArray =(await ref.child(`Logs/${groupID}/${machineID}/`).once('value')).val();
    if(resID)
    {
      const id=Object.keys(resID).reverse()[selectedIndexOpenResult]
      let tmp={...resID[id]}


      resID[id].passed=totalPassed
      resID[id].results[index].Result=true
      const date= new Date();
      // const date= new Date();
      // const day=date.getDate()
      // const month=date.getMonth()+1
      // const monthsArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // const monthMMM=monthsArray[month-1]
      // const year=date.getFullYear()
      // const hr= date.getHours()
      // const min=date.getMinutes()
      // const seg=date.getSeconds()
      
      if(uid&&email)
      {
        resID[id].results[index].authorized={
          uid,
          email,
          // date:`hoy`,
          date:`${date.toISOString()}`,
          // date:`${month}/${day}/${year} - ${hr}:${min}:${seg}`,
        }
        // resID[id]={...resID[id],resID[id].results[index]:{
          //   uid,
          //   email,
          //   date:`${month}/${day}/${year} - ${hr}:${min}:${seg}`,
          // }}
        }
        else{
          resID[id].results[index].authorized={
            uid:'',
            email:'',
            date:`${date.toISOString()}`,
            // date:`${month}/${day}/${year} - ${hr}:${min}:${seg}`,
          // date:`hoy`,
        }
        // resID[id]={...resID[id],authorized:{
        //   uid:'',
        //   email:'',
        //   date:`${month}/${day}/${year} - ${hr}:${min}:${seg}`,
        // }}

      }
      // console.log(`tmp sin actualizar:`,tmp);
      
      // console.log(`Antes update res1:`,res1);
      // console.log(`Antes update res1[0].passed:`,res1&&res1[0].passed);
      
      // res1.passed=totalPassed
      // console.log(`Object.keys(res1):`,Object.keys(res1));
      // console.log(`res1:`,Object.keys(res1)[0]);
      
      // const res =(await ref.child(`Logs/${groupID}/${machineID}/${Object.keys(res1)[index]}/`).once('value')).val();
      // console.log(`res:`,res);
      // const res =(await ref.child(`Logs/${groupID}/${machineID}/${Object.keys(res1)[index]}/results/${index}/`).once('value')).val();
      // const res =(await ref.child(`Logs/${groupID}/${machineID}/-MlGMT07FHDI3caAZu_W/results/${index}/`).once('value')).val();
      
      //obtener datos de la maquina, verificar que coincida el total de pruebas vs el incremento de passed
      const mach:Machine=(await ref.child(`Machines/${machineID}`).once('value')).val()
      if(totalPassed<=mach.total)
      {
        mach.passed=totalPassed
        try{
          await ref.child(`Machines/${machineID}/`).set(mach);
        }
        catch(e)
        {
          console.log(`error en actualizar machines:`,e);
        }
      }
      
      //Bien
      // console.log(`Url a donde quiero mandar:\n`,`Logs/${groupID}/${machineID}/${id}/`);
      // console.log(`Datos quiero mandar:`);
      // console.log(resID[id]);
      
      //Bien
      await ref.child(`Logs/${groupID}/${machineID}/${id}/`).set(resID[id]);
      // console.log(`el UPDATE es:`,res);
      
    }

  }catch(e){
    console.log(`errror:`,e);
  }
  
  
  // const res =await ref.child(`Logs/${groupID}/${m.ID}`).set(newTests);
  
  // let machines: Machine = (await ref.child('Machines').orderByChild('Group').equalTo(groupID).once('value')).val()
  // let allLogs: Log[] = []
  // let arrayResults: Result[] = []
  // let machineID: string = ''
  // try {
  //   const promiseDataLogs = Object.values(machines).map(async (m: Machine) => {
  //     machineID = m.ID
  //     let res = (await ref.child(`Logs/${groupID}/${m.ID}`).orderByChild('date').limitToLast(1).once('value')).val()
  //     return res
  //   })

  //   const allLogsData: Log[] = await Promise.all(promiseDataLogs)
  //   allLogsData && allLogsData.forEach((log) => allLogs.push(log))
  //   Object.values(allLogs[0]).forEach((log: Log) => {
  //     arrayResults = log.results
  //   })
  // }
  // catch (e) {
  //   console.log(`error:`, e);
  // }
  // return { machineID, allLogs, arrayResults }
}

const fetchGroupTests = async (group: string) => {
  const ref = fridaTesterFirebase.database().ref();
  const tests: any = (
    await ref.child(`Groups/${group}/Tests`).once("value")
  ).val();
  if (tests) {
    const testArr: Test[] = Object.values(tests);
    const testsId: string[] = Object.keys(tests);
    return { tests: testArr, testsId };
  } else {
    return { tests: [], testsId: [] };
  }
};

const fetchTestTemplates = async (): Promise<TestTemplate> => {
  const ref = fridaTesterFirebase.database().ref();
  const tests: TestTemplate = (
    window.location.hostname.includes('-qa')||window.location.hostname.includes('localhost')?
      await ref.child(`TestTemplatesQA`).once("value")
    :
      await ref.child(`TestTemplates`).once("value")
  ).val();
  return tests;
};

const addUserTest = async (userID: string, newTests: Test[]) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Machines/${userID}/Tests`).set(newTests);
};

const updateUserTest = async (
  userID: string,
  newTest: Test,
  testIdx: number
) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Machines/${userID}/Tests/${testIdx}`).update(newTest);
};

const deleteUserTestFb = async (userID: string, newTests: Test[]) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Machines/${userID}/Tests`).set(newTests);
};

const updateNotifyFailure = async (userID: string, NotifyFailure: string) => {
  const ref = fridaTesterFirebase.database().ref();
  await ref.child(`Machines/${userID}`).update({ NotifyFailure });
};

export {
  addUserTest,
  checkAccess,
  updateResultStatus,
  fetchUserInfo,
  updateUserTest,
  fetchGroupTests,
  deleteUserTestFb,
  fetchTestTemplates,
  updateNotifyFailure,
};
