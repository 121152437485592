import { InfoCircleOutlined, TeamOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  List,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import React from "react";
import { firebase } from "../../../firebase";
import { TeamMateInf, TeamMates } from "../../../interfaces/Home";
import { removeTeamMate } from "../../../pages/Account/services";
import { colorHEX, getAvatarLeters } from "../../../utilities";

interface Props {
  newUserEmail: string;
  displayWrongFormatEmail: boolean;
  allTeamMates: TeamMateInf[];
  handleNewUserEmail: (val: string) => void;
  addNewTeamMate: () => void;
  removeTeamMateList: (val: string) => void;
}

const TeamMate2: React.FC<Props> = ({
  newUserEmail,
  displayWrongFormatEmail,
  allTeamMates,
  handleNewUserEmail,
  addNewTeamMate,
  removeTeamMateList,
}) => {
  const { Option } = Select;
  //Search
  return (
    <>
      <div>
        <Row>
          <Col
            span={1}
            // style={{ display: "flex", alignItems: "center" }}
            xs={3} sm={3} md={3} lg={2} xl={2} xxl={55}          >
            <Avatar
              style={{ marginTop: "18px",backgroundColor: "#531dab", }}
              // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              size={{ xs: 52, sm: 52, md: 62, lg: 72, xl: 72, xxl: 72 }}
              icon={<TeamOutlined />}
            />
          </Col>
          <Col>
            <Col>
              <div style={{ fontSize: "20px", margin: "5px 0px 20px 0px" }}>
                Add Team Mate
                <Tooltip
                  key={"inf"}
                  placement="right"
                  title={`All groups to create from now on will be shared with the users in this list.`}
                >
                  <InfoCircleOutlined
                    style={{
                      marginLeft: "10px",
                      color: "#2197DA",
                      fontSize: "13px",
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
            <Row>
              <Col style={{ margin: "0px 10px 0px 0px", width: "255px" }}>
                <Input
                  style={{ width: "250px" }}
                  placeholder="User Email"
                  value={newUserEmail !== "" ? newUserEmail : ""}
                  onChange={(val) => handleNewUserEmail(val.target.value)}
                />
              </Col>

              <Col>
                <Button
                  disabled={
                    displayWrongFormatEmail || newUserEmail === ""
                      ? displayWrongFormatEmail
                        ? true
                        : newUserEmail !== ""
                        ? false
                        : true
                      : !displayWrongFormatEmail
                      ? false
                      : true
                  }
                  onClick={addNewTeamMate}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  fontSize: "12px",
                  color: displayWrongFormatEmail ? "#FF451E" : "green",
                }}
              >
                {displayWrongFormatEmail ? `Invalid email format` : ``}
              </div>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            margin: "40px 0px 0px 0px",
          }}
        ></div>
        <Row>
          <Col span={2}></Col>
          <Col span={20} style={{ margin: "0px 10px 0px 0px", width: "90%" }}>
            <List
              pagination={{
                hideOnSinglePage: true,
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 6,
              }}
              // loading={initLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={allTeamMates}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Popconfirm
                      title={`Are you sure to remove the user?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => item.uid && removeTeamMateList(item.uid)}
                    >
                      <a key="list-loadmore-remove">Remove</a>
                    </Popconfirm>,
                  ]}
                >
                  {/* <Skeleton avatar title={false} loading={item.loading} active> */}
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        size={45}
                        style={{
                          color: "white",
                          marginRight: "10px",
                          backgroundColor:colorHEX(),
                          textTransform: "uppercase",
                        }}
                      >
                        {getAvatarLeters(item.Mail)}
                      </Avatar>
                    }
                    title={item.Name}
                    description={item.Mail}
                  />

                  {/* </Skeleton> */}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default TeamMate2;
