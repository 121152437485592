import React from "react";
import { Button, Form, Input, Spin, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { loginOption } from "../../constants/login";
import { SignStatus, SignUpForm } from "../../interfaces/auth";
import "../../pages/Login/login.css";

const { Title, Text } = Typography;

interface Props {
  Formlayout: any;
  domains: string[];
  status: SignStatus;
  fetchingDom: boolean;
  onFinishSignUp: (values: SignUpForm) => void;
  setLogOption: React.Dispatch<React.SetStateAction<number>>;
}

export const SignUpView: React.FC<Props> = ({
  status,
  domains,
  Formlayout,
  fetchingDom,
  setLogOption,
  onFinishSignUp,
}) => {
  return (
    <div className="login-container">
      <Title level={2}>Sign Up</Title>
      <Spin spinning={fetchingDom}>
        <Form {...Formlayout} onFinish={onFinishSignUp}>
          {/* <Form.Item
            label="User Name"
            name="userName"
            required={false}
            validateStatus={status.status}
            help={status.message}
            rules={[
              { required: true, message: "Please input your user name" },
            ]}
          >
            <Input
              allowClear
              autoComplete="off"
              prefix={<UserOutlined />}
              size="large"
            />
          </Form.Item> */}
          <Form.Item
            label="Email"
            name="email"
            required={false}
            validateStatus={status.status}
            help={status.message}
            rules={[
              { required: true, message: "Please input your email" },
              { type: "email", message: "please enter a valid email" },
              {
                validator(_, value) {
                  if (!value || domains.includes(value.split("@")[1])) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Invalid domain"));
                },
              },
            ]}
          >
            <Input
              allowClear
              autoComplete="off"
              prefix={<UserOutlined />}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            required={false}
            validateFirst={false}
            rules={[
              { required: true, message: "Please input your password" },
              { min: 9, message: "Password must be at least 9 characters" },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} size="large" />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="repassword"
            required={false}
            validateFirst={false}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
              { min: 9, message: "Password must be at least 9 characters" },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} size="large" />
          </Form.Item>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                width: "50%",
              }}
              shape="round"
              size="large"
              type="primary"
              htmlType="submit"
            >
              Sign up
            </Button>
          </Form.Item>
          <div
            className="to-sign"
            onClick={() => setLogOption(loginOption.signIn)}
          >
            <Text className="sign-up">Sign in</Text>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
