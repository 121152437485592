import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom';
// import AccountView from './view' //con tabs
import AccountView from './view2' //sin tabs
import { firebase} from "../../firebase";
import { addTeamMate, getTeamMates, getUserGroups, removeTeamMate } from './services';
import { AccessControl, TeamMateInf, TeamMates } from '../../interfaces/Home';
import { MessagesError } from './interface';
import { message } from 'antd';
interface Props{
   isDarkMode: boolean;
   // toggleTheme?:(val:boolean)=>void
   user?: firebase.User | null
}

const AccountPage:React.FC<Props> = ({
   isDarkMode,
   // toggleTheme
   user,
}) => {
   const { path } = useRouteMatch();
   const EMAIL_REGEX: RegExp=/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
   const [userGroups,setUserGroups]=useState<string[]>([])
   const [newUserEmail,setNewUserEmail]=useState<string>('')
   const [newUserSelectedGroup,setNewUserSelectedGroup]=useState<string>('')
   const [displayWrongFormatEmail,setDisplayWrongFormatEmail]=useState<boolean>(false)
   // const [allTeamMates,setAllTeamMates]=useState<TeamMates|null>(null)
   const [allTeamMates,setAllTeamMates]=useState<TeamMateInf[]>([])
   const [messageError,setMessageEror]=useState<MessagesError>({message:'',type:'',display:false})
   
   const handleNewUserEmail=(email:string)=>{
      email!==''?!EMAIL_REGEX.test(email)?setDisplayWrongFormatEmail(true):setDisplayWrongFormatEmail(false)
      :setDisplayWrongFormatEmail(false)
      setNewUserEmail(email)
   }

   const handleNewUserSelectedGroup=(group:string)=>{
      setNewUserSelectedGroup(group)
   }

   // useEffect(() => {
   //    console.log(`newUserEmail:`,newUserEmail);
   // }, [newUserEmail])
   
   // useEffect(() => {
   //    console.log(`newUserSelectedGroup:`,newUserSelectedGroup);
   // }, [newUserSelectedGroup])

   // useEffect(() => {
   //    getUserGroupNames()
   // }, [])

   useEffect(() => {
      getAllTeamMates()
   }, [])

   const addNewTeamMate=()=>{
      if(!allTeamMates.find((val)=>val.Mail===newUserEmail))
      {
         // console.log(`No existe en tu team Mates`);
         if(user)
         {
            addTeamMate(newUserEmail,user.uid).then((val)=>{
               setMessageEror(val)
            })
         }
         getAllTeamMates()
         setNewUserEmail('')
      }
      else
      {
         // console.log(`Existe en tu team Mates`);
         setMessageEror({display:true,
            type:'warning',
            message:`The user already exist in the tam mates group.`,})
      }
   }

   const removeTeamMateList=(tmid:string)=>{
      user&&removeTeamMate(user.uid,tmid).then((val)=>{
         setMessageEror(val)
      })
      getAllTeamMates()
   }

   const getAllTeamMates=async()=>{
      let res:TeamMates|null
      let arrayTM:TeamMateInf[]=[]
      if(user&&user.email)
      {
         res = await getTeamMates(user.email) as TeamMates|null
         res&&Object.values(res).forEach((tm,indx)=>{
            // console.log(`tm:`,tm);
            arrayTM.push({
               Mail: tm.Mail,
               Name:tm.Name,
               uid:res?String(Object.keys(res)[indx]):'',
            })
         })
         setAllTeamMates(arrayTM)
         // setAllTeamMates(res) 
      }
   }

   useEffect(() => {
      if(messageError.display)
      {
         displayMessage()
         setMessageEror({...messageError,display:false})
      }
   }, [messageError])

   const displayMessage=()=>{
      switch(messageError.type){
         case 'success':
            message.success(messageError.message)
            break
         case 'error':
            message.error(messageError.message)
            break
         case 'warning':
            message.warning(messageError.message)
            break
         default:
            break
      }
   }

   const getUserGroupNames=async()=>{
      let res:string[]
      if(user&&user.email)
      {
         res = await getUserGroups(user.email) as string[]
         setUserGroups(res) 
      }
   }

   return (
      <div>
         {
            user?
         <AccountView 
            path={path} 
            user={user} 
            userGroups={userGroups}
            newUserEmail={newUserEmail}
            newUserSelectedGroup={newUserSelectedGroup}
            displayWrongFormatEmail={displayWrongFormatEmail}
            allTeamMates={allTeamMates}
            handleNewUserEmail={handleNewUserEmail}
            handleNewUserSelectedGroup={handleNewUserSelectedGroup}
            addNewTeamMate={addNewTeamMate}
            removeTeamMateList={removeTeamMateList}
         />
         :
            <>No data user</>
         }
      </div>
   )
}
 export default AccountPage