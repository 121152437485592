import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Test,
  TestParamTemplate,
  TestTemplate,
} from "../../../interfaces/Home";
import "./blockStyles.css";

const { Title } = Typography;
const { Option } = Select;

interface Props {
  isDarkMode: boolean;
  selectedKey: string;
  selectedType: string;
  selectedTest: Test;
  testForm: FormInstance<any>;
  testTemplates: TestTemplate;
  onFinishTestForm: (values: any) => void;
}

export const EditForm: React.FC<Props> = ({
  testForm,
  isDarkMode,
  selectedKey,
  selectedType,
  selectedTest,
  testTemplates,
  onFinishTestForm,
}) => {
  const [form] = Form.useForm();

  const renderInput = (paramInput: TestParamTemplate) => {
    switch (paramInput.type) {
      case "text":
        return <Input />;
      case "select":
        return (
          <Select>
            {paramInput.options?.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        );
      default:
        return <></>;
    }
  };

  const [copySelectedTest,setCopySelectedTest]=useState<Test>(selectedTest)
  useEffect(() => {
    form.setFieldsValue({TestNickName:''});
  }, [])

  return (
    <div className="justifyCenter">
      <Row justify="center">
        <Col xs={24}>
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={4}
          >
            Current selection
          </Title>
        </Col>
        <Col xs={24}>
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={5}
          >
            TestType: {copySelectedTest.TestType}, Test Key: {copySelectedTest.TestKey}, Test NickName: {copySelectedTest.TestNickName}, Test Params: {copySelectedTest.TestParams? Object.values(copySelectedTest.TestParams)[0] :'NA'} 
          </Title>
        </Col>
        {/* <Col xs={24}>
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={5}
          >
            Test Key: {selectedTest.TestKey}
          </Title>
        </Col>
        <Col xs={24}>
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={5}
          >
            Test NickName: {selectedTest.TestNickName}
          </Title>
        </Col> */}
        <Col xs={24}>
          <Title
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343", marginTop:'15px' }}
            level={4}
          >
            Test Params
          </Title>
        </Col>
        <Col xs={24}>
          <Form
            form={testForm}
            onFinish={onFinishTestForm}
            initialValues={{
              TestParams:copySelectedTest.TestParams,
              TestNickName: copySelectedTest.TestNickName,
            }}
          >
            {testTemplates[selectedType][selectedKey].TestParams.map(
              (param, indx) => (
                <Form.Item
                  key={`${param}-${indx}`}
                  name={param.name}
                  rules={[
                    {
                      required: true,
                      pattern: param.regex
                        ? new RegExp(`${param.regex}`)
                        : undefined,
                    },
                  ]}
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label={
                    <Title
                      level={5}
                      style={{
                        color: isDarkMode ? "#f0f0f0" : "#595959",
                        fontSize: "14px",
                      }}
                    >
                      {param.name}
                    </Title>
                  }
                >
                  {renderInput(param)}
                </Form.Item>
              )
            )}
            <Form.Item
              name="TestNickName"
              rules={[
                {
                  required: true,
                },
              ]}
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={
                <Title
                  level={5}
                  style={{
                    color: isDarkMode ? "#f0f0f0" : "#595959",
                    fontSize: "14px",
                  }}
                >
                  TestNickName 
                </Title>
              }
            >
              <Input defaultValue={copySelectedTest.TestNickName} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
