import { UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Col, Divider, Input, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { firebase} from "../../../firebase";
import { useAuth } from '../../../firebase/auth';
import { updateUserName } from '../../../pages/Account/services';

interface Props{
   user: firebase.User | null
}

const UserInfo:React.FC<Props> = ({
   user,
}) => {
   const authObj = useAuth();
   let {userName, updateAuthObjUserName}=authObj
   const [newUserName,setNewUserName]=useState<string>(userName)
   const [userNameAvatar,setUserNameAvatar]=useState<string>('')
   const [selectedName, setSelectedName] = useState<boolean>(false);
   const handleClickInside = () => setSelectedName(true);
   const ref = useRef(null);
   
   const handleClickOutside = (event: any) => {
      if (ref.current) {
         setSelectedName(false);
      }
   };
   const onFinishNameGroup = async(newGroupName: string) => {
      let message={}
      if(user)
         message=await updateUserName(user?.uid,newGroupName)
      updateAuthObjUserName(newGroupName,user)
   };
   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    });
    
    useEffect(() => {
      const { loading, user,userName } = authObj;
      
      if (!loading) {
        if (user) {
          const mail = user.email;
          // const name = mail?.split("@")[0] as string;
          setUserNameAvatar(userName);
          try {
            const initials = userName.split(" ");
            const newCont = initials[0][0] + initials[1][0];
            setUserNameAvatar(newCont);
          } catch (err) {
            const initials = userName.split(" ");
            const newCont = initials[0][0] + initials[0][1];
            setUserNameAvatar(newCont);
          }
          // try {
          //   const initials = userName.split(".");
          //   const newCont = initials[0][0] + initials[1][0];
          //   setAvCont(newCont);
          // } catch (err) {
          //   const initials = name.split(".");
          //   const newCont = initials[0][0] + initials[0][1];
          //   setAvCont(newCont);
          // }
        }
      }
    }, [authObj]);
   return (
      <div>

         <Row>
            <Col span={1} 
               style={{display:'flex', alignItems:'center'}}
               xs={3} sm={3} md={3} lg={2} xl={2} xxl={55}
            >
               <Avatar
                  style={{marginTop:'8px',fontSize:'30px',backgroundColor: "#531dab",}}
                  // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  size={{ xs: 52, sm: 52, md: 62, lg: 72, xl: 72, xxl: 72 }}
                  // icon={<UserOutlined />}
               >
                  {userNameAvatar}
               </Avatar>
            </Col>
            <Col 
               // span={23}
            >
               <Col 
                  // span={23}
               >
                  <div style={{ marginBottom: "0px", marginTop: "0px", 
                  width:'350px' }}>
                     <Input
                     style={{
                        fontSize: "18px",
                        marginRight: "15px",
                        marginLeft: "-12px",
                     }}
                     ref={ref}
                     value={newUserName}
                     bordered={selectedName}
                     onChange={(e) => setNewUserName(e.target.value)}
                     onClick={() => {
                        handleClickInside();
                     }}
                     onKeyPress={(e: React.KeyboardEvent) => {
                        if (
                           e.key == "Enter" &&
                           newUserName !== "" &&
                           newUserName !== userName
                        ) {
                           onFinishNameGroup(newUserName);
                        }
                     }}
                     />
                     {/* {
                           nameGroup !== groupName
                           &&
                           <Row >
                           <Col className="col-end" >
                              <Row justify="start" >
                                 <Col >
                                 <Alert message="Please Press enter into text input to apply the change in the group name" type="warning" />
                                 </Col>
                              </Row>
                           </Col>
                           </Row>
                        } */}
                     {/* </GroupBreadCrumbs> */}
                  </div>
               </Col>
               <Col>
                  <Row>
                     <Col style={{marginTop: newUserName !== userName?'0px':"10px"}}>
                        <div style={{fontSize:'12px'}}>{user?.email}</div>
                     </Col>
                  </Row>
               </Col>
            </Col>
               <Col span={1}>
               </Col>
               <Col span={23}>
                  <Divider />
               </Col>
            {/* <div style={{fontSize:'25px'}}>{userName}</div> */}
         </Row>
         <Row>
            <Col>
               <div
                  style={{
                     marginTop: "5px",
                     marginBottom: "-10px",
                     marginLeft: "-11px",
                  }}
               >
                  { newUserName !== userName && (
                     <Row>
                        <Col className="col-end">
                        <Row justify="start">
                           <Col>
                              <Alert
                              message="Please Press enter into text input to apply the change in the user name"
                              type="warning"
                              />
                           </Col>
                        </Row>
                        </Col>
                     </Row>
                  )}
                  {/* {Type} */}
               </div>
            </Col>
         </Row>
         {/* <Row>
            <Col style={{marginTop: newUserName !== userName?'0px':"10px"}}>
               <div style={{fontSize:'12px'}}>{user?.email}</div>
            </Col>
         </Row> */}
         
      </div>
   )
}
export default UserInfo