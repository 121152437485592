import React from "react";
import { Spin } from "antd";
import "./loader.css";

interface Props {}

export const Loader: React.FC<Props> = () => {
  return (
    <div className="spinner">
      <Spin size="large" />
    </div>
  );
};
