import React from "react";
import { Tabs, Row,Col } from "antd";
import { firebase} from "../../firebase";

//CON TABS
// import UserInfo from "../../components/Account/UserInfo";
// import TeamMate2 from "../../components/Account/TeamMates2";

//SIN TABS
import UserInfo from "../../components/Account/UserInfo/";

import TeamMate2 from "../../components/Account/TeamMates";
import { TeamMateInf, TeamMates } from "../../interfaces/Home";
const { TabPane } = Tabs;

interface Props {
  path: string;
  user: firebase.User | null
  userGroups:string[]
  newUserEmail:string
  newUserSelectedGroup:string
  displayWrongFormatEmail:boolean
  allTeamMates:TeamMateInf[]
  handleNewUserEmail:(val:string)=>void
  handleNewUserSelectedGroup:(val:string)=>void
  addNewTeamMate:()=>void
  removeTeamMateList:(val:string)=>void
}

const AccountView: React.FC<Props> = ({ 
  path,
  user,
  userGroups,
  newUserEmail,
  newUserSelectedGroup,
  displayWrongFormatEmail,
  allTeamMates,
  handleNewUserEmail,
  handleNewUserSelectedGroup,
  addNewTeamMate,
  removeTeamMateList,
}) => {
  return (
    <>
      <div className="mainContainerGroups">
        {/* Account view */}
        
            <div
              style={{
                height: "130px",
               //  backgroundColor: "red",
              }}
            >
               <UserInfo user={user} />
            </div>
          
            <div
              style={{
                height: "550px",
                //  backgroundColor: "red",
              }}
            >
              <TeamMate2 
                  newUserEmail={newUserEmail}
                  displayWrongFormatEmail={displayWrongFormatEmail}
                  allTeamMates={allTeamMates}
                  handleNewUserEmail={handleNewUserEmail}
                  addNewTeamMate={addNewTeamMate}
                  removeTeamMateList={removeTeamMateList}
                />
               {/* <TeamMate 
                  user={user} 
                  userGroups={userGroups}
                  newUserEmail={newUserEmail}
                  newUserSelectedGroup={newUserSelectedGroup}
                  displayWrongFormatEmail={displayWrongFormatEmail}
                  handleNewUserEmail={handleNewUserEmail}
                  handleNewUserSelectedGroup={handleNewUserSelectedGroup}  
                /> */}
               
            </div>
      </div>
    </>
  );
};
export default AccountView;
