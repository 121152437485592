import React, { useState } from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { NavBar } from "../../components/Home/NavBar";
import GroupsComponent from "../Groups";
import Group from "../Group";
import UserPage from "../User";
import AccountPage from "../Account";
import { firebase} from "../../firebase";
const { Content, Footer } = Layout;

interface Props {
  path: string;
  isDarkMode: boolean;
  toggleTheme: (isChecked: boolean) => void;
  user: firebase.User | null
}

const HomeView: React.FC<Props> = ({ path, isDarkMode, toggleTheme,user }) => {

  const [titleNameGroup,setTitleNameGroup]=useState<string>('')
  const [groupType,setGroupType]=useState<string>('')

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <NavBar isDarkMode={isDarkMode} toggleTheme={toggleTheme} titleNameGroup={titleNameGroup} groupType={groupType}/>
          <Content
            className={
              isDarkMode ? "content-dark content" : "content-light content"
            }
          >
            <Switch>
              <Route exact path={path}>
                <GroupsComponent isDarkMode={isDarkMode} />
              </Route>
              <Route path={`${path}/group/:id`}>
                <Group isDarkMode={isDarkMode} setTitleNameGroup={setTitleNameGroup}
                setGroupType={setGroupType}
                />
              </Route>
              <Route path={`${path}/user/:id`}>
                <UserPage isDarkMode={isDarkMode} />
              </Route>
              <Route path={`${path}/account`}>
                <AccountPage isDarkMode={isDarkMode} user={user}/>
              </Route>
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <img
              src={
                isDarkMode ? "/InnovationLogoDark.png" : "/InnovationLogo.png"
              }
              alt="innovation_logo"
            />
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default HomeView;
