import { EyeInvisibleFilled, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  FormInstance,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ModalView from "../../../components/Modal";
import {
  Test,
  TestParam,
  TestParamTemplate,
  TestTemplate,
} from "../../../interfaces/Home";

interface Props {
  visibleEditBlock: boolean;
  isDarkMode: boolean;
  selectedKey: string;
  selectedType: string;
  selectedTest: Test;
  testForm: FormInstance<any>;
  testTemplates: TestTemplate;
  toggleVisibleEditBlock: (val: boolean) => void;
  handleEditTestForm: (values: Test) => void;
  newTestData: Test;
  setNewTestData: (val: Test) => void;
  verifySyntax: {};
  setVirifySintax: (values: {}) => void;
  isAbleCreateEditNewTest: boolean;
}

const EditTestForm: React.FC<Props> = ({
  visibleEditBlock,
  testForm,
  isDarkMode,
  selectedKey,
  selectedType,
  selectedTest,
  testTemplates,
  toggleVisibleEditBlock,
  handleEditTestForm,
  newTestData,
  setNewTestData,
  verifySyntax,
  setVirifySintax,
  isAbleCreateEditNewTest,
}) => {
  const [showForm, setShowForm] = useState<boolean>(visibleEditBlock);
  const { Title } = Typography;
  const { Option } = Select;
  const [copySelectedTest, setCopySelectedTest] = useState<Test>(selectedTest);
//   const [isAbleSendData, setIsAbleSendData] = useState<boolean>(false);
  const ERROR = "Error, invalid input";

  useEffect(() => {
    if (!showForm) toggleVisibleEditBlock(false);
  }, [showForm]);

  useEffect(() => {
   //  verifyInputs();
  }, [copySelectedTest]);

  useEffect(() => {
   //  verifyInputs();
    setCopySelectedTest(selectedTest);
  }, [selectedTest]);

//   const verifyInputs = () => {
//     if (copySelectedTest.TestParams) {
//       Object.values(copySelectedTest.TestParams).forEach((val, indx) => {
//         if (val === "" || copySelectedTest.TestNickName === "")
//           setIsAbleSendData(false);
//         else setIsAbleSendData(true);
//       });
//     }
//   };

  const renderInput = (
    paramInput: TestParamTemplate,
    indx?: number,
    params?: TestParam
  ) => {
    switch (paramInput.type) {
      case "text":
        return (
          <>
            <Input
              type={'text'}
              style={{ margin: "10px 0px 10px 0px" }}
              onChange={(e) => {
                if (verifySyntax.hasOwnProperty(paramInput.name)) {
                  if (e.target.value !== "") {
                    let reg = RegExp(paramInput.regex);
                    if (reg.test(e.target.value)) {
                      setVirifySintax({
                        ...verifySyntax,
                        [paramInput.name]: true,
                      });
                    } else {
                      setVirifySintax({
                        ...verifySyntax,
                        [paramInput.name]: false,
                      });
                    }
                  } else {
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: false,
                    });
                  }
                } else {
                  let reg = RegExp(paramInput.regex);
                  if (reg.test(e.target.value))
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: true,
                    });
                  else
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: false,
                    });
                }

                if (newTestData) {
                  setCopySelectedTest({
                    ...copySelectedTest,
                    TestParams: {
                      ...copySelectedTest.TestParams,
                      [paramInput.name]: e.target.value,
                    },
                  });
                }
              }}
              defaultValue={params ? params[paramInput.name] : ""}
            />
            {!Object.keys(verifySyntax).find((val, indx) => {
              if (val === paramInput.name)
                return Object.values(verifySyntax)[indx];
            }) ? (
              <div style={{ color: "#E74D2C" }}>{ERROR}</div>
            ) : (
              ""
            )}
          </>
        );
      case "select":
        return (
          <Select
            style={{ margin: "10px 0px 10px 0px" }}
            defaultValue={params ? params[paramInput.name] : ""}
            onChange={(e) => {
              if (newTestData)
                setCopySelectedTest({
                  ...copySelectedTest,
                  TestParams: {
                    ...copySelectedTest.TestParams,
                    [paramInput.name]: e,
                  },
                });
                if(e!=='')
                {
                  setVirifySintax({
                    ...verifySyntax,
                    [paramInput.name]: true,
                  });
                }
                else
                {
                  setVirifySintax({
                    ...verifySyntax,
                    [paramInput.name]: false,
                  });
                }
            }}
          >
            {paramInput.options?.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        );
      default:
        return <></>;
    }
  };

  return (
    <ModalView
      title={"Edit"}
      visible={showForm}
      setShowModalOutput={setShowForm}
    >
      <div className="justifyCenter">
        <Row justify="center">
          <Col xs={24}>
            <Title
              style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
              level={4}
            >
              Current selection
            </Title>
          </Col>
          <Col xs={24}>
            <Title
              style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
              level={5}
            >
              TestType: {copySelectedTest.TestType}, Test Key:{" "}
              {copySelectedTest.TestKey}
            </Title>
          </Col>
          <Col xs={24}>
            <Title
              style={{
                color: isDarkMode ? "#f0f0f0" : "#434343",
                marginTop: "15px",
                marginBottom: "30px",
              }}
              level={4}
            >
              Test Params
            </Title>
          </Col>
          <Col xs={24}>
            {testTemplates[selectedType][selectedKey].TestParams.map(
              (param, indx) => (
                <>
                  <Title
                    level={5}
                    style={{
                      color: isDarkMode ? "#f0f0f0" : "#595959",
                      fontSize: "14px",
                      margin:'20px 0px 10px 0px'
                    }}
                  >
                    {param.example !== undefined ? (
                      <>
                        {param.name}
                        <Tooltip
                          placement="right"
                          title={`Example - ${param.example}`}
                          // title='Example - Email lists: jorge@softtek.com;raul@softtek.com;luis@softtek.com'
                        >
                          <InfoCircleOutlined
                            style={{ marginLeft: "10px", color: "#4BC7F2" }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      param.name
                    )}
                  </Title>
                  {copySelectedTest.TestParams
                    ? renderInput(param, indx, copySelectedTest.TestParams)
                    : renderInput(param, indx)}
                </>
              )
            )}
            <Title
              level={5}
              style={{
                color: isDarkMode ? "#f0f0f0" : "#595959",
                fontSize: "14px",
                  margin:'20px 0px 10px 0px'
              }}
            >
              TestNickName
            </Title>
            <Input
              defaultValue={copySelectedTest.TestNickName}
              style={{ margin: "10px 0px 10px 0px" }}
              onChange={(e) => {
                if (verifySyntax.hasOwnProperty(`TestNickName`)) {
                  if (e.target.value !== "") {
                    //   let reg = RegExp(/\w/g);
                    let reg = RegExp(/^[^]+$/g);
                    if (reg.test(e.target.value)) {
                      setVirifySintax({
                        ...verifySyntax,
                        [`TestNickName`]: true,
                      });
                    } else {
                      setVirifySintax({
                        ...verifySyntax,
                        [`TestNickName`]: false,
                      });
                    }
                  } else {
                    // debugger
                    setVirifySintax({
                      ...verifySyntax,
                      [`TestNickName`]: false,
                    });
                  }
                } else {
                  if (e.target.value !== "") {
                    setVirifySintax({
                      ...verifySyntax,
                      [`TestNickName`]: false,
                    });
                  } else {
                    setVirifySintax({
                      ...verifySyntax,
                      [`TestNickName`]: false,
                    });
                  }
                }
                if (newTestData)
                  setCopySelectedTest({
                    ...copySelectedTest,
                    TestNickName: e.target.value,
                  });
              }}
            />
            {!Object.keys(verifySyntax).find((val, indx) => {
              if (val === "TestNickName")
                return Object.values(verifySyntax)[indx];
            }) ? (
              <div style={{ color: "#E74D2C" }}>{ERROR}</div>
            ) : (
              ""
            )}

            <Button
              type="primary"
              disabled={isAbleCreateEditNewTest}
              style={{ margin: "35px 0px 0px 0px" }}
              onClick={() => {
                handleEditTestForm(copySelectedTest);
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </ModalView>
  );
};

export default EditTestForm;
