import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { TestForm, TestType, TestKey } from "./TestBlock";
import { Button, Form, message, Popconfirm, Typography } from "antd";
import { GroupParams } from "../../interfaces/Group";
import { AccessControl, GroupInformation, Test, TestTemplate, User } from "../../interfaces/Home";
import { fridaTesterFirebase } from "../../firebase";
import { ColumnsType } from "antd/es/table";
import GroupsView from "./view";
import { Tag } from "antd";
import { useAuth } from "../../firebase/auth";
import {
  addGroupTest,
  AddUserFb,
  checkAccess,
  deleteGroupTestFb,
  duplicateGroup,
  fetchGroupTests,
  fetchTestTemplates,
  getAccessControl,
  getGroupData,
  setEmailToShare,
  updateGroupName,
  updateGroupTestFb,
  updateIsGroupEnabled,
  updateStatusArchivedGroup,
  updateTimeout,
} from "./service";
import "./group.css";
import { CodeSnippet } from "../../components/User/Snippet";
import { AlertOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { informationEmails, userList } from "./interface";
import { RcFile } from "antd/lib/upload";
import { isArray, isObject } from "util";

interface Props {
  isDarkMode: boolean;
  setTitleNameGroup:(val:string)=>void
  setGroupType:(val:string)=>void
}

const { Title } = Typography;

const Group: React.FC<Props> = ({ isDarkMode,setTitleNameGroup,setGroupType }) => {
  const history = useHistory();
  const { loading, user } = useAuth();
  const [nameForm] = Form.useForm();
  const [testForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [newUserForm] = Form.useForm();
  let { id } = useParams() as GroupParams;
  const [groupData,setGroupData]=useState<GroupInformation>({
    Enabled:false,
    Name: '',
    Type:'',
    Archived:false,
    LastUpdateTimeout:'',
  })
  const [users, setUsers] = useState<User[]>([]);
  const [searchMachine,setSearchMachine]= useState<string>('');
  const [nameModal, setNameModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [testsId, setTestsId] = useState<string[]>([]);
  //eslint-disable-next-line
  const [templateTypes, setTemplateTypes] = useState<string[]>([]);
  const [visibleEditBlock, setVisibleEditBlock] = useState(false);
  const [selectedTest, setSelectedTest] = useState<Test>({} as Test);
  const [selectedTestIdx, setSelectedTestIdx] = useState(0);
  const [groupTests, setGroupTests] = useState<Test[]>([]);
  const [testTemplates, setTestTemplates] = useState<TestTemplate>({});//tempaltes from DB
  const [visibleAddBlock, setVisibleAddBlock] = useState(false);
  const [visibleUserBlock, setVisibleUserBlock] = useState(false);
  const [fetchingGroupTests, setFetchingGroupTests] = useState(false);
  const [openManageGroup, setOpenManageGroup] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [managingAccess, setManagingAccess] = useState(true);
  const [modalShareGroup, setModalShareGroup] = useState<boolean>(false);
  const [emailShareGroup,setEmailShareGroup]=useState<string>('')
  const EMAIL_REGEX: RegExp=/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
  const [displayErrorEmail,setDisplayErrorEmail]=useState<boolean>(false)
  const [messageAddUserToGroup,setMessageAddUserToGroup]=useState<string>('')
  const [showModalDuplicateGroup,setShowModalDuplicateGroup]=useState<boolean>(false)
  const [newGroupNameDuplicate,setNewGroupNameDuplicate]=useState<string>('')
  
  const [isAbleCreateEditNewTest,setIsAbleCreateEditNewTest]=useState<boolean>(false)
  
  const [newTestData,setNewTestData]=useState<Test>({
    TestKey: selectedKey,
    TestNickName: '',
    TestParams: {},
    TestType: selectedType,
  })
  
  const [isCorrectFormatFile,setIsCorrectFormatFile]=useState<boolean>(false)
  const [verifySyntax,setVerifySyntax]=useState<{}>({})
  const [uploadEmailsFile,setUploadEmailsFile]=useState<string[]>([])
  const [typeShareEmail, setTypeShareEmail] = useState<string>('SingleEmail');
  const [uploadedEmailsFile,setUploadedEmailsFile]=useState<boolean>(false)
  const [visibleActionsMenu,setVisibleActionsMenu]=useState<boolean>(false)
  const [showAlertChangeHour,setShowAlertChangeHour]=useState<boolean>(false)
  const [newFinishUpdTmout,setNewFinishUpTmout]=useState<string>('')
  const [errorTemplatesGroup,setErrorTemplatesGroup]=useState<string[]>([])
  const [modalErrorTemplatesGroup, setModalErrorTemplatesGroup] = useState<boolean>(false);
  
  const errorTemplateList=useMemo(() => {
    let templateList:string[]=[]
    if(groupData.Tests&&Object.keys(testTemplates).length>0)
    {
      Object.values(groupData.Tests).forEach((gd,indx)=>{
        let lngtTestParamsGroup=gd.TestParams&&Object.keys(gd.TestParams).length
        let lngtTestParamsGroupDB=Object.values(Object.keys(testTemplates[gd.TestType][gd.TestKey][`TestParams`])).length
        //// Inicio intentando obtener nombres de params faltantes
        // if(gd.TestParams) 
        // {
        //   Object.values(testTemplates[gd.TestType][gd.TestKey][`TestParams`]).forEach((tt,indx)=>{
        //     console.log(`tt:`,tt.name);
        //     groupData.Tests&&Object.values(groupData.Tests)[indx]&&console.log(`dato:`,Object.values(groupData.Tests)[indx].TestParams)
            
        //     // groupData.Tests&&console.log(`dato:`,Object.values(testTemplates[gd.TestType][gd.TestKey][`TestParams`])[indx].name)

        //     // if(groupData.Tests&&Object.values(Object.keys(testTemplates[gp.TestType][gp.TestKey][`TestParams`])).includes(tt.name))
        //     // {
        //     //   console.log(`No incluye `,tt.name);
              
        //     // }

        //     // if(!Object.keys(groupData.Tests[gp.TestType][gp.TestKey][`TestParams`])).includes(tp.name))
        //     // {
        //     //   console.log(`Noincluye `,tp.name);
              
        //     // }
        //   })
        // }
        //// FIN intentando obtener nombres de params faltantes

        if(lngtTestParamsGroup!==lngtTestParamsGroupDB)
        {
          templateList.push(`${gd.TestType} - ${gd.TestKey} : There is a difference between the parameters, your group has ${lngtTestParamsGroup} and currently there are ${lngtTestParamsGroupDB}`)
        }
      })
    }
    // console.log(`templateList:`,templateList);
    return templateList
  }, [testTemplates,groupData.Tests])//prueba

  useEffect(() => {
    errorTemplateList.length>0&&setErrorTemplatesGroup(errorTemplateList)
  }, [errorTemplateList])
  

  useEffect(() => {
    if(groupData.LastUpdateTimeout!==newFinishUpdTmout)
      setShowAlertChangeHour(true)
    else
      setShowAlertChangeHour(false)
  }, [groupData.LastUpdateTimeout,newFinishUpdTmout])

  useEffect(() => {
    if(visibleActionsMenu||groupData.LastUpdateTimeout)
      setNewFinishUpTmout(groupData.LastUpdateTimeout)
    else if(!visibleActionsMenu)
      setNewFinishUpTmout('')

  }, [groupData.LastUpdateTimeout,visibleActionsMenu])

  useEffect(() => {
    if(uploadEmailsFile.length>0)
    {
      setUploadedEmailsFile(true)
      verifyEmailsFileContent()
    }
    else
      setUploadedEmailsFile(false)
  }, [uploadEmailsFile])

  useEffect(() => {
    if(!modalShareGroup)
      setTypeShareEmail('SingleEmail')
  }, [modalShareGroup])

  useEffect(() => {
    if(typeShareEmail==='SingleEmail')
    {
      setUploadedEmailsFile(false)
      setUploadEmailsFile([])
    }
  }, [typeShareEmail])
  // useEffect(() => {
  //     verifyInputs()
  //     // console.log(`Cambios copySelectedTest:`,newTestData);
  // }, [])
  
  // useEffect(() => {
  //     verifyInputs()
  //     // console.log(`Cambios copySelectedTest:`,newTestData);
  // }, [newTestData])

  useEffect(() => {
    setNewTestData({...newTestData,TestKey:selectedKey})
  }, [selectedKey])

  useEffect(() => {
      setNewTestData({...newTestData,TestType:selectedType})
  }, [selectedType])

  const onChangeTypeShareEmail = (e:string) => {
    setTypeShareEmail(e);
  };

  const handleMenuClick=(e:any)=>{
    if(e.key=='4')
    setVisibleActionsMenu(true)
    else
    setVisibleActionsMenu(!visibleActionsMenu)
      // setVisibleActionsMenu(true)

  }
  const handleVisibleChange = (flag:boolean) => {
    setVisibleActionsMenu(flag);
  }

  const createNewTest=()=>{
    // console.log(`newTestData:`,newTestData);
    updateTest(newTestData);
    setSelectedKey('')
    setSelectedType('')
    setCurrentStep(0);
    // setVirifySintax({})
    setNewTestData({
      TestKey: selectedKey,
      TestNickName: '',
      TestParams: {},
      TestType: selectedType,
    })
  }

  const verifyEmailsFileContent=()=>{
    let correctFormat:boolean=false
    console.log(`EMAIL_REGEX.test(uploadEmailsFile[0]):`,EMAIL_REGEX.test(uploadEmailsFile[0]));
    if(uploadEmailsFile.length>0&&EMAIL_REGEX.test(uploadEmailsFile[0]))
    {
      correctFormat=true
    }
    setIsCorrectFormatFile(correctFormat)
  }

  useEffect(() => {
    if(selectedKey!=='')
    {
      // setVerifySyntax({})
      let tmpVirifySintax={}
      if(testTemplates[selectedType][selectedKey].TestParams)
      {
        testTemplates[selectedType][selectedKey].TestParams.forEach((param)=>{
          tmpVirifySintax={...tmpVirifySintax,[param.name]: false,}
          })
          tmpVirifySintax={
            ...tmpVirifySintax,
            [`TestNickName`]: false,
          };
          setVerifySyntax(tmpVirifySintax)
    }
      // console.log(`tmpVirifySintax:`,tmpVirifySintax);
    }
    //a@a.com
  }, [selectedKey])


  useEffect(() => {
    let isEnabled:boolean=false
    Object.values(verifySyntax).forEach((val)=>{
      if(val===false)
        isEnabled=true
    })
    // console.log(`cambiando a:`,isEnabled);
    setIsAbleCreateEditNewTest(isEnabled)
  }, [verifySyntax])

 

  // const verifyInputs=()=>{
  //   if(newTestData.TestParams&&newTestData.TestKey!==''&&newTestData.TestType!=='')
  //   {
  //     Object.values(newTestData.TestParams).forEach((val,indx)=>{
  //         // console.log(`val:`,val);
  //         // if(val===''||newTestData.TestNickName===''||isEnableDone)
  //         if(val===''||newTestData.TestNickName==='')
  //           setIsAbleCreateNewTest(false)
  //         else
  //         {
  //           setIsAbleCreateNewTest(true)
  //           // setShowSyntaxRegexError(false)
  //         } 
  //           // alert(`campo vacio en :${copySelectedTest.TestParams&&Object.keys(copySelectedTest.TestParams)[indx]}`)
  //     })
  //   }
  // }

  useEffect(() => {
    
      if(displayErrorEmail)
      {
        setTimeout(() => {
          setMessageAddUserToGroup('')
          setDisplayErrorEmail(false)
          setEmailShareGroup('')
        }, 2500);
      }
  }, [displayErrorEmail])

  const duplicateNewGroup=()=>{
    duplicateGroup(user?.uid as string,user?.email as string,id,newGroupNameDuplicate)
    setShowModalDuplicateGroup(false)
    setNewGroupNameDuplicate('')
  }

  const handleNewGroupNameDuplicated=(newName:string)=>{
    setNewGroupNameDuplicate(newName)
  }

  const handleAddEmailToShareGroup=(email:string)=>{
    setEmailShareGroup(email)
  }

  const handleShowModalDuplicateGroup=()=>{
    setShowModalDuplicateGroup(!showModalDuplicateGroup)
  }

  const handleArchiveGroup=(status:boolean,nameGroup:string)=>{
    updateStatusArchivedGroup(status,id)
    setGroupData({...groupData,Archived:status,Enabled:!status})
    updateIsGroupEnabled(!status,id)

  }

  const [dataEmails,setDataEmails]=useState<informationEmails>({
    correctFormat:[],
    incorrectFormat:[],
    addedEmails:[],
    existedEmails:[],
  })

  const validateEachEmail=(): informationEmails=>{
    let tmpcorrectFormat:string[]=[]
    let tmpIncorrectFormat:string[]=[]

    uploadEmailsFile.forEach((ml)=>{
      if(EMAIL_REGEX.test(ml))
      {
        console.log(`correo correcto:`,ml);
        tmpcorrectFormat.push(ml)
      }
      else
      {
        console.log(`correo mal:`,ml);
        tmpIncorrectFormat.push(ml)
      }
    })
    console.log(`tmpcorrectFormat:`,tmpcorrectFormat);
    console.log(`tmpIncorrectFormat:`,tmpIncorrectFormat);
    setDataEmails({...dataEmails,incorrectFormat:tmpIncorrectFormat,correctFormat:tmpcorrectFormat}) 
    return {...dataEmails,incorrectFormat:tmpIncorrectFormat,correctFormat:tmpcorrectFormat} as informationEmails
  }

  const shareGroup=async()=>{
    // setDataEmails([])
    if(typeShareEmail==='SingleEmail')
    {
      setDisplayErrorEmail(true)
      setEmailToShare(emailShareGroup,id,setMessageAddUserToGroup,setDisplayErrorEmail)
    }
    else
    {
      //validar que los que estan en upladEmailsFile tengan el formato correcto, si es así, guardarlos en un array como corrects y los que no, en otro arreglo como incorrects
      let tmpDataEmails:informationEmails|[]=validateEachEmail()
      console.log(`tmpDataEmails:`,tmpDataEmails);
      
      //función para obtener datos de usuarios y guardar en el arreglo usuariosDB 
      // const accessList=await getAccessControl()
      // console.log(`accessList:`,accessList);
      
      // if(tmpDataEmails.hasOwnProperty('correctFormat')&&accessList)
      // {
      //   tmpDataEmails.correctFormat.forEach((ml)=>{
      //     let tmpRes: AccessControl=Object.values(accessList).find((usr: AccessControl)=>usr.Mail===ml)
      //     if(tmpRes)
      //     {
      //       console.log(`encontro respuesta:${ml}`,tmpRes);
      //       console.log(`uid`,`-${user?.uid as string}`);
            
      //       let tmpGp=Object.keys(tmpRes.Groups).find((gp)=>gp===`-${user?.uid as string}`)
      //       if(tmpGp)
      //       {
      //         console.log(`se encontro grupo con el id:${user?.uid as string}:`,tmpGp);
      //       }
      //       else
      //       {
      //         console.log(`NO se encontro grupo para el id ${user?.uid as string}:`,tmpRes);
              
      //       }
      //     }
      //     else
      //       console.log(`Correo no encontrado:${ml}`);

      //   })
      //   // tmpDataEmails.correctFormat.forEach((ml)=>{
      //   //   let tmpRes=accessList.Mail
      //   // })
      // }

      
      // else
      // {

      // }

        //Obtener datos de AccessControl
        //Recorrer de corrects el arreglo/objeto de AccessControl para validar si ese correo/usuario tiene en groups el id del grupo y que este en true,
          
          // * si no existe se agrega el id del grupo a Groups
          // * si existe y esta en false, ponerlo a true


      //validar si los que estan en la lista corrects existen o no en el arreglo usuariosDB 

      // los que no existan se agregaran y los que existan marcarlos como ya existentes (color rojo)


      
    }
  }

  // useEffect(() => {
  //   console.log(`Cambio dataEmails:`,dataEmails);
    
  // }, [dataEmails])

  const handleModalErrorTemplatesGroup=(val:boolean)=>{
    setModalErrorTemplatesGroup(val)
  }

  const handleModalShareGroup=(val:boolean)=>{
    setModalShareGroup(val)
    val&&setUploadEmailsFile([])
  }
  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if(currentStep===1)
      setIsAbleCreateEditNewTest(true)
  }, [currentStep])

  const prev = () => {
    if(currentStep - 1===1)
    {
      setNewTestData({...newTestData,TestParams: {}})
      setVerifySyntax({})
      
    }
    if(currentStep - 1===0)
    {
      setNewTestData({...newTestData,TestKey:''})
      setSelectedKey("");
      setVerifySyntax({})
    }
    setCurrentStep(currentStep - 1);
  };

  const updateTest = async (newTest: Test) => {
    await addGroupTest(id, newTest);
    setVisibleAddBlock(false);
    setSelectedKey("");
    setSelectedType("");
    setCurrentStep(0);
    setVerifySyntax({})
    const newTests = await fetchGroupTests(id);
    setGroupTests(newTests.tests);
    setTestsId(newTests.testsId);
  };
  
  const handleEditTestForm=async(newTest: Test)=>{
    // console.log(`newTest:`,newTest);
    await updateGroupTestFb(id, testsId[selectedTestIdx], newTest);
    const newTests = await fetchGroupTests(id);
    setGroupTests(newTests.tests);
    setVisibleEditBlock(false);
  }

  const onFinishTestForm = (values: any) => {
    let newTest: Test = {
      TestKey: selectedType,
      TestType: selectedKey,
    };
    if(Object.keys(values).length>0)
    {
      const { TestNickName, ...params } = values;
      newTest = {
        TestKey: selectedType,
        TestNickName,
        TestParams: params,
        TestType: selectedKey,
      };
    }
    updateTest(newTest);
  };

  const steps = [
    {
      title: "TestType",
      content: (
        <TestType
          isDarkMode={isDarkMode}
          selectedType={selectedType}
          testTemplates={testTemplates}
          setSelectedType={setSelectedType}
        />
      ),
    },
    {
      title: "TestKey",
      content: (
        <>
          <TestKey
            isDarkMode={isDarkMode}
            selectedType={selectedType}
            selectedKey={selectedKey}
            testTemplates={testTemplates}
            setSelectedKey={setSelectedKey}
          />
        </>
      ),
    },
    {
      title: "Test Form",
      content: (
        <TestForm
          testForm={testForm}
          isDarkMode={isDarkMode}
          selectedKey={selectedKey}
          selectedType={selectedType}
          testTemplates={testTemplates}
          onFinishTestForm={onFinishTestForm}
          newTestData={newTestData}
          setNewTestData={setNewTestData}
          verifySyntax={verifySyntax}
          setVirifySintax={setVerifySyntax}
        />
      ),
    },
  ];

  const toggleManageDrawer = (toggle: boolean) => {
    setOpenManageGroup(toggle);
  };

  const toggleVisibleAddBlock = (toggle: boolean) => {
    setVisibleAddBlock(toggle);
    if(!toggle)
    {
      setSelectedKey('')
      setSelectedType('')
      setCurrentStep(0);
      setNewTestData({
        TestKey: selectedKey,
        TestNickName: '',
        TestParams: {},
        TestType: selectedType,
      })

    }
  };

  const checkAccessGroup = async () => {
    if (!loading && user) {
      
      const hasAccess = await checkAccess(user.uid, id);
      if (!hasAccess) {
        history.replace("/home");
      }
      if (hasAccess) {
        setFetchingGroupTests(true);
        const newGroupTests = await fetchGroupTests(id);
        const newTestTemplates = await fetchTestTemplates();
        const groupData = await getGroupData(id)
        setGroupData({...groupData,Name:groupData.Name})
        setGroupData({...groupData,Enabled:groupData.Enabled})
        if(groupData.Type!=='')
        {
          setGroupData({...groupData,Type:groupData.Type})
          groupData.Type&&setGroupType(groupData.Type)
        }
        const newTypes: string[] = [];
        for (const key in newTestTemplates) {
          for (const keyType in newTestTemplates[key]) {
            newTypes.push(keyType);
          }
        }
        setTemplateTypes(newTypes);
        setTestTemplates(newTestTemplates);
        setGroupTests(newGroupTests.tests);
        setTestsId(newGroupTests.testsId);
        setFetchingGroupTests(false);
      }
      setManagingAccess(false);
    }
  };

  useEffect(() => {
    checkAccessGroup();
    //eslint-disable-next-line
  }, [user, loading, id]);

  const testCols: ColumnsType<Test> = [
    {
      key: "TestType",
      dataIndex: "TestType",
      title: "TestType",
    },
    {
      key: "TestKey",
      dataIndex: "TestKey",
      title: "Test Key",
    },
    {
      key: "TestNickName",
      dataIndex: "TestNickName",
      title: "Test Nickname",
    },
    {
      key: "TestParams",
      title: "Params",
      dataIndex: "TestParams",
      render: (value, row, index) =>
        value && (
          <div className="paramsContainer">
            <CodeSnippet
              language="json"
              code={JSON.stringify(value, null, 2)}
            />
          </div>
        ),
    },
    {
      key: "edit",
      title: "Edit",
      dataIndex: "edit",
      render: (value, row, index) => (
        <Button onClick={() => handleSelectEditTest(row, index)} type="ghost">
          Edit
        </Button>
      ),
    },
    {
      key: "delete",
      title: "Delete",
      dataIndex: "Delete",
      render: (value, row, index) => (
        <Popconfirm
          placement="topLeft"
          title={`Are you sure to delete this test?`}
          onConfirm={() => deleteGroupTest(index)}
          okText="Yes"
          cancelText="No"
        >
          <Button shape="circle" type="text" icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const cols: ColumnsType<User> = [
    {
      key: "Name",
      title: "Name",
      dataIndex: "Name",
      render: (value, row, index) => (
        <Link to={`/home/user/${row.ID}`}>
          <Title style={{ color: isDarkMode?"#B783FF":'#722ed1' }} level={5}>
            {value}
          </Title>
        </Link>
      ),
      responsive: ['sm'],
    },
    { key: "Username", title: "Username", dataIndex: "Username" },
    {
      key: "Enabled",
      title: "Enabled",
      dataIndex: "Enabled",
      render: (value, row, index) => (
        <Tag color={value ? "green" : "error"}>
          {value ? "enabled" : "disabled"}
        </Tag>
      ),
      responsive: ['sm'],
    },
    {
      key: "lastUpdate",
      title: "Last Update",
      dataIndex: "lastUpdate",
      render: (value, row, index) => (
        <Tag color={getColorDate(new Date(value))} style={{ fontSize: "14px" }}>
          {new Date(value).toDateString()}
        </Tag>
      ),
      responsive: ['sm'],
    },
    {
      key: "ConfigError",
      title: "Config Error",
      dataIndex: "ConfigError",
      render: (value, row, index) => (
        
          value&&
          // <AlertOutlined style={{marginLeft:'30px', fontSize: "18px", color:isDarkMode?'white':'red' }} />
          <WarningOutlined style={{marginLeft:'30px', fontSize: "18px", color:isDarkMode?'white':'red' }} />
          
        
        // <Tag color={getColorErrorConfig(value)} style={{ fontSize: "14px" }}>
        //   {value?'true':'false'}
        // </Tag>
      ),
      responsive: ['sm'],
    },
    {
      key: "passed",
      title: "Passed tests",
      dataIndex: "passed",
      render: (value, row, index) => (
        <Tag color={getColorPassed(value, row.total)}>{value}</Tag>
      ),
      align: "center",
      responsive: ['sm'],
    },
    {
      key: "total",
      title: "Total tests",
      dataIndex: "total",
      align: "center",
      responsive: ['sm'],
    },
  ];

  const getColorDate = (lupdate: Date) => {
    const milis = new Date().getTime() - lupdate.getTime();
    const hours = Math.floor(milis / 1000 / 60 / 60);
    if (hours <= Number(groupData.LastUpdateTimeout)) {
      return "green";
    } else {
      return "yellow";
    }
  };

  const getColorPassed = (passed: number, total: number) => {
    if (passed === total) {
      return "green";
    } else {
      return "error";
    }
  };

  const getColorErrorConfig = (val: boolean) => {
    if (!val) {
      return "green";
    } else {
      return "error";
    }
  };

  const deleteGroupTest = async (testIdx: number) => {
    // const newTests = [...groupTests];
    // newTests.splice(testIdx, 1);
    // setGroupTests(newTests);
    await deleteGroupTestFb(id, testsId[testIdx]);
    const newTests = await fetchGroupTests(id);
    setGroupTests(newTests.tests);
    setTestsId(newTests.testsId);
  };

  const toggleVisibleEditBlock = (visible: boolean) => {
    setVisibleEditBlock(visible);
  };

  const handleSelectEditTest = (test: Test, index: number) => {
    let tmpVerifySyntax={}

    //Validación para los otros campos excepto NickName
    testTemplates[test.TestType][test.TestKey].TestParams.forEach((param)=>{
      if(test.TestParams)
      {
        if(param.hasOwnProperty('regex'))//contiene regex
        {
          
          let reg = RegExp(param.regex);
          if (reg.test(test.TestParams[param.name]))
            tmpVerifySyntax={...tmpVerifySyntax,[param.name]: true,}
          else
            tmpVerifySyntax={...tmpVerifySyntax,[param.name]: false,}
        }
        else //no contiene regex
        {
          // let reg = RegExp(/^[^]+$/g);
          // let reg = RegExp(/\w+/g);
          let reg = RegExp(/^\s*$/);
          if(reg.test(test.TestParams[param.name]))
            tmpVerifySyntax={...tmpVerifySyntax,[param.name]: false,}
          else
            tmpVerifySyntax={...tmpVerifySyntax,[param.name]: true,}
          }
      }
    })
    //validación para NickName
    if(test.TestNickName&&test.TestNickName!=='')
    {
      let reg = RegExp(/^[^]+$/g);
      if(reg.test(test.TestNickName))
        tmpVerifySyntax={...tmpVerifySyntax,[`TestNickName`]: true,}
      else
        tmpVerifySyntax={...tmpVerifySyntax,[`TestNickName`]: false,}
    }
    else
    {
      tmpVerifySyntax={...tmpVerifySyntax,[`TestNickName`]: false,}
    }
    setVerifySyntax(tmpVerifySyntax)
    toggleVisibleEditBlock(true);
    setSelectedTest(test);
    setSelectedTestIdx(index);
  };

  const fetchGroupTest = async () => {
    const newGroupTests = await fetchGroupTests(id);
    setGroupTests(newGroupTests.tests);
    setTestsId(newGroupTests.testsId);
  };

  const onFinishEditForm = (vals: any) => {
    const { TestNickName, ...params } = vals;
    let newTest = selectedTest;
    newTest.TestNickName = TestNickName;
    newTest.TestParams = params;
    updateGroupTestFb(id, testsId[selectedTestIdx], newTest);
    setVisibleEditBlock(false);
    setSelectedTest({} as Test);
    fetchGroupTest();
  };

  const onFinishAddUser = (vals: any) => {
    const newUser: User = {
      Enabled: vals.Enabled,
      Group: id,
      ID: "",
      Name: vals.Name,
      NotifyFailure: "",
      Tests: [],
      Username: vals.Username,
    } as unknown as User;
    handleAddUser(newUser);
  };

  const onFinishUpdateTimeout = () => {
    setGroupData({...groupData,LastUpdateTimeout:newFinishUpdTmout})
    handleUpdateTimeout();
    // nameForm.resetFields();
    // setNameModal(false);
  };
  const onFinishNameGroup = (newGroupName: string) => {
    setGroupData({...groupData,Name:newGroupName})
    handleUpdateGroupName(newGroupName);
    setGroupData({...groupData,Name:newGroupName})
    // nameForm.resetFields();
    // setNameModal(false);
  };

  const handleUpdateTimeout = async () => {
    await updateTimeout(id,newFinishUpdTmout);
  };

  const handleUpdateGroupName = async (name: string) => {
    await updateGroupName(id, name);
  };

  const handleAddUser = async (user: User) => {
    message.success({ content: "Machine added" });
    await AddUserFb(user);
    newUserForm.resetFields();
    toggleVisibleUserBlock(false);
  };

  const toggleVisibleUserBlock = (visible: boolean) => {
    setVisibleUserBlock(visible);
    if (!visible) {
      newUserForm.resetFields();
    }
  };

  const handleChangeEnabled=(enabled:boolean)=>{
    setGroupData({...groupData,Enabled:enabled})
    updateIsGroupEnabled(enabled,id)
  }

  // searchMachine
  // setSearchMachine

  useEffect(() => {
    setFetchingUsers(true);
    if(searchMachine==='')
    {
      let ref = fridaTesterFirebase.database().ref();
      const listener = ref.child("Machines").on("value", (snapshot) => {
        const users: any = snapshot.val();
        const groupUsers: User[] = [];

        for (const key in users) {
          if (users[key]) {
            if (users[key].Group === id) {
              groupUsers.push(users[key]);
            }
          }
        }
        setUsers(groupUsers);
        setFetchingUsers(false);
      });
      return () => ref.off("value", listener);
    }
    else if(searchMachine!==''&&users.length>0)
    {
      setFetchingUsers(false);
      let resultSearch:User[]=[]
      users.forEach((val)=>{
        if(val.Name.toLocaleLowerCase().includes(searchMachine.toLocaleLowerCase())||val.Username.toLocaleLowerCase().includes(searchMachine.toLocaleLowerCase()))
        {
          resultSearch.push(val)
        }
      })
      setUsers(resultSearch)
    }
    //eslint-disable-next-line
  }, [searchMachine]);

  const usersList:userList[]=[
    {
      title: 'Node1',
      value: '0-0',
      key: '0-0',
    },
    {
      title: 'Node2',
      value: '0-1',
      key: '0-1',
    },
    {
      title: 'Node3',
      value: '0-2',
      key: '0-2',
    },
  ]

  const [stateLista,setStateLista]=useState<{value: string[]}>({value:['0-0-0']})

  const onChangeShareGoupList= (val:{value: string[]})=>{
  setStateLista(val)
  }

  return !managingAccess ? (
    <GroupsView
      group={id}
      cols={cols}
      steps={steps}
      users={users}
      editForm={editForm}
      testForm={testForm}
      testCols={testCols}
      nameForm={nameForm}
      nameModal={nameModal}
      groupName={groupData.Name}
      isGroupEnabled={groupData.Enabled}
      Type={groupData.Type}
      lastUpdateTimeout={groupData.LastUpdateTimeout}
      groupTests={groupTests}
      isDarkMode={isDarkMode}
      newUserForm={newUserForm}
      selectedKey={selectedKey}
      selectedType={selectedType}
      currentStep={currentStep}
      selectedTest={selectedTest}
      testTemplates={testTemplates}
      fetchingUsers={fetchingUsers}
      openManageGroup={openManageGroup}
      visibleAddBlock={visibleAddBlock}
      visibleUserBlock={visibleUserBlock}
      visibleEditBlock={visibleEditBlock}
      fetchingGroupTests={fetchingGroupTests}
      prev={prev}
      next={next}
      isArchived={groupData.Archived}
      visibleActionsMenu={visibleActionsMenu}
      showAlertChangeHour={showAlertChangeHour}
      newFinishUpdTmout={newFinishUpdTmout}
      errorTemplatesGroup={errorTemplatesGroup}
      modalErrorTemplatesGroup={modalErrorTemplatesGroup}
      setNameModal={setNameModal}
      onFinishAddUser={onFinishAddUser}
      onFinishEditForm={onFinishEditForm}
      onFinishNameGroup={onFinishNameGroup}
      toggleManageDrawer={toggleManageDrawer}
      toggleVisibleAddBlock={toggleVisibleAddBlock}
      toggleVisibleEditBlock={toggleVisibleEditBlock}
      toggleVisibleUserBlock={toggleVisibleUserBlock}
      searchMachine={searchMachine}
      setSearchMachine={setSearchMachine}
      handleChangeEnabled={handleChangeEnabled}
      modalShareGroup={modalShareGroup}
      handleModalShareGroup={handleModalShareGroup}
      usersList={usersList}
      stateLista={stateLista}
      onChangeShareGoupList={onChangeShareGoupList}
      handleAddEmailToShareGroup={handleAddEmailToShareGroup}
      shareGroup={shareGroup}
      emailShareGroup={emailShareGroup}
      EMAIL_REGEX={EMAIL_REGEX}
      displayErrorEmail={displayErrorEmail}
      messageAddUserToGroup={messageAddUserToGroup}
      setSelectedTest={setSelectedTest}
      handleArchiveGroup={handleArchiveGroup}
      handleShowModalDuplicateGroup={handleShowModalDuplicateGroup}
      showModalDuplicateGroup={showModalDuplicateGroup}
      newGroupNameDuplicate={newGroupNameDuplicate}
      handleNewGroupNameDuplicated={handleNewGroupNameDuplicated}
      duplicateNewGroup={duplicateNewGroup}
      onFinishTestForm={onFinishTestForm}
      handleEditTestForm={handleEditTestForm}
      isAbleCreateEditNewTest={isAbleCreateEditNewTest}
      newTestData={newTestData}
      setNewTestData={setNewTestData}
      createNewTest={createNewTest}
      verifySyntax={verifySyntax}
      setVirifySintax={setVerifySyntax}
      uploadEmailsFile={uploadEmailsFile}
      setUploadEmailsFile={setUploadEmailsFile}
      typeShareEmail={typeShareEmail}
      setTypeShareEmail={setTypeShareEmail}
      onChangeTypeShareEmail={onChangeTypeShareEmail}
      uploadedEmailsFile={uploadedEmailsFile}
      isCorrectFormatFile={isCorrectFormatFile}
      setDataEmails={setDataEmails}
      setTitleNameGroup={setTitleNameGroup}
      handleMenuClick={handleMenuClick}
      handleVisibleChange={handleMenuClick}
      setShowAlertChangeHour={setShowAlertChangeHour}
      onFinishUpdateTimeout={onFinishUpdateTimeout}
      setNewFinishUpTmout={setNewFinishUpTmout}
      handleModalErrorTemplatesGroup={handleModalErrorTemplatesGroup}
    />
  ) : (
    <div></div>
  );
};

export default Group;
