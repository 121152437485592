///TestForm OLD
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Test,
  TestParam,
  TestParamTemplate,
  TestTemplate,
} from "../../../interfaces/Home";
import "./blockStyles.css";

const { Title } = Typography;
const { Option } = Select;

interface Props {
  isDarkMode: boolean;
  selectedKey: string;
  selectedType: string;
  testForm: FormInstance<any>;
  testTemplates: TestTemplate;
  onFinishTestForm: (values: any) => void;
  newTestData?: Test;
  setNewTestData?: (val: Test) => void;
  verifySyntax: {};
  setVirifySintax: (val: {}) => void;
}

export const TestForm: React.FC<Props> = ({
  testForm,
  isDarkMode,
  selectedKey,
  selectedType,
  testTemplates,
  onFinishTestForm,
  newTestData,
  setNewTestData,
  verifySyntax,
  setVirifySintax,
}) => {
  const renderInputAlan = (paramInput: TestParamTemplate) => {
    switch (paramInput.type) {
      case "text":
        return <Input />;
      case "select":
        return (
          <Select>
            {paramInput.options?.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        );
      default:
        return <></>;
    }
  };

  const ERROR = "Error, invalid input";
  // const ERROR = "Error, verify the syntax, invalid input";
  // const [verifySyntax,setVirifySintax]=useState<{}>({})

  const renderInput = (
    paramInput: TestParamTemplate,
    indx: number,
    params?: TestParam
  ) => {
    switch (paramInput.type) {
      case "text":
        return (
          <>
            <Input
              type={'text'}
              key={`${paramInput.name}-${indx}`}
              style={{ margin: "10px 0px 10px 0px" }}
              onChange={(e) => {
                if (verifySyntax.hasOwnProperty(paramInput.name)) {
                  if (e.target.value !== "") {
                    let reg = RegExp(paramInput.regex);
                    if (reg.test(e.target.value)) {
                      setVirifySintax({
                        ...verifySyntax,
                        [paramInput.name]: true,
                      });
                    } else {
                      setVirifySintax({
                        ...verifySyntax,
                        [paramInput.name]: false,
                      });
                    }
                  } else {
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: false,
                    });
                  }
                } else {
                  let reg = RegExp(paramInput.regex);
                  if (reg.test(e.target.value))
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: true,
                    });
                  else
                    setVirifySintax({
                      ...verifySyntax,
                      [paramInput.name]: false,
                    });
                }

                if (newTestData && setNewTestData) {
                  setNewTestData({
                    ...newTestData,
                    TestParams: {
                      ...newTestData.TestParams,
                      [paramInput.name]: e.target.value,
                    },
                  });
                }
              }}
              defaultValue={params ? params[paramInput.name] : ""}
            />
            {!Object.keys(verifySyntax).find((val, iNdx) => {
              if (val === paramInput.name)
                return Object.values(verifySyntax)[iNdx];
            }) ? (
              <div style={{ color: "#E74D2C" }}>{ERROR}</div>
            ) : (
              ""
            )}
            {/* {!Object.values(verifySyntax)[indx+2] ? <div style={{color:'#E74D2C'}}>{ERROR}</div>:''} */}
          </>
        );

      case "select":
        return (
          <Select
            showSearch
            key={`select-${indx}`}
            style={{ margin: "10px 0px 10px 0px", width: "120px" }}
            defaultValue={params ? params[paramInput.name] : ""}
            onChange={(e) => {
              if (newTestData && setNewTestData)
                setNewTestData({
                  ...newTestData,
                  TestParams: {
                    ...newTestData.TestParams,
                    [paramInput.name]: e,
                  },
                });
              if (e !== "") {
                setVirifySintax({
                  ...verifySyntax,
                  [paramInput.name]: true,
                });
              } else {
                setVirifySintax({
                  ...verifySyntax,
                  [paramInput.name]: false,
                });
              }
            }}
          >
            {paramInput.options?.map((option) => (
              <Option value={option}>{option}</Option>
            ))}
          </Select>
        );
      default:
        return <></>;
    }
  };

  //return FER
  return (
    <div className="justifyCenter">
      <Row justify="center" key={`row-Title-test-params`}>
        <Col xs={24} key={`col-Title-test-params`}>
          <Title
            key={"Test Params"}
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={4}
          >
            Test Params
          </Title>
        </Col>

        <Col xs={24} key={`col--testKey-params`}>
          <Title
            key={"Test Key/Test Type"}
            style={{ color: isDarkMode ? "#f0f0f0" : "#434343" }}
            level={5}
          >
            Test Key: {newTestData && newTestData.TestType}, Test Type:{" "}
            {newTestData && newTestData.TestKey}
          </Title>
        </Col>

        <Col xs={24} key={`Title-param-test-params`}>
          {testTemplates[selectedType][selectedKey].TestParams ? (
            <>
              {testTemplates[selectedType][selectedKey].TestParams.map(
                (param, indX) => (
                  <>
                    <Title
                      key={indX}
                      level={5}
                      style={{
                        color: isDarkMode ? "#f0f0f0" : "#595959",
                        fontSize: "14px",
                        margin: "15px 0px 5px 0px",
                      }}
                    >
                      {param.example !== undefined ? (
                        <>
                          {param.name}
                          <Tooltip
                            key={`example=${param.name}`}
                            placement="right"
                            title={`Example - ${param.example}`}
                          >
                            <InfoCircleOutlined
                              style={{ marginLeft: "10px", color: "#4BC7F2" }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        param.name
                      )}
                    </Title>
                    {newTestData && newTestData.TestParams
                      ? renderInput(param, indX, newTestData.TestParams)
                      : renderInput(param, indX)}
                  </>
                )
              )}

              <Title
                key={" TestNickName"}
                level={5}
                style={{
                  color: isDarkMode ? "#f0f0f0" : "#595959",
                  fontSize: "14px",
                  marginTop: "10px",
                }}
              >
                TestNickName
              </Title>
              <Input
                key={"TestNickName-Input"}
                style={{ margin: "10px 0px 10px 0px" }}
                onChange={(e) => {
                  if (verifySyntax.hasOwnProperty(`TestNickName`)) {
                    if (e.target.value !== "") {
                      // let reg = RegExp(/\w/g);
                      let reg = RegExp(/^[^]+$/g);
                      if (reg.test(e.target.value)) {
                        setVirifySintax({
                          ...verifySyntax,
                          [`TestNickName`]: true,
                        });
                      } else {
                        setVirifySintax({
                          ...verifySyntax,
                          [`TestNickName`]: false,
                        });
                      }
                    } else {
                      // debugger
                      setVirifySintax({
                        ...verifySyntax,
                        [`TestNickName`]: false,
                      });
                    }
                  } else {
                    let reg = RegExp(/\w/g);
                    if (e.target.value !== "") {
                      setVirifySintax({
                        ...verifySyntax,
                        [`TestNickName`]: false,
                      });
                    } else {
                      setVirifySintax({
                        ...verifySyntax,
                        [`TestNickName`]: false,
                      });
                    }
                  }
                  if (newTestData && setNewTestData)
                    setNewTestData({
                      ...newTestData,
                      TestNickName: e.target.value,
                    });
                }}
              />
              {!Object.keys(verifySyntax).find((val, inDx) => {
                if (val === "TestNickName")
                  return Object.values(verifySyntax)[inDx];
              }) ? (
                <div style={{ color: "#E74D2C" }}>{ERROR}</div>
              ) : (
                ""
              )}
            </>
          ) : (
            <h1>No parameter data</h1>
          )}
        </Col>
      </Row>
    </div>
  );
};
