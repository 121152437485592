import React, { useEffect, useState } from "react";
import { AuthProvider } from "./firebase/auth";
import MainLayout from "./routing";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { useCookies } from "react-cookie";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

function App() {
  const [cookies] = useCookies(["theme"]);
  const [defaultTheme, setDefaultTheme] = useState("light");

  useEffect(() => {
    if (cookies.theme) {
      setDefaultTheme(cookies.theme);
    }
  }, [cookies.theme]);

  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
      <AuthProvider>
        <MainLayout />
      </AuthProvider>
    </ThemeSwitcherProvider>
  );
}

export default App;
